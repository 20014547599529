/* margin start */
.mb-0{
  margin-bottom: 0px !important;
}
.mb-5{
  margin-bottom: 5px;
}
.mb-10{
  margin-bottom: 10px;
}
.mb-15{
  margin-bottom: 15px;
}
.mb-20{
  margin-bottom: 20px;
}
.mb-25{
  margin-bottom: 25px;
}
.mb-30{
  margin-bottom: 30px;
}
.ml-0{margin-left: 0px;}
.ml-33{
  margin-left: 33px;
}
.ml-24{
  margin-left: 24px;
}
.mr-0{margin-right: 0 !important;}
.mr-20{
  margin-right: 20px;
}
.mr-15{
  margin-right: 15px;
}
.ml-15{margin-left: 15px;}
.mt-15{margin-top: 15px !important;}
.mt-50{
  margin-top: 50px;
}
.mr-10{
  margin-right: 10px;
}
.mt-0{
  margin-top: 0px !important;
}
.mt-10{
  margin-top: 10px !important;
}
.mt-40{
  margin-top: 40px;
}
.mt-17{
  margin-top: 17px;
}
.mt-20{
  margin-top: 20px;
}
.mt-25{
  margin-top: 25px;
}
.mt-6{
  margin-top: 6px;
}
.ml-10{
  margin-left: 10px;
}
.my-5{
  margin: 5px 0px;
}
/* margin end */
/*padding start*/
.px-0{
  padding-left: 0px;
  padding-right: 0px;
}
.p-25-pt-0{
  padding: 0px 25px 25px 25px;
}
.pt-8-pb-25{
  padding-top: 8px;
  padding-bottom: 25px;
}
.p-23{
  padding: 23px;
}
.p-25{
  padding: 25px;
}
.p-10{
  padding: 10px;
}
.p-15{
  padding: 15px;
}
.pr-7{
  padding-right: 7px;
}
.pr-10{
  padding-right: 10px;
}
.pl-0{padding-left: 0px;}
.pl-17{
  padding-left: 17px !important;
}
.pl-15{padding-left: 15px;}
.px-10{
  padding: 0px 10px;
}
.px-15{
  padding: 0px 15px;
}
.pl-15-pr-15{
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.px-25{
  padding: 0px 25px;
}
.py-3{padding: 3px 0px;}
.py-25{
  padding: 25px 0px;
}
.py-8{padding: 8px 0px;}
.pb-30{
  padding-bottom: 30px;
}
.pb-25{
  padding-bottom: 25px;
}
.pb-15{
  padding-bottom: 15px;
}
.pb-0 {
  padding-bottom: 0px !important;
}
.pt-0{padding-top: 0 !important;}
.pt-4{padding-top: 4px !important;}
.pt-10{padding-top: 10px !important;}
.pt-20{padding-top: 20px;}
/* padding end */
.place-center{
  display: grid !important;
  place-content: center;
}
/* upload count start */
.cust-input-style{
  width: 32px;
  height: 28px;
  background: #FFFFFF;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  text-align: center;
}
/* upload count end */
/* PQF start */
.pqf-form-box{
  max-width: 935px;
  max-height: 485px;
  margin: auto;
}
.inner-pqf-form-box{
  max-width: 700px;
  margin: auto !important;
}
/* PQF end */
/*tab progress bar start*/
.tab-right-progress-bar .ant-tabs-extra-content{
  width: 46%;
  text-align: end;
}
.tab-right-progress-bar-osne .ant-tabs-extra-content{
  width: 40%;
}
/* .tab-right-progress-bar .ant-progress-text{
  right: 20px !important;
} */
.ant-tabs.ant-tabs-card .ant-tabs-extra-content{
  line-height: 12px !important;
}
/*tab progress bar end*/
/* flex properties start */
.flex-space-between{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dc-inc-space .ant-space-item:not(:last-child){margin-right: 14px !important;}
/* flex properties end */
/* tabs content inline start */
.content-inline-tabs{
  text-align: end;
  position: absolute;
  top: -36px;
  right: 0;
  margin-bottom: 50px;
  z-index: 10;
}
/* tabs content inline end */
/*tabs progress color start*/
.tab-form-progress::after {
  display: block;
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}
.red::after{border-right: 5px solid #C04804;}
.green::after{border-right: 5px solid #63AA4A;}
.amber::after{border-right: 5px solid #FFC107;}
/*tabs progress color end*/
/*Multi upload start*/
.multi-upload{
  overflow-y: auto;
}
/*Multi upload end*/
/* Draggable modal start */
.flexible-modal {
  position: fixed;
  top: 20vh !important;
  z-index: 130;
  padding: 0 25px 25px 25px;
  border-radius: 20px;
  background: #fbfbfb;
  overflow: hidden;
}
.flexible-modal-mask {
  position: fixed;
  height: 100%;
  background: rgba(0,0,0,.45);
  top:0;
  left:0;
  right:0;
  bottom:0;
  z-index: 130;
}
.flexible-modal-resizer {
  position:absolute;
  right:0;
  bottom:0;
  cursor:se-resize;
  margin:5px;
  border-bottom: solid 2px #333;
  border-right: solid 2px #333;
}
.flexible-modal-drag-area{
  height: 50px;
  position:absolute;
  right:0;
  top:0;
  cursor:move;
}
.modal-close-x {
  position: absolute;
  right: 0;
  width: 56px;
  height: 20px;
  font-size: 16px;
  font-style: normal;
  /* line-height: 56px; */
  text-align: center;
  text-transform: none;
  text-rendering: auto;
  color: rgba(0,0,0,.45);
  font-weight: 700;
  cursor: pointer;
  z-index: 140;
}
/* Draggable modal end */
.from-shift{
  cursor: pointer;
}
.shrink{width: 288.5px;}
.expand{width: 627px;}
.reset-placeholder .ant-input:placeholder-shown{
  text-transform:none;
}
.ant-upload.ant-upload-select-picture-card{
  overflow: hidden;
}
.center-object .avatar-uploader > .ant-upload{
  width:46px;
  height:46px;
  border-radius: 100px;
  background: #EFECE8;
}
.avatar-uploader:not(.project-logo) img{
  width: 46px !important;
  height: 46px;
  object-fit: cover;
}
.upload-text{
  position: absolute;
  white-space: nowrap;
  top: 50px;
  left: -10px;
}
.default-upload-icon{
  font-size: 30px;
  position: absolute;
  top: 13px;
  left: 8px;
}
.action-icon{
  font-size: 18px;
  color: #9D9E9E;
  stroke: #9D9E9E;
}
.action-icon-highlight{
  stroke: rgb(66, 135, 245) !important;
}
.grid-icon-size{
  font-size: 17px;
}
.grid-icon-highligh{
  color:#404040;
  stroke: #404040;
}
.grid-icon-new-highligh{
  color: #4287f5;
  stroke: #4287f5;
}
.grid-icon-new-highligh .action-icon{
  color: #4287f5;
  stroke: #4287f5 !important;
}
.dc-highlighted{
  color: #4287f5;
}
.cell-product:not(.wj-state-selected):not(.wj-state-multi-selected) {
  background-color: var(--scrollbarColor) !important;
}
.dc-green-icon{
  color: #0D837C;
}
.dc-red-icon{
  color: #ca2812;
}
.right-box-140{
  min-height: 140px;
}
/* Tooltip start */
[data-tip] {
	position:relative;

}
[data-tip]:before {
	content:'';
	/* hides the tooltip when not hovered */
	display:none;
	content:'';
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-bottom: 5px solid #404040;	
	position:absolute;
	top:30px;
	left:15px;
	z-index:8;
	font-size:0;
	line-height:0;
	width:0;
	height:0;
}
[data-tip]:after {
	display:none;
	content:attr(data-tip);
	position:absolute;
	top:35px;
	left:0px;
	padding:0px 8px;
	background:#404040;
	color:#fff;
	z-index:9;
	font-size: 0.75em;
	height:18px;
	line-height:18px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	white-space:nowrap;
	word-wrap:normal;
}
[data-tip]:hover:before,
[data-tip]:hover:after {
	display:block;
}
/* Tooltip end*/
/*enquiry profile start*/
.enq-noti-comments-box{
  height: 81px;
  background: #F1F0F0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
}
.enq-noti-label{
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #5F5A60;
}
.enq-envelope-btn{
  font-size: 20px;
  color: #857E7E;
}
.dc-enq-comment-box .ant-comment-avatar{display: none;}
.dc-enq-comment-box .ant-list-items{
  height: 145px;
}
.dc-enq-comment-box .ant-comment-inner{padding: 14px 0 !important;}
/* comments scroll start */
.dc-enq-comment-box .ant-list-items{
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: thin;
}
.dc-enq-comment-box .ant-list-items::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 7px;
}

.dc-enq-comment-box .ant-list-items::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: var(--scrollbarColor);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
/* comments scroll end */
.center-object .avatar-uploader-enq > .ant-upload{
  width:103px;
  height:103px;
  border-radius: 100px;
  background: #EFECE8;
}
.avatar-uploader-enq img{
  width: 103px !important;
  height: 103px;
  object-fit: cover;
}
.upload-text-enq{
  position: absolute;
  white-space: nowrap;
  top: 105px;
  left: 15px;
}
.default-upload-icon-enq{
  font-size: 88px;
  position: absolute;
  top: 13px;
  left: 8px;
}
.remove-upload-img{
  margin-bottom: 0;
  text-align: center;
}
.delete-placement{
  position: absolute;
  top: 33px;
  right: 15px;
}
/* proj enq form profile pic start  */
.center-object .avatar-uploader-enq-2 > .ant-upload{
  width:56.5px;
  height:56.5px;
  border-radius: 100px;
  background: #EFECE8;
}
.avatar-uploader-enq-2 img{
  width: 56.5px !important;
  height: 56.5px;
  object-fit: cover;
}
.upload-text-enq-2{
  position: absolute;
  white-space: nowrap;
  top: 63px;
  left: 3px;
}
.default-upload-icon-enq-2{
  font-size: 46px;
  position: absolute;
  top: 8px;
  left: 5px;
}
.profile-upload-middle{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
/* proj enq form profile pic end */
/*enquiry profile end*/
.flex-align-middle{
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-align-middle-2 .ant-space-item{
  display: flex;
  align-items: center;
  justify-content: center;
}
/* tabs hide start */
.hide-tab-headers .ant-tabs-nav-scroll{display: none;}
.hide-tab-headers .ant-tabs-bar{border:none;}
.child-tab .ant-tabs-nav-scroll{display: block;}
/* tabs hide end */
.tabs-mb-0 .ant-tabs-bar{
  margin-bottom: 0;
}
.tabs-mb-10 .ant-tabs-bar{
  margin-bottom: 10px;
}
.tabs-hide-conflicting-background .ant-tabs-bar{
  background: none !important;
}
.ant-tabs .ant-tabs-left-content{
  padding-left: 17px !important;
  border-left: none !important;
}
.dashboard-tabs{margin-top: 1.5%;}
.dashboard-tabs .ant-tabs-left-content{
  padding-left: 0px !important;
}
.ant-table-filter-dropdown .ant-dropdown-menu{
  height: 200px;
}
/*Search bar css start*/
.ant-input-search-icon::before{
  border-left:none;
}
.ant-input-affix-wrapper{
  border: none;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px !important;
  height: 28px;
}
.ant-input-affix-wrapper .ant-input{
  background:transparent;
  box-shadow: unset;
  border-radius: unset;
  height: unset;
}
/*Search bar css end*/

.pull-right{
  float: right;
}
.content-right{
  text-align: right;
}
.logo {
  width: 120px;
  height: 31px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px 28px 16px 0;
  float: left;
}

.logo {
  background: rgba(255, 255, 255, 0.3);
}
.enq-logo-placement{
  position: absolute;
  top: 20px;
  left: 20px;
}
/* In app header start */
.ant-menu-submenu-horizontal{margin-right: 10px;}
.main-header{
  box-shadow: 0px 8px 5px 2px rgba(0, 0, 0, 0.05);
}
.ant-menu-vertical.ant-menu-sub{
  min-width:136px;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled){
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #FFFFFF;
  background-color:var(--problue) !important;
}
.ant-menu-submenu-horizontal .ant-menu-submenu-selected{
  background-color: var(--problue) !important;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px !important;
  color: #FFFFFF !important;
  border-bottom: none !important;
}
.ant-menu-submenu-selected .ant-menu-submenu-title{
  color: #FFFFFF;
}
.ant-menu-vertical > .ant-menu-item{
  height: 27px !important;
  line-height: 27px !important;
}
.ant-menu-submenu-title{
  height: 24px;
  line-height: 23px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px !important;
  color: #5F5A60 !important;
}
.ant-menu-submenu-title:active, .ant-menu-submenu-title:hover{
  background-color: var(--problue) !important;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px !important;
  color: #FFFFFF !important;
  border-bottom: none !important;
}
.ant-menu-horizontal > .ant-menu-submenu, .ant-menu-horizontal > .ant-menu-submenu:hover{
  vertical-align: middle !important;
  border-bottom: none !important;
}
.ant-menu-submenu-popup > .ant-menu{
  background: #F3F5F6 !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px !important;
  text-align: center !important;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: #5F5A60;
  padding-top: 10px;
  padding-bottom: 10px;
}
.ant-menu:not(.ant-menu-horizontal, .ant-menu-inline) .ant-menu-item-selected{
  background: var(--problue) !important;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  color: #FFFFFF !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
}
.ant-layout-header {
  height: auto;
  padding: 10px 24px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 50px;
  background: white;
  text-align: right;
}
.ant-layout-header .ant-badge-count {
  background: var(--probluedark);
}
.custom-badge .ant-badge-count {
  background: var(--probluedark);
}
.ant-layout{
  background:#FFFFFF !important;
}
.ant-menu-dark .ant-menu-item > a {
  color: rgba(255, 255, 255, 0.65) !important;
}
.forced-white{
  color: rgba(255, 255, 255, 0.65) !important;
}
.top-nav-btn{
  height: 24px;
  line-height: 23px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px !important;
  color: #5F5A60;
  vertical-align: middle !important;
}
.top-nav-btn:active, .top-nav-btn:hover{
  background-color: var(--problue) !important;
  box-shadow: inset 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 3px;
  font-style: normal;
  font-weight: 600;
  color: #FFFFFF !important;
  border-bottom: none !important;
}
  /*layout styles*/
  .layout-style{
    padding: 0px 24px 0;
  }
  .main-content-area{
    background: #fff;
    width: 100%;
    /* height: 400px; */
    overflow-y: scroll;
  }
  .main-content-area::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 7px;
  }
  
  .main-content-area::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: var(--scrollbarColor);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }

  .custom-scroll, 
  .comparison-table .ant-table-content {
    height: 100vh;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: var(--scrollbarColor) #C2D2E4;
  } 
  .ant-select-dropdown > div > div:not([role="listbox"]) {
    scrollbar-width: thin;
    scrollbar-color: var(--scrollbarColor) #C2D2E4;
  }
  .custom-scroll::-webkit-scrollbar, 
  .comparison-table .ant-table-content::-webkit-scrollbar, 
  .ant-select-dropdown > div > div::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 7px;
    background: transparent;
    border-radius: 5px;
  }
  
  .custom-scroll::-webkit-scrollbar-thumb, 
  .comparison-table .ant-table-content::-webkit-scrollbar-thumb, 
  .ant-select-dropdown > div > div::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: var(--scrollbarColor);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }
  .history-table.custom-scroll, .comparison-table .ant-table-content {height: auto;}
  .comparison-table th.ant-table-cell:not(:first-child) {
    min-width: 100px;
  }
  .version-modal .ant-modal-footer {
    padding-right: 3.5%;
  }
  .fixed-height-pannel-scroll .ant-tabs-tabpane{
    min-height: calc(97vh - 120px);
/*     overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: var(--scrollbarColor) #C2D2E4; */
  }
  .dc-tab-overflow-y .ant-tabs-left-content{
    overflow: unset !important;
    margin-left: 87px;
  }
  .dc-tab-overflow-y .ant-tabs-tabpane-inactive{display: none;}
  .history-table .ant-table-thead > tr > th:not(:first-child),
  .history-table .ant-table-tbody > tr > td:not(:first-child) {
    min-width: 100px;
  }
  .history-panel .ant-collapse-content-box label {
    margin: 10px 0;
    display: block;
  }
  .ant-collapse-item.history-panel:nth-child(even) .ant-collapse-header {
    background: #e7ecee;
  }
  .history-table .ant-table-tbody {
    border: 1px solid #dddddd;
  }
  .history-table .ant-table-tbody > tr > td {text-align: center;}
  .fixed-height-pannel-scroll >.ant-tabs-content > .ant-tabs-tabpane::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 7px;
    background: transparent;
    border-radius: 5px;
  }
  .fixed-height-pannel-scroll >.ant-tabs-content > .ant-tabs-tabpane::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: var(--scrollbarColor);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }

  .unset-fixed-tab-height .ant-tabs-tabpane{
    min-height: auto;
    overflow: unset;
  }
  .unset-tab-progress .ant-tabs-tab{
    border-right: unset !important;
  }

  .ant-form-item-mb-10 .ant-form-item{
    margin-bottom: 10px;
  }
  .ant-form-item-mb-0 .ant-form-item{
    margin-bottom: 0px;
  }
  .ant-form-item-mb-31 .ant-form-item{
    margin-bottom: 31px;
  }
  .ant-form-item-mb-38 .ant-form-item{
    margin-bottom: 38px;
  }
/* .select-placeholder-center .ant-select-selector .ant-select-selection-placeholder{
  text-align: center;
  padding-right: 4px;
} */
  .page-title{
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #5F5A60;
  }
  .notify-scroll-height{
    height: 515px;
  }
    /* Custom scroll start */
    .dd-custom-scroller{
      overflow-y: scroll;
      overflow-x: hidden;
      scrollbar-width: thin;
    }
    .dd-custom-scroller::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
      height: 7px;
    }
    
    .dd-custom-scroller::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: var(--scrollbarColor);
      box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }
    .noti-blue{
      background: rgba(240, 246, 249, 0.6);
    }
    /* Custom scroll end */
/* Native table scroll */
.native-table-scroller{
  overflow-y: auto;
  overflow-x: auto;
  scrollbar-width: thin;
}
.native-table-scroller::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 7px;
}

.native-table-scroller::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #609FDB;
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

/*Input field styling*/
select{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;       /* Remove default arrow */
  background: url('../Assets/images/selectCaret.svg') no-repeat 97% 50%;
  background-color: #ffffff;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  height: 28px;
  width: -webkit-fill-available;
  border: none;
  text-overflow: ellipsis;
  padding: 4px 11px;
}
select:focus {
  outline: -webkit-focus-ring-color auto 0px;
}
.ant-input{
  background: #FFFFFF;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px !important;
  height: 28px;
}
.ant-input-number{
  background: #FFFFFF;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px !important;
  height: 28px;
}
/* .input-crazy-style .ant-input-number{width: 100%;} */
.input-crazy-style .ant-picker{width: 60%;}
.input-crazy-style .ant-form-item-label > label{float: right;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 16px;
  
  color: #5F5A60;}
  .pro-top-search .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
    height: unset;
  }
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
  height: 28px;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 28px;
}
.ant-select-arrow{
  top: 45% !important;
}
.ant-input-affix-wrapper{
  max-height: 40px !important;
}
.ant-checkbox-inner{
  width: 12px !important;
  height: 12px !important;
}
.dc-custom-checkbox label{
  font-size: 12px;
}
.dc-custom-checkbox .ant-checkbox-checked .ant-checkbox-inner{
  background-color: #81AED7;
  border-color: #81AED7;
}
.dc-custom-checkbox .ant-checkbox-disabled .ant-checkbox-inner{
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.dc-custom-checkbox .ant-checkbox-inner::after{
  width: 3.714286px;
  height: 7.142857px;
}
.ant-checkbox:hover::after{
  border: none;
}
.ant-checkbox-checked::after{
  border: none;
}
/*Camel case place holders*/
.ant-input:placeholder-shown{
  text-transform: capitalize;
  font-weight: 300;
}
.ant-input-number-input:placeholder-shown{
  text-transform: capitalize;
  font-weight: 300;
}
.OSEPQF-placeholder .ant-input-number-input:placeholder-shown{
text-transform: unset;
}
.ant-form-item-has-error .ant-form-item-explain, .ant-form-item-has-error .ant-form-item-split{
  font-size: 10.5px;
}
.menu-drawer-style .ant-drawer-header{
  background: #ffffff;
  border-bottom: 1px solid #ffffff;
}
/* .menu-drawer-style .ant-drawer-title{
  color: #fff;
} */
.menu-drawer-style .ant-drawer-body{
  padding: 0px;
  background: #ffffff;
}
.menu-drawer-style .ant-drawer-close{
  color: #fff;
}
.menu-logo{
  height: 6%;
  padding-left: 23px;
  position: absolute;
  top: 6px;
}
/*For all modal start*/
.ant-modal-title{
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: var(--probluedarker) !important;
}
.ant-modal-header,.ant-modal-footer {
  border-bottom: unset !important;
  border-top:unset !important;
}
/*For all modal end*/

.header-menu-btn{
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  color: #5F5A60;
  background: #F1F3F5;
  border-color:#F1F3F5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
}
.header-menu-btn:hover{
  color: #3370A8;
  background: #ffffff;
  border-color:#3370A8;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
}

.header-menu-btn:active{
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  color: #ffffff;
  background: var(--probluedarker);
  border-color:var(--probluedarker);
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
}
.header-menu-btn:focus, .ant-menu-item-selected .header-menu-btn{
  color: #ffffff;
  background: var(--probluedarker);
  border-color:var(--probluedarker);
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
/* li.ant-menu-item.ant-menu-item-selected:focus, li.ant-menu-item.ant-menu-item-selected {
  margin-bottom: -1px;
} */
/* .dash-horizontal-menu{
  float: right;
} */
.ant-menu-horizontal{
  border-bottom: none !important;
  background: transparent;
}
.ant-menu-horizontal > .ant-menu-item:hover,.ant-menu-horizontal > .ant-menu-item-selected{
  color:var(--probluedarker) !important;
  border-bottom: 2px solid #ffffff !important;
}
.header-menu-btn-conatiner{
  background: #CFD8DC;
  border-radius: 5px;
}
.mx-10{
  margin: 0px 10px 0px 10px;
}
.ant-form-item-label > label{
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: #5F5A60;
  white-space: normal;
}
.PQF-OSNE-form-label-style .ant-form-item-label > label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #5F5A60;
  white-space: normal;
}
.enq-label{
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: #5F5A60;
  white-space: normal;
}
.operators-label .ant-form-item-label > label{
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #5F5A60;
  white-space: normal;
}
.medium-text-header{
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #5F5A60;
}
.medium-sub-text{
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #5F5A60;
}
.medium-sub-text-tab{
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 25px;
  color: #5F5A60;
}
.inquiry-header{
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  text-align: center;
  color: #777078;
}
.inquiry-subtext{
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: #857E7E;
  margin-bottom:0px !important;
}
.info-graphic{
/*   width: 20%;
  display: inline-block;
  line-height: 53px; */
  padding: 10px;
  flex: 50%;
}
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
/* table properties start */
.responsive-table .ant-table-content{
  overflow: auto;
}
.ant-table-tbody > tr.ant-table-row > td{
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  background: none;
}
.ant-table-tbody > tr.ant-table-row > td:first-child{
  border-left: 2px solid transparent;
}
.ant-table-tbody > tr.ant-table-row > td:last-child{
  border-right: 2px solid transparent !important;
}
.ant-table-tbody > tr.ant-table-row:hover > td{
  border-top: 2px solid #9DBCD9;
  border-bottom: 2px solid #9DBCD9;
  background: none;
}
.ant-table-tbody > tr.ant-table-row:hover > td:first-child{
  border-left: 2px solid #9DBCD9;
}
.ant-table-tbody > tr.ant-table-row:hover > td:last-child{
  border-right: 2px solid #9DBCD9 !important;
}

/* border-top: 0.15em solid var(--probluedarker);
border-bottom: 0.15em solid var(--probluedarker);
background: transparent;
}
.ant-table-tbody > tr.ant-table-row:hover > td:first-child{
border-left: 0.15em solid var(--probluedarker);
}
.ant-table-tbody > tr.ant-table-row:hover > td:last-child{
border-right: 0.15em solid var(--probluedarker) !important; */
/* table properties end */
.double-scroll{
  overflow-x: scroll;
  scrollbar-width: thin;
}
.double-scroll::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 4px;
}

.double-scroll::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: var(--scrollbarColor);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
.ant-table table{
  text-align: center !important;
}
/* status buttons start */
/* .radio-group-width{
  display: block !important;
} */
/* .ant-radio-button-wrapper:last-child{
  background: #DEE3E6;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: var(--probluedarker);
  text-align: center;
}
.ant-radio-button-wrapper:first-child{
  background: #DEE3E6;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: var(--probluedarker);
  text-align: center;
  margin-right: 10px;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  background: var(--probluedarker);
  border-color:var(--probluedarker);
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover{
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  background: var(--probluedarker);
  border-color:var(--probluedarker);
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within{
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  background: var(--probluedarker);
  border-color:var(--probluedarker);
} */

.ant-radio-button-wrapper{
  min-width: 110px;
  max-height: 24px;
  text-align: center;
  line-height: unset !important;
  background: #F1F0F0;
  /* box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25); */
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child{
  border-radius: 3px 0 0 3px;
  background: var(--problue);
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #FFFFFF;
}
.ant-radio-button-wrapper:last-child{
  border-radius: 0 3px 3px 0 !important;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
  background: var(--problue);
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #FFFFFF;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within{
  color: #FFFFFF;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active{
  color: #FFFFFF;
  border-color: var(--problue);
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover{
  color: #FFFFFF;
  border-color: var(--problue);
}
/* status buttons end */

/* status buttons MD start */
.tab-pannel-alignment .radio-group-width{
  display: block !important;
}
.tab-pannel-alignment .ant-radio-button-wrapper:last-child{
  background: #DEE3E6;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  /* color: var(--probluedarker); */
  color: #5F5A60;
  text-align: center;
  width: 47%;
}
.tab-pannel-alignment .ant-radio-button-wrapper:last-child:active{
  background: #DEE3E6;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  /* color: #5585b3; */
  color: #5F5A60;
  text-align: center;
  width: 47%;
}
.tab-pannel-alignment .ant-radio-button-wrapper:first-child{
  background: #DEE3E6;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  /* color: var(--probluedarker); */
  color: #5F5A60;
  text-align: center;
  width: 47%;
  margin-right: 10px;
}
.tab-pannel-alignment .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  background: var(--probluedarker);
  border-color:var(--probluedarker);
}
.tab-pannel-alignment .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover{
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  background: var(--probluedarker);
  border-color:var(--probluedarker);
}
.tab-pannel-alignment .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within{
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  background: var(--probluedarker);
  border-color:var(--probluedarker);
}
/* status buttons MD end */

.master-form-style .ant-btn[disabled]{
  width: 100px;
  background: #DEE3E6;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #5F5A60;
}
.master-form-style .ant-btn[disabled]:hover{
  width: 100px;
  background: #dee1e2;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.5);
}

/*Collapse start*/
.ant-collapse:nth-child(even){
  background: #E7ECEE;
  border-color: #E7ECEE;
  border-bottom: 0px solid #E7ECEE;
}

/* Vendor template collapse */
.assigned-questions .ant-collapse-item .ant-collapse-header{
  color: var(--probluedarker);
}
.quality-criteria-collapse .ant-collapse-content-box{
  color: var(--probluedarker);
}
/*Collapse end*/
/*Blue shadow btn start*/
.blue-shadow-btn{
  background: #3471A9;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border-color: var(--probluedarker) !important;
  color: white;
}
.blue-shadow-btn:focus{
  background-color: #578bbb !important;
  color: white;
}
.blue-shadow-btn:hover{
  background-color: #578bbb !important;
  color: white;
}
/*Blue shadow btn end*/
/* .ant-btn:hover, .ant-btn:focus{
  color: #3370A8;
  background: #ffffff;
  border-color: #3370A8;
} */
/*Typography start*/
.blue-text{
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: var(--probluedarker);
}
.OrderedListStyle{
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #FFFFFF;
}
.OrderedListStyle li{
  padding: 10px 0px 10px 0px;
}
.add-photo-label{
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #5F5A60;
}
.ant-form-item-label > label.ant-form-item-required::before {
  display: none;
}
.ant-form-item-label > label.ant-form-item-required::after {
  display: inline-block;
  margin-left: 4px;
  color: rgb(136, 136, 136);
  font-size: 10px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}
.colon-none .ant-form-item-label > label:not(label.ant-form-item-required)::after{
  content: ' ';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}
.notice-box-sm-text{
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #FFFFFF;
}

/*Typography end*/
.ant-radio-wrapper{
  white-space: normal;
}
.ant-radio-inner::after{
  background-color: #86B5E0 !important;
}
.ant-radio-inner{
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

/*default success btn start*/
.btn-success{
  border: #63AA4A;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5) !important;
}
.btn-success:focus{
  border: #63AA4A;
  background: #63AA4A;
  color: white;
}

/*default success btn end*/
/*Notification start*/
.active-notification{
  color: var(--probluedark);
  font-size: 18px;
}
.inactive-notification{
  color: #9D9E9E;
  font-size: 18px;
}
.notify-item .ant-divider{
  border: 1px solid #C4C4C4;
}
.notify-item .ant-divider-horizontal{
  margin: 15px 0;
}
.ant-popover-title{
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  padding: 15px 16px 10px !important;
  color: var(--probluedarker);;
}
.ant-popover-placement-bottomRight .ant-popover-inner{
  background: #F0F6F8 !important;
  box-shadow: -8px -8px 8px rgba(0, 0, 0, 0.25), 8px 8px 8px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.notify-header-style{
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}
.notify-header-style .ant-form-item-label > label{
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}
.notify-date{
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: var(--probluedarker);
  text-align: end;
}

.noti-proj-name {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}
.noti-description{
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}
.noti-timestamp {
  width: 95px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: var(--probluedarker);
}
.noti-middle-content{
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
}
.noti-view-btn {
  width: 151px;
  height: 20px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 10px;
  color: #FFFFFF;
  background: #81AED7;
  border-radius: 6px;
}
.noti-view-btn:hover {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 10px;
  color: #FFFFFF;
  background: #8cb8e0;
}
.noti-container{width: 935px;}
.devicer {
  width: 5em;
  display: block;
  height: 3px;
  background-color: #5F5A60;
  margin: 0px auto 17px;
}
/*Notification end*/
/*background colors start*/
.skyblue-bg{
  background: #E7ECEE;
}
.skyblue-bg2:hover{
  background: #F0F6F8;
}
.dc-gray-bg{
  background: #F0EFEF;
}
.pale-orange-bg{background-color: var(--paleorange);}
.pale-green-bg{background-color: var(--palegreen);}
.pale-blue-bg{background-color: var(--paleblue);}
.pale-red-bg{background-color: var(--palered);}
.pale-purple-bg{background-color: var(--palepurple);}
/*background colors end*/
/*image responsive start*/
.img-responsive{
  width: 100%;
  height: auto;
}
/*image responsive end*/
/*Modal bg start*/
.modal-bg .ant-modal-content{
  background: #FBFBFB;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}
.modal-bg .ant-modal-header{
  background: transparent !important;
}

.card-5{
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px;
}
.card-6{
  background: #E7ECEE;
  border-radius: 5px;
}
.card-7{
  background: #F0FBFF;
  mix-blend-mode: normal;
  box-shadow: 8px 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.card-8{
  background: #FFFFFF;
  mix-blend-mode: normal;
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.25), 0px -5px 12px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.card-9{
  background: #E0E4E3;
  mix-blend-mode: normal;
  border-radius: 5px;
}
.card-10{
  background: rgb(207, 216, 220, 0.5);
  border-radius: 3px;
}
.card-p-10 .ant-card-body{
  padding: 10px !important;
}
.card-p-16 .ant-card-body{
  padding: 16px;
}
.card-p-20 .ant-card-body{
  padding: 20px;
}
.card-p-15-pb-0 .ant-card-body{
  padding: 15px 15px 0px 15px;
}
.card-py-5-px-15 .ant-card-body{
  padding: 5px 15px;
}
.card-pt-5 .ant-card-body{
  padding-top: 5px;
}
/*Modal bg end*/
/*Default link btn start*/
.ant-btn-link{
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: var(--probluedarker);
}
/*Default link btn start*/
/*Default btn gray block start*/
.gray-block{
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #5F5A60;
  background: #F1F0F0;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5); */
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
  border: transparent;
  border-radius: 3px;
}
.gray-block:hover{
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #5F5A60;
  background: #f3f3f3;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5); */
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
  border: transparent;
  border-radius: 3px;
}
/*Default btn gray block end*/
/*progress bar start*/
/* .step-progress .ant-progress-inner{
  border-radius: 3px !important;
  background-color: #f3f7fb !important;
  border: 1px solid rgba(232, 231, 231, 0.1) !important;
}
.step-progress .ant-progress-success-bg, .ant-progress-bg {
  background: #81AED7;
  box-shadow: inset 0px -4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  height: 16px !important;
}
.step-progress .ant-progress-text{
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #ACACAC;
  position: absolute;
  right: 15px;
  top: 4px;
}
.pqf-progress .ant-progress-text{right: -30px !important;}
.step-progress .ant-progress-status-success .ant-progress-bg{
  background: #81AED7 !important;
}
.step-progress .ant-progress-status-success .ant-progress-text{
  color: #FFFFFF !important;
}
.step-progress .ant-progress-show-info .ant-progress-outer{
  padding-right: unset;
} */

.mustard-progress .ant-progress-bg{
  border-radius: 0 100px 100px 0;
}
.mustard-progress .ant-progress-bg::after {
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 6px solid #DBC715;
  float: right;
  content: "";
  position: relative;
  left: 2px;
}
.mustard-progress .ant-progress-inner{
  border: solid .5px #DBC715;
  border-radius: 0;
}
.mustard-progress .ant-progress-status-success .ant-progress-text {
  color: #DBC715;
}
/*progress bar end*/
button.ant-btn.grey-btn {
  background: #F1F0F0;
  border-radius: 0;
  border: none;
  min-width: 80px;
  color: rgba(0, 0, 0, 0.65);
  height: 30px;
  --antd-wave-shadow-color: transparent;
} 
button.ant-btn.grey-btn.width-50 {
  width: 50%;
}
.grey-btn-outer {
box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
position: relative;
margin-bottom: 28px;
display: flex;
border-radius: 3px;
}
.grey-btn-outer button.ant-btn.grey-btn:first-child {
  border-radius: 3px 0 0 3px;
  /* padding-right: 0px; */
}
.grey-btn-outer button.ant-btn.grey-btn:last-child {
  border-radius: 0 3px 3px 0;
  /* padding-left: 0px; */
}
.grey-btn-outer button.ant-btn.grey-btn:not(:first-child)::after {
  content: "";
  width: 1px;
  height: 20px;
  display: block;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  background: rgb(0 0 0 / 0.1);
  left: 0;
}
button.ant-btn.grey-btn a {
  color: rgba(0, 0, 0, 0.65) !important;
}
.ant-table-container table th.ant-table-cell.ant-table-cell-fix-right, 
.ant-table-container table td.ant-table-cell.ant-table-cell-fix-right {
  border-left: 1px solid rgb(0 0 0 / 0.1);
}
.ant-input-search.search-field.ant-input-affix-wrapper .ant-input-search-icon::before {display: none;}
.group-menu {
  padding: 0 20px;
}
li.ant-menu-item.gap-left {
  padding-left: 48px;
  padding-right: 0;
}
li.ant-menu-item.gap-right {
  padding-left: 0;
  padding-right: 48px;
}
.ant-col.ant-col-24.Small{
  width:33%;
  display: inline-block;
}
.ant-col.ant-col-24.Medium{
  width:50%;
  display: inline-block;
}
.ant-col.ant-col-24.Large{
  width:100%;
}
.ant-table-pagination.ant-pagination li.ant-pagination-item {
  display: none;
}
.ant-table-pagination.ant-pagination li a {
  background: transparent;
  border: none;
}
.ant-table-pagination.ant-pagination li.ant-pagination-next, 
.ant-table-pagination.ant-pagination li.ant-pagination-prev {
  min-width: 20px;
}
.ant-table-pagination.ant-pagination li.ant-pagination-prev .anticon, 
.ant-table-pagination.ant-pagination li.ant-pagination-next .anticon {
  color: #5F5A60 !important;
}
.pagination-display {
  position: absolute;
  right: 70px;
  bottom: 6px;
  word-spacing: 5px;
  color: #5F5A60;
}
.pagination-display-inside-modal{
  position: absolute;
  bottom: 22px;
  right: 85px;
  word-spacing: 5px;
  color: #5F5A60;
}
.pagination-display-inside-modal-2{
  position: absolute;
  bottom: 40px;
  right: 85px;
  word-spacing: 5px;
  color: #5F5A60;
}
.pagination-display-user-listing{
  left: 81.5%;
  position: relative;
  bottom: 27px;
  word-spacing: 5px;
  color: #5F5A60;
  width: 122px;
}
.pagination-display-input-matrix {
  position: absolute;
  right: 67px;
  bottom: 5px;
  word-spacing: 5px;
  color: #5F5A60;
}
li.ant-pagination-options {
  display: none;
}
/* .ant-popover-buttons {
  text-align: center;
} */
.ant-modal-body {
  background: linear-gradient(90deg,transparent 7.5%, rgba(230, 241, 243, 0.5) 7.5%, rgba(230, 241, 243, 0.5) 92.5%, transparent 92.5%);
  padding-left: 14%;
  padding-right: 14%;
}
.ant-modal-body span.anticon.anticon-exclamation-circle {
  color: #faad14;
  font-size: 22px;
  margin-bottom: 10px;
}
.modal-body-resource-dev .ant-modal-body {
  background: unset !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  padding-top: 0px;
}
.modal-body-resource-dev .ant-modal-header{
  background: unset !important;
}
header.ant-layout-header .header-logo {
  display: block;
  float: left;
  margin-top: -4px;
  /* padding-left: 25px; */
}
.ant-table-pagination.ant-pagination {
  margin: 0;
}
header.ant-layout-header .ant-menu-item .anticon {
  font-size: 20px;
}
li.ant-menu-item.user-menu-item {
  padding-left: 30px;
  padding-right: 0;
}
header.ant-layout-header .ant-menu-item.user-menu-item .anticon {
  font-size: 18px;
}
.top-arrow-btn {line-height: 38px;}
.top-arrow-btn .ant-space-item {
  line-height: 0px;
}
.top-arrow-btn .anticon:hover svg line {
  stroke: var(--probluedarker);
}
.ant-form-item-label > label {
  min-height: 32px;
  height: auto;
}
/*Enquiry modal start*/
.jumbo-btn{
  /* width: 170px; */
  height: 65px;
  background: #F1F0F0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #5F5A60;
}
.jumbo-btn:hover, .jumbo-btn:active, .jumbo-btn:focus{
  background: #e2e2e2;
  color: #5F5A60;
  border-color:#e2e2e2;
}
.jumbo-text-1 span{
  white-space: break-spaces;
  width: 107px;
}
.jumbo-text-2 span{
  white-space: break-spaces;
  width: 64px;
}
.jumbo-text-3 span{
  white-space: break-spaces;
  width: 91px;
}
.enq-page{
  display: flex;
  height: calc( 100vh - 30px);
}
.enq-sidebar{
  width: 250px;
}
.enq-main-content{
  flex: 1 0 auto;
}
.enq-modal .ant-modal-body{
  background: none !important;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 0;
}
.enq-modal .ant-modal-content, .ant-modal-header{
  border-radius: 20px;
  background: #FBFBFB;
}
.enq-modal2 .flexible-modal-mask{
  z-index: 99 !important;
}
.enq-modal3{
  z-index: 100 !important;
}
.send-mail-btn{
  width: 150px;
  border-radius: 3px;
}
.send-enq{
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  text-decoration-line: underline;
  width: 215.5px;
  height: 127px;
}
.send-enq span{
  text-decoration: underline;
}
.enq-sidemenu-text{
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #777078;
  width: 214px;
  height: 109px;
}
.enq-left{
  position: absolute;
  left: 0;
  top: 43%;
}
.flex-container{
  display: flex;
  flex-direction: column;
}
.hide-empty-rows .ant-table-body .anticon{
  color: #9D9E9E;
  stroke: #9D9E9E;
}
/*Enquiry modal end*/
/* Chart start */
.responsive-pie{
  width: 350px;
  height: 87px;
}
/* Chart end */

select::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 7px;
 }
select::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: var(--scrollbarColor);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.spin-middle{
  position: absolute;
  top: 50%;
  left: 50%;
}

.pro-top-search{
  width: 389px;
  background: #F1F0F0;
  border-radius: 3px;
  text-align: center;
}
.send-email-box{
  background: #F1F0F0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
}
.proj-search-btn{
  background: transparent;
  border: none;
}
.proj-search-btn:active,
.proj-search-btn:focus,
.proj-search-btn.active {
  background: var(--problue);
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  color: white;
}

/* Add on start */
.addon .ant-input-group-addon{
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  background: #F0EFEF;
  box-shadow: inset 0px 4px 4px rgb(0 0 0 / 25%);
}
.addon .ant-input{
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
/* Add on end */

.dc-exp-table-pb-4 .wj-row .wj-detail{
  padding-bottom: 4px;
  /* padding: 0; */
}
.red-btn{
  background: #fbb1b1 !important;
  color: white;
  border-color: #fb7070;
}
.red-btn:hover,.red-btn:active,.red-btn:focus{
  color:red;
  border-color: red;
}
.green-btn{
  background: #8df389 !important;
  color: white;
  border-color: #2fc536;
}
.green-btn:hover,.green-btn:active,.green-btn:focus{
  color:green;
  border-color: green;
}
/* Target Internet Explorer 9 to undo the custom arrow */
.dc-min-tabgrid-height{
  max-height: 70vh;
}
.dc-min-tabgrid-height-2{
  min-height: 80vh;
}
.dc-phone-input .react-tel-input .form-control{
  height: 28px !important;
  width: 100%;
  box-shadow: 4px 4px 10px rgb(0 0 0 / 5%), inset 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 3px !important;
}
.dc-phone-input .react-tel-input .form-control::placeholder{
  color: #c4c4c4;
}
.dc-phone-input .react-tel-input .invalid-number-message{left: 0; top: 30px;}
.text-right{text-align: right;}
.text-left{text-align: left !important;}
.text-end{text-align: end;}
.text-center{text-align: center;}
.text-start{text-align: start;}
.dc-absolute-bottom{position: absolute;bottom: 0;width: 100%;}
.comment-textbox{
  width: 274px;
  resize: none;
}
.comment-date-time{
  font-style: normal;
  font-weight: 300;
  font-size: 9px;
  line-height: 11px;
  color: #857E7E;
}
.blue-text{color: #8BADCD;}
.cursor-pointer{cursor: pointer;}
.allowance-textbox{resize: none;}
.dc-explain-msg .ant-form-item-explain{text-align: start;}
.font-18{font-size: 18px !important;}
.font-15{font-size: 15px;}
.font-24{font-size: 24px;}
.dc-trad-input{
  background: #FFFFFF;
  box-shadow: 4px 4px 10px rgb(0 0 0 / 5%), inset 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 3px !important;
  height: 28px;
  border: none;
  text-align: center;
}
.width-60px{width: 60px;}
.width-100{width: 100%;}
.width-200{width: 200px !important;}
.width-90px{width: 90px;}
.width-180{width:180px;}
.width-100px{width: 100px;}
.width-auto{width: auto;}
.width-155{width: 155px;}
.width-255{width: 255px;}
.height-28 .ant-select-selector{height: 28px;}
.exclamation-comment-btn .exclamation-comment{
  stroke: #857E7E;
  fill: #857E7E;
}
.exclamation-comment-btn:focus .exclamation-comment, .exclamation-comment-btn:hover .exclamation-comment{
  stroke: #ffffff;
  fill: #ffffff;
  border: none;
}
.exclamation-comment-btn:focus, .exclamation-comment-btn:hover{
  background: var(--problue);
  border-color: var(--problue);
}
.tab-border{border: 1px solid #C4C4C4; border-radius: 3px;}
.tab-mb-0 .ant-tabs-bar{margin-bottom: 0px !important;}
.ant-form label{color: rgb(136, 136, 136) !important;}
.dark-gray-color{color: rgb(136, 136, 136);}
.float-right{float: right;}
.float-left{float: left;}
.z-index-20{z-index: 20;}
.noti-popover-width{width: 510px;}
.select-proj-width{width: 269px;}
.dash-layout{
  margin: 0;
  padding: 24px 24px 0px;
  min-height: 248px;
}
.border-radius-in-per{border-radius: 3%;}
.overflow-hidden{overflow: hidden;}
.overflow-unset{overflow: unset !important;}
.relative-wrap{position: relative;}
.overflow-hidden-tabs{overflow: hidden !important; position: static;}
.overflow-hidden-tabs .ant-tabs-content{position: absolute;}
.border-bottom-gray{border-bottom: 2px solid #C4C4C4;}
.z-index-3{z-index: 3;}
.overflow-x-hidden{overflow-x: hidden;}
.agreement-doc{width: 100%; height: 100vh;}
.allowance-upload-btn .ant-upload-list{
  position: absolute;
  right: 200px;
  top: 0;
}
.icon-btn{background: #F1F0F0;border-radius: 3px; box-shadow: 0px 1px 3px rgb(0 0 0 / 25%); width: 28px; height: 28px;}
.gray-border{border: 0.1px solid #c1c1c1;}
.table-container-height{min-height: auto}
.table-container-height2{min-height: 70vh;}
.dark-label{color: #5F5A60; font-size: 14px;font-weight: 600;}
.ant-switch-inner{
  display: block;
  margin: 0 14px 0 25px;
  color: #fff;
  font-size: 12px;
  transition: margin .2s;
}
.ant-badge-count-sm .ant-badge-count{
  min-width: 14px;
  height: 14px;
  padding: 0;
  font-size: 12px;
  line-height: 14px;
  border-radius: 7px;
}
.pro-top-search-2 .ant-select-selector {
  height: 32px !important;
}
.flexible-modal .country-list{position: fixed !important;}
.site-layout .site-layout-background {
  background: #fff;
}
.sider-fixed{
    overflow: auto;
    height:100vh;
    position: fixed;
    left: 0;
}
.promenu .single-menu:first-child{margin-top: 0 !important;}
.promenu .ant-menu-item{
  padding-left: unset !important;
  padding-right: unset !important;
  font-size: 15px;
  color: #5f5a60;
  font-weight: 500;
  width: 70px;
  height: 30px !important;
  /* background: #f0efef;
  box-shadow: 4px 4px 10px rgb(0 0 0 / 5%), inset 0 4px 4px rgb(0 0 0 / 25%); */
  border-radius: 3px;
  margin: 7px 12px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.promenu .ant-menu-submenu-title{
  font-style: normal;
  font-weight: 600;
  font-size: 18px !important;
  line-height: 22px !important;
  color: #5f5a60 !important;
  height: 22px !important;
  padding: unset !important;
  text-align: center;
  cursor: not-allowed;
}
.promenu .ant-menu-item-selected{
  background-color: var(--problue) !important;
  box-shadow: 4px 4px 10px rgb(0 0 0 / 5%), inset 0 4px 4px rgb(0 0 0 / 25%);
  border-radius: 3px;
  color: #fff;
}
.promenu .ant-menu-item::after{border-right: unset;}
.promenu .ant-menu-submenu-arrow{display: none;}
.promenu .ant-menu-submenu-selected{background-color:var(--probluelight) !important;}
.promenu .ant-menu-submenu-open{margin-bottom: 11px;background-color: #F8F7F7;border-radius: 3px;padding: 11px 0;}
.promenu .ant-menu-submenu > .ant-menu{background-color: transparent;}
.promenu{border-right: unset;}
.promenu .single-menu{
  width: 95px;
  height: 54px !important;
  white-space: break-spaces;
  line-height: 1.5;
  margin: 11px 0 !important;
  background-color: #F8F7F7;
}
.promenu .ant-menu-submenu-title{pointer-events: none;}
.pro-menu-btn{background-color: #3c7ab4;border-color:#3c7ab4; color: #ffffff;border-radius: 3px;}
.pro-menu-btn:focus{background-color: var(--problue);border-color:var(--problue); color: #ffffff;box-shadow: inset 0px 4px 4px rgb(0 0 0 / 25%);}
.pro-menu-btn:active{background-color: #3c7ab4;border-color:#3c7ab4;box-shadow: inset 0px 4px 4px rgb(0 0 0 / 25%);}
.sider-ml-15 .ant-layout-sider:not(.ant-layout-sider-collapsed){margin-left: 15px; margin-right: 15px;}
.x-sm-btn{height: 20px;font-size: 12px;line-height: 20px;}
.vendor-req-pqq .ant-tabs-content{position:relative;}
@media screen and (min-width:0\0) {
  select {
      background-image:none\9;
      padding: 5px\9;
  }
}

@media (min-width: 320px) {
  .master-form-style .ant-btn[disabled]{
    width: 70px;
  }
  .main-content-area{
    height: unset;
    overflow-y: unset; 
  }
  .hide-pc-menu{
    display: none;
  }
  .hide-mobile-menu-btn{
    display: block;
    position: absolute;
    top: 14px;
    left: 17px;
  }
  .ant-tabs-nav-scroll{
    overflow: scroll;
  }
  .ant-tabs-nav-scroll::-webkit-scrollbar{
    display: none;
  }
/*   .ant-table-cell-fix-left, .ant-table-cell-fix-right{
    background: #fff;;
  } */
  .responsive-display{
    display: inline-block;
  }
  .responsive-display-2{
    display: none;
  }
}

@media (min-width: 732px) {
  .master-form-style .ant-btn[disabled]{
    width: 100px;
  }
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 882px) {
  .hide-pc-menu{
    display: block;
  }
  .hide-mobile-menu-btn{
    display: none;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
  .flex-container {
    flex-direction: row;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
  .ant-tabs-nav-scroll{
    overflow: hidden;
  }
  .responsive-display{
    display: none;
  }
  .responsive-display-2{
    display: inline-block;
  }
}
.ant-tabs {overflow: visible;}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab:last-child {
  margin-right: 0 !important;
}
.ant-select .ant-select-arrow .anticon {
  background: url('../Assets/images/selectCaret.svg') center bottom no-repeat;
  color: transparent;
}
.ant-table-filter-dropdown .ant-table-filter-dropdown-btns button.ant-btn.ant-btn-primary:not([disabled]),
.ant-picker-footer button.ant-btn.ant-btn-primary:not([disabled]) {
  background: var(--probluedarker);
  border-color: var(--probluedarker);
  line-height: normal;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  background: var(--probluedarker);
  border-color: var(--probluedarker);
}
header.ant-layout-header .header-logo img {max-width: 100%;}
@media screen and (max-width: 1440px) {
  .layout-style {
    padding: 0px 0px 20px;
  }
  main.ant-layout-content.main-content-area {
    padding: 24px 16px 0 !important;
  }
}
@media screen and (max-width: 905px) {
  .dash-horizontal-menu{
    float: unset;
  }
}
@media screen and (min-width: 1440px) {
  .responsive-pie{
    width: 370px;
    height: 200px;
  }
}
@media screen and (min-width: 1800px) {
  .dc-curve-bg{
    width: 85%;
  }
}
