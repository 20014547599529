.wj-flexgrid {} 
.table-container-height2 .wj-flexgrid {max-height: 69vh;}
.proj-spec-table-child{ min-height: 20vh;}
.proj-spec-table-child .wj-cell:not(.wj-header){border-bottom: 1px solid #d9dcdd !important;}
.my-cell {padding: 0px;}
.my-cell.wj-state-selected .my-icon {display: none;}
.my-cell:not(.wj-state-selected) .my-dropdown {display: none;}
/* .my-icon {
  height: 100%;
  width: 100%;
} */

.my-icon > svg {
  margin-top: 9px;
/*   height: 100%;
  width: 100%; */
}
.wj-content{text-align: center;}
.wj-cell.wj-align-right{text-align: center !important;}
.pagination-pull-right{float: right;}
.wj-cell.wj-alt{
  background: #e9eaeb;
}
/* .wj-cell.wj-alt.wj-state-selected{
  background: #0085c7 !important;
} */
.wj-cell:not(.wj-header){
  border-bottom: none !important;
}
.wj-cell{
  border-right: 1px solid #d9dcdd !important;
}
div[wj-part="root"]::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 6px;
  height: 6px; 
  background: #C4C4C4;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
div[wj-part="root"]::-webkit-scrollbar-thumb {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: var(--scrollbarColor);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
div[wj-part="root"]{
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbarColor) #C2D2E4;
  padding-right: 0px;
  margin-right: -7px;
}

.wj-row .wj-cell.wj-header {
  background: white;
  color: #5F5A60 !important;
}
div[wj-part="rh"] .wj-row .wj-cell.wj-header {border: none;}
/* div[wj-part="rh"] .wj-row:nth-child(odd) .wj-cell.wj-header {
  background: white;
} */
.wj-row .wj-cell.wj-header.wj-header-alt {
    background: #e9eaeb;
}
.wj-row .wj-cell.wj-state-multi-selected {
  background: #306da5 !important;
}
/* wijmo filter start */
.wj-columnfiltereditor .wj-listbox {
  min-height: 72px;
  max-height: 150px;
  height: unset !important;
}
/* wijmo filter end */
/* wijmo row hover start */
.wj-cells .wj-cell.wj-state-selected{
  border: 2px solid #9DBCD9 !important;
  background:#ffffff !important;
  color: #5F5A60;
}
/* .wj-cell:hover{
  border: 2px solid #9DBCD9 !important;
} */
/* wijmo row hover end */
.wj-control{
  color: #5F5A60 !important;
}
.dc-grid-input-style .ant-select-selector, .dc-grid-input-style select{
  background-color:transparent;
  box-shadow: unset;
  border: none;
}
/* user permissions start */
.dc-hide-from-view .wj-cell:last-child{
  background: rgb(240, 246, 249);
  border-left: 2px solid #9DBCD9;
  padding-left: 0px;
}
/* user permissions end */
/* .wj-cell.wj-frozen,
.wj-cell.wj-alt.wj-frozen {
    background: rgb(255, 255, 125);
} */
.dc-select-transparent .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
  background-color: transparent;
  border:transparent;
}
/* .wj-cells .wj-cell:hover {
  overflow: visible;
  z-index: 6;
  color: black;
  text-shadow:
      0 0 1px white,
      0 0 2px white,
      0 0 3px white,
      0 0 4px white,
      0 0 5px white,
      0 0 6px white,
      0 0 7px white,
      0 0 8px white,
      0 0 9px white,
      0 0 10px white
} */
.dc-tags .wj-cells .wj-cell:focus{
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: thin;
}

.dc-tags .wj-cells .wj-cell:focus::-webkit-scrollbar{
  -webkit-appearance: none;
  width: 5px;
  height: 5px;
}
.dc-tags .wj-cells .wj-cell:focus::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: var(--scrollbarColor);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
.dc-tags .wj-cell:nth-child(4):not(.wj-header){
  padding: 10px;
  justify-content: flex-start;
}
.dc-boq-cell .wj-cell{
  line-height: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* .data-present .wj-btn-glyph .wj-glyph-plus{
  color: red;
} */
.highlighted-text .wj-cell{color: red;}
.highlighted-text-2{color: green;}
.room-matrix-table .wj-colheaders:first-child .wj-row:first-child .wj-cell.wj-header:not(:nth-child(2)){
  background: #ffffff !important;
}
.room-matrix-table .wj-colheaders:first-child .wj-row:nth-child(2) .wj-cell.wj-header{
  background: #ffffff !important;
}
.room-matrix-table .wj-row .wj-cell{
  background: #e9eaeb !important;
}
.room-matrix-table .wj-row .wj-cell.wj-alt{
  background: #ffffff !important;
}
.room-matrix-table .wj-colfooters .wj-row .wj-cell{
  background: #F0F6F8 !important;
  border-top: 1px solid black;
}
.room-matrix-table .wj-row .wj-cell.wj-state-multi-selected {
  background: #306da5 !important;
}
.public-area-table .wj-colfooters .wj-row .wj-cell{
  background: #F0F6F8 !important;
  border-top: 1px solid black;
}
.data-present{
  position: absolute;
  top: 0;
  left: 0;
  font-size: 20px;
  height: 100%;
  background: coral;
}
.data-present::before{
  content: '';
  border-left: 4px solid coral;
}
/* use flex display to center-align cells vertically */
.wj-flexgrid .wj-cell {
  display: flex;
  align-items: center;
  justify-content: center;
}

.wj-cell.wj-has-notes:after {
  position: absolute;
  content: '';
  width: 0;
  right: 0;
  top: -10px;
  border: 10px solid transparent;
  border-right: 10px solid rgb(0, 34, 226);
  opacity: .5;
}
.wj-cell.wj-has-notes:hover:after {
  opacity: 1;
}
.disabledCell {
  color: #aaaaaa !important;
  opacity: 0.7;
}
.disabledCell:hover {
  color: #aaaaaa !important;
  opacity: 0.7;
}
.isChanged{
  background-color: rgb(202, 248, 156) !important;
}
.column-picker-icon {
  cursor: pointer;
  color: #ff8754;
  margin: 3px;
}

/* .column-picker-div {
  display: none;
} */

.wj-listbox.column-picker {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0 10px;
  columns: 4;
  /* IE fallback */
  padding: 12px;
  /* margin-left: 12px;
  margin-top: 26px; */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.wj-listbox .wj-listbox-item > label {
  display: flex;
  margin: 0 0 3px 0;
}

.column-picker-div .wj-listbox .wj-listbox-item.wj-state-selected {
  background: transparent;
  color: inherit;
}

.wj-listbox .wj-listbox-item:hover {
  background: rgba(0, 0, 0, 0.05);
}

.wj-listbox .drop-marker {
  position: absolute;
  background: #0085c7;
  opacity: 0.5;
  pointer-events: none;
  z-index: 1000;
}

.col-picker-collapse .ant-collapse-header{height: 46px;}

/* CSS FOR 12 VENDORS MAX */
.wj-colheaders > .wj-row:first-child .wj-cell.vendor-column1 {
  background-color: #ccc672;
}
.wj-colheaders > .wj-row:first-child .wj-cell.vendor-column2 {
  background-color: var(--palepurple);
}
.wj-colheaders > .wj-row:first-child .wj-cell.vendor-column3 {
  background-color: var(--palered);
}
.wj-colheaders > .wj-row:first-child .wj-cell.vendor-column4 {
  background-color: var(--palegreen);
}
.wj-colheaders > .wj-row:first-child .wj-cell.vendor-column5 {
  background-color: var(--paleorange);
}
.wj-colheaders > .wj-row:first-child .wj-cell.vendor-column6 {
  background-color: var(--paleblue);
}
.wj-colheaders > .wj-row:first-child .wj-cell.vendor-column7 {
  background-color: #ccc672;
}
.wj-colheaders > .wj-row:first-child .wj-cell.vendor-column8 {
  background-color: var(--palepurple);
}
.wj-colheaders > .wj-row:first-child .wj-cell.vendor-column9 {
  background-color: var(--palered);
}
.wj-colheaders > .wj-row:first-child .wj-cell.vendor-column10 {
  background-color: var(--palegreen);
}
.wj-colheaders > .wj-row:first-child .wj-cell.vendor-column11 {
  background-color: var(--paleorange);
}
.wj-colheaders > .wj-row:first-child .wj-cell.vendor-column12 {
  background-color: var(--paleblue);
}

.wj-colheaders > .wj-row:first-child .wj-cell.selection-column,
.wj-colheaders > .wj-row:first-child .wj-cell.savings-column {
  background-color: #c2ddcc;
}
