/* .editable-row .ant-form-explain {
    position: absolute;
    font-size: 12px;
    margin-top: -4px;
  } */
  .editable-cell {
    position: relative;
  }
  
  .editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
  }
  
  .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 4px 11px;
  }
  
  [data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #434343;
  }
  .ant-table-tbody > tr .ant-table-wrapper:only-child .ant-table{
    margin:unset !important;
    padding: 5px;
  }
  .upload-icon-style{
    font-size: 18px;
    color: skyblue;
  }
  .highlight-icon-style{
    font-size: 18px;
  }
  .project-title{
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    /* line-height: 22px; */
    color: #5F5A60;
  }
  .transparent-btn{
    border: transparent;
    padding: 0px;
    background: transparent;
  }
  .ptable-1 .editable-cell-value-wrap{
    padding: 0px !important;
  }
  .btn-success{
    background: #63AA4A;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}
.border-right{border-right: 1px solid #9D9E9E;}
.project-name {
  /* border-right: 1px solid #9D9E9E; */
  text-align: center;
  flex: inherit;
  width: 353px;
}
.project-name.verticalLayout-form .ant-col.ant-form-item-label {
  text-align: center !important;
}
.project-management.ant-tabs .select-tabpanel::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: calc(100% + 5px);
  /* background: var(--problue); */
  top: -1px;
  left: 0;
}
.reset-cascade-2 .reset-cascade .ant-tabs-bar{
  width: unset !important;
  margin-left: unset !important;
}
.reset-cascade-2 .reset-cascade .ant-tabs-nav-wrap{
  border-top: none !important;
}
.operations-data-tabs .project-management .ant-tabs-bar {
  width: 70%;
  margin-left: auto;
}
.select-tabpanel {
  position: absolute;
  top: 0;
  width: 30%;
  padding: 8px 17px 2px;
}
.project-dtls-right {
  padding: 0% 5% 4% !important;
  flex: inherit;
  width: calc(100% - 353px);
  max-width: inherit;
}
.project-management.ant-tabs .select-tabpanel .ant-select.searchSelect .ant-select-arrow {
  top: 51% !important;
  left: 328px;
  right: auto;
}
/* .project-dtls-right > .ant-row > .ant-col {
  padding: 4% !important;
} */
/* .project-dtls-right .ant-row.ant-form-item {
  margin-bottom: 20px !important;
  max-width: 341px;
  margin: auto;
} */
.project-name .ant-picker {
  background: #FFFFFF;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px !important;
  width: 230px;
  max-width: 100%;
}
.operations-data-tabs.ant-tabs .ant-tabs-tab {
  text-align: center;
}
.reset-cascade .ant-tabs-tab {
  width: unset !important;
  border-bottom: none !important;
}
.operations-data-tabs.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  padding: 4px 10px;
  width: auto;
  margin-right: 0;
  box-shadow: none;
}
/* .project-dtls-right > .ant-row > .ant-col:first-child {
  padding: 4% 2% 4% 4% !important;
}
.project-dtls-right > .ant-row > .ant-col:nth-child(2) {
  padding: 4% 4% 4% 2% !important;
} */
.project-dtls-right > .ant-row {
  padding-left: 12px;
  max-width: 1000px;
  margin-left: auto !important;
  margin-right: auto !important;
}
.project-management.ant-tabs .ant-select.searchSelect {
  width: 333px;
  min-width: 286px;
  max-width: 100%;
  text-align: left;
  position: unset;
}
/* form#ProjectItem .ant-form-item-label label::after {
  display: none;
} */
.reset-cascade .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab{
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
}
.reset-cascade .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab:first-child{
  display: block;
  border-radius: 5px 0px 0px 0px !important;
}
.reset-cascade .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab:last-child{
  /* display: block; */
  border-radius: 0px 6px 0px 0px !important;
}
.project-management .ant-tabs-bar .ant-tabs-nav-wrap .ant-tabs-tab:first-child{
  display: none;
}
.operations-data-tabs.ant-tabs .ant-tabs-nav > div {
  display: flex;
}
.project-management.ant-tabs .ant-tabs-tab:first-child > div {
  display: flex;
  justify-content: space-between;
}
.project-management.ant-tabs .ant-tabs-tab:first-child > div span.project-tab-name {
  width: 28%;
  text-align: center;
  padding: 6px 0 0 15px;
  min-width: 80px;
}
.project-management .ant-tabs-nav-wrap .ant-tabs-tab:first-child {
  padding: 1px 16px 3px;
  border-top: 0;
}
/* .ant-table-wrapper.ptable-1.specification-table th:not(:first-child):not(:nth-child(2)) {
  min-width: 100px;
} */
.project-name .ant-picker-input > input {
  text-align: center;
}
.avatar-uploader.project-logo .ant-upload.ant-upload-select-picture-card {
  float: none;
  margin: 8px auto 10px;
  border: none;
  background: transparent;
  width: 87px;
  height: 87px;
}
.project-name .ant-select, 
.project-name .ant-input-number {
  width: 230px;
  margin: 0 auto;
  max-width: 100%;
}
.project-name .ant-checkbox-wrapper {
  width: auto;
  display: inline-block;
}
.proj-clientName input.ant-input,
form#ProjectItem .ant-select-selection-item {
  color: rgba(0, 0, 0, 0.65);
}
.project-name .ant-input-number input {
  text-align: center;
}
/* .project-name .ant-form-item {
  margin-bottom: 35px;
} */
.project-logo .ant-upload button.ant-btn {
  margin-top: 50px;
  border: none;
  background: transparent;
  box-shadow: none;
  color: #3C7AB4;
  position: absolute;
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
}
.project-logo .ant-upload > .ant-upload {
    border-radius: 50%;
    background: gainsboro;
    /* background: url("./images/profile.svg") no-repeat;
    background-size: cover; */
    width: 87px;
    height: 87px;
    overflow: hidden;
    padding: 0;
}
.project-logo .ant-upload > .ant-upload img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.master-outer-card .ant-input-number {
  width: 100%;
}
.card-pl-0 .card-body{
  padding-left: 0 !important;
}
.ant-input-search.search-field.ant-input-affix-wrapper {
  border: none;
  background: #FFFFFF;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px !important;
  padding: 1px 0 1px 11px;
  width: 230px;
  float: right;
  max-width: 100%;
}
.ant-input-search.search-field.ant-input-affix-wrapper input.ant-input {
  box-shadow: none;
  background: transparent;
}
.ant-table-container table > thead > tr:first-child th:first-child,
.ant-table.ant-table-bordered thead > tr > th, 
.ant-table.ant-table-bordered tbody > tr > td, 
.ant-table.ant-table-bordered tfoot > tr > th, 
.ant-table.ant-table-bordered tfoot > tr > td {
  border-right-color: rgb(0 0 0 / 0.1) !important;
}
.taxType-table tr:not([data-row-key]).ant-table-row td:first-child > * {
  display: none;
}
.taxType-table tr:not([data-row-key]).ant-table-row td:nth-child(2) {
  font-size: 0;
}
.projDtls-save {
  float: right;
  /* margin-right: 7.25%; */
}
.grey-btn-outer.projDtls-save button.ant-btn.grey-btn:last-child {
  border-radius: 3px;
}
.project-tables .ant-select .ant-select-selector,
.project-tables .ant-input-number,
.project-tables textarea,
.project-tables .ant-picker, 
.project-tables input {
  box-shadow: none;
  border: none;
  background: transparent;
}
.project-tables textarea:focus {
  box-shadow: none;
  outline: none;
  border: none;
}
.project-tables .ant-input-number input, 
.project-tables input {
    text-align: center;
}
/* .project-tables .ant-select .ant-select-arrow {
    visibility: hidden;
} */
.project-tables textarea {
  padding: 4px 0;
  resize: none;
  text-align: center;
}
.project-tables tr.ant-table-row:hover .editable-cell-value-wrap {
  border: none;
}
.project-tables .ant-row.ant-form-item {
  margin-bottom: 0;
}
.project-tables td.ant-table-cell {
  padding: 0 8px;
}
/* .project-tables .ant-table-tbody > tr > td {
  height: auto;
} */
/* .project-tables td.ant-table-cell .editable-cell-value-wrap {
  padding: 0px !important;
} */
.project-tables td.ant-table-cell .ant-form-item-control-input {  
  min-height: 28px;
}
.clear-float {clear:both;}
/* .proj-catMarkup table, .proj-docRegister table {table-layout: auto !important;} */
.ant-table-row:nth-child(even) td.ant-table-cell.ant-table-cell-fix-right {
  background: white;
}
.ant-table-row:nth-child(odd) td.ant-table-cell.ant-table-cell-fix-right {
  background: #E7ECEE;
}
.ant-table-row:nth-child(even) td.ant-table-cell.ant-table-cell-fix-left {
  background: white;
}
.ant-table-row:nth-child(odd) td.ant-table-cell.ant-table-cell-fix-left {
  background: #E7ECEE;
}
.specification-table .ant-table-row.table-row-light td.ant-table-cell.ant-table-cell-fix-right {
  background: white;
}
.specification-table .ant-table-row.table-row-dark td.ant-table-cell.ant-table-cell-fix-right {
  background: #E7ECEE;
}
td .ant-select-selection-item {
    color: rgb(0 0 0 / 65%);
    font-weight: 400;
}
.specification-table .ant-table .ant-table-expanded-row-fixed {
  padding: 0;
  margin: 0;
}
.specification-table .ant-table .ant-table-expanded-row td.ant-table-cell,
.specification-table .ant-table .ant-table {
  padding: 0 !important;
}
.specification-table .ant-table .ant-table th.ant-table-cell:first-child {
  width: 45px;
  min-width: 45px;
}
.specification-table .ant-table .ant-table th.ant-table-cell:nth-child(2) {
  min-width: 50px;
  width: 50px;
}
.specification-table th.ant-table-cell {
  font-size: 15px;
  font-weight: 600;
}
.specification-table .ant-table .ant-table th.ant-table-cell {
  font-size: 14px;
}
/* .specification-table .ant-table-wrapper.ptable-1.specification-table th:not(:first-child):not(:nth-child(2)):not(:nth-child(3)) {
  min-width: 100px;
} */
.specification-table td.ant-table-cell.ant-table-row-expand-icon-cell button {
  margin: auto;
  float: none;
}
.specification-table .ant-table .ant-table-expanded-row td.ant-table-cell:first-child button {
  border: 1px solid #f0f0f0;
  background: white;
  padding: 1px 1px;
  height: auto;
  line-height: 0;
}
.specification-table .ant-table .ant-table-expanded-row td.ant-table-cell:first-child button .anticon {
  font-size: 11px;
  line-height: 0;
}
.specification-table .ant-table .ant-table-expanded-row .ant-table-thead tr th {
  background: white;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
}
.specification-table .ant-table .ant-table-expanded-row:nth-child(odd) tr.ant-table-row,
.specification-table .ant-table tr.table-row-dark + .ant-table-expanded-row tr.ant-table-row  {
  background: #E7ECEE;
}
.specification-table .ant-table .ant-table-expanded-row:nth-child(even) tr.ant-table-row,
.specification-table .ant-table tr.table-row-light + .ant-table-expanded-row tr.ant-table-row {
  background: white;
}
.file-upload-search {
  width: 315px;
  margin-left: auto;
  max-width: 100%;
}
.specification-table span.anticon, 
.project-tables span.anticon {
  color: #9D9E9E;
}
.specification-table .ant-table th {
  padding: 0 7px;
}
.specification-table td.ant-table-cell .ant-select {
  width: 100%;
}
/* .project-management.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
  box-shadow: none;
} */
.ant-tabs.operations-data-tabs.ant-tabs-card .ant-tabs-nav-scroll {
  text-align: right;
  box-shadow: 0px 0.17em 0.17em rgb(0 0 0 / 25%);
  margin: 10px 17px 10px 10px;
  z-index: 2;
}
.operations-data-tabs.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab::after {
  content: "";
  width: 1px;
  height: 20px;
  display: block;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  background: rgb(0 0 0 / 0.1);
  left: -1px;
}
.tab-title-left .ant-tabs-nav-scroll{
  text-align: left !important;
}
.ant-tabs.operations-data-tabs .master-justify-between {
  padding: 20px 0px;
}
.project-name .ant-form-item-label > label, 
.project-dtls-right .ant-form-item-label > label,
.project-name .ant-input, 
.project-dtls-right .ant-input {
  font-weight: 300;
  color: black;
  font-size: 14px;
}
.operations-data-tabs .project-management {
  margin-top: 3px;
}
.proj-catMarkup .ant-table-header {
  width: calc(100% + 3px);
}
.project-search-outer.projCat-search-outer {
  max-width: inherit;
}
.project-search-outer.projCat-search-outer {
    margin: 0 15px;
}
.proj-docRegister table tr td:not(:first-child) {
  min-width: 100px;
}
.proj-catMarkup.master-table table td:nth-child(2) .ant-select {
  max-width: 150px;
}
.proj-catMarkup table td:not(:first-child):not(:last-child),
.proj-catMarkup table th:not(:first-child):not(:last-child) {
  min-width: 150px;
  width: 150px;
}
.proj-catMarkup.master-table table tr td:nth-child(2),
.proj-catMarkup.master-table table tr th:nth-child(2) {
  min-width: 170px;
  width: 170px;
}
.master-table.proj-catMarkup table th:nth-last-child(2),
.master-table.proj-catMarkup table td:nth-last-child(1) {
  min-width: 70px;
  width: 70px;
}
.proj-catMarkup.master-table table tr td:first-child,
.proj-catMarkup.master-table table tr th:first-child {
  min-width: 50px;
  width: 50px;
}
.master-table.proj-catMarkup table .ant-table-row:nth-child(even) td:nth-last-child(1),
.specification-table table .ant-table-row:nth-child(even) td:nth-last-child(1) {background: white;}
.proj-docRegister table th.ant-table-cell:nth-last-child(2) {
  border-right: none;
}
.proj-docRegister table th.ant-table-cell:last-child {
  display: none;
}
.proj-catMarkup.proj-docRegister .ant-table-header {
  width: 100%;
}
/* .hide-empty-rows.specification-table .ant-table-body {
  margin-right: -7px;
  padding-right: 0;
} */
.specification-table .ant-table table tr th:nth-last-child(2) {
  border-left: 2px solid #d8d8d8 !important;
}
.specification-table .ant-table table tr td:last-child {
  border-left: 0.12em solid rgba(0, 0, 0, 0.1) !important;
}
.slick-vertical .slick-slide:not(.slick-current) {
  padding-top: 6px;
}
.specification-table + .pagination-display {
  right: 85px;
}
.project-tables .ant-select,
.specification-markup .ant-form-item-control-input-content .ant-input-number {
  max-width: 100%;
}
.delete-image .ant-form-item-control-input {
  margin-bottom: -25px;
}
th.ant-table-cell.scrollRight::after {
  box-shadow: none;
}
.ant-table-cell.scrollRight {
  font-size: 0;
}
/* .ant-table-wrapper.specification-table td.ant-table-cell.ant-table-cell-fix-right.ant-table-cell-fix-right-first {
  display: none;
} */
.specification-markup .ant-modal-content {
  padding-bottom: 40px;
}
/* th.ant-table-cell.spec-action.ant-table-cell-fix-right, td.ant-table-cell.spec-action.ant-table-cell-fix-right {
  min-width: 100px;
  width: 100px;
} */
td.ant-table-cell.spec-action.ant-table-cell-fix-right {
  padding-left: 3px;
  padding-right: 3px;
}
.spec-action .ant-space-item:not(:last-child) {
  margin-right: 5px !important;
}
.specification-view-modal .ant-modal-content {
  padding-top: 30px;
}
.specification-table .ant-table-row.table-row-light {
  background-color: #ffffff;
}
.specification-table .ant-table-row.table-row-dark {
  background-color: #E7ECEE;
}
th.ant-table-cell.scrollRight::before {
  right: -20px;
}
.tab-extra-left .ant-tabs-extra-content{float: left !important;}
.tab-extra-right .ant-tabs-extra-content{float: right !important;}
.width-266{width:266px !important;}
@media screen and (max-width: 991px) {
  .project-name {
    border-right: none;
    width: 100%;
  } 
}
/* @media screen and (max-width: 1400px) and (min-width: 1366px) {
  .operations-data-tabs.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
    width: 151px;
  }
} */
@media screen and (max-width: 2560px) and (min-width: 1200px) {
  .operations-data-tabs.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
    /* min-width: 110px; */
    min-width: 80px;
    height: 24px;
  }
  .project-name {
    width: 286px;
  }
  .project-dtls-right {
    width: calc(100% - 286px);
  }
  .project-management.ant-tabs .ant-select.searchSelect {
    width: 266px;
    min-width: 266px;
  }
  .project-management.ant-tabs .select-tabpanel .ant-select.searchSelect .ant-select-arrow {
    left: 244px;
  }
  
}

@media screen and (min-width: 1441px) {
  /* .ant-table-wrapper.specification-table th.ant-table-cell.ant-table-cell-fix-right.ant-table-cell-fix-right-first,
  .ant-table-wrapper.specification-table td.ant-table-cell.ant-table-cell-fix-right.ant-table-cell-fix-right-first {
    width: 0;
    padding: 0;
    min-width: 0;
    right: 0 !important;
  } */
}
@media screen and (max-width: 1440px) {
  .specification-table table tr td:not(:first-child):not(:nth-child(2)):not(:nth-last-child(2)):not(:last-child) {
    min-width: 150px;
    width: 150px;
  }
  .hide-empty-rows.specification-table .ant-table-body {
    padding-right: 0px;
    margin-right: -7px;
  }
  .ant-table-cell.scrollRight::before {
    content: "";
    width: 53px;
    display: block;
    height: 100%;
    border-left: 1px solid rgb(0 0 0 / 0.1);
    margin-left: -60px;
    border-right: 1px solid rgb(0 0 0 / 0.1);
}
  .ant-table-cell.scrollRight {
      border: none !important;
  }
  .ant-table-row:nth-child(odd) .ant-table-cell.scrollRight::before {
    background: #E7ECEE;
  }
  .ant-table-row:nth-child(even) .ant-table-cell.scrollRight::before {
    background: white;
  }
  .specification-table .ant-table-row.table-row-dark .ant-table-cell.scrollRight::before {
    background: #E7ECEE;
  }
  .specification-table .ant-table-row.table-row-light .ant-table-cell.scrollRight::before {
    background: white;
  }
  th.ant-table-cell.scrollRight::before {
    top: 0;
    position: absolute;
    content: "...";
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f1f0f0;
    width: 53px;
    /* margin-left: -61px; */
    /* border-right: 2px solid rgb(0 0 0 / 0.1); */
    right: -20px;
  }
  th.ant-table-cell.scrollRight {
    right: 159px !important;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td.scrollRight::before {
    border-top: 2px solid #9DBCD9;
    border-bottom: 2px solid #9DBCD9;
  }
  .ant-table-cell.hs-code {padding-right: 60px !important;}
}
@media screen and (max-width: 1200px) {
  .project-management.ant-tabs .select-tabpanel .ant-select.searchSelect .ant-select-arrow {
    left: auto;
    right: 30px;
  }
  .project-management.ant-tabs .ant-select.searchSelect {
    min-width: inherit;
  }
}