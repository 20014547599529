@import url(https://fonts.googleapis.com/css2?family=Signika+Negative:wght@300;400;600;700&display=swap);
*{
  font-family: Signika Negative;
}
body {
  margin: 0;
  font-family: 'Signika Negative', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a{
  color: #316EA6 !important;
  color: var(--probluedarker) !important;
}
/* Themes */
:root {
  --problue: #88B7E2;
  --probluelight: #D8E5F1;
  --probluedark: #80ADD6;
  --probluedarker: #316EA6;
  --paleblue: rgb(217, 226, 243);
  --paleorange: rgb(255, 242, 204);
  --palegreen: rgb(226, 239, 217);
  --palered: rgb(247, 202, 172);
  --palepurple: rgb(229, 214, 241);
  --scrollbarColor:#87B6E2;
}
.wj-flexgrid {} 
.table-container-height2 .wj-flexgrid {max-height: 69vh;}
.proj-spec-table-child{ min-height: 20vh;}
.proj-spec-table-child .wj-cell:not(.wj-header){border-bottom: 1px solid #d9dcdd !important;}
.my-cell {padding: 0px;}
.my-cell.wj-state-selected .my-icon {display: none;}
.my-cell:not(.wj-state-selected) .my-dropdown {display: none;}
/* .my-icon {
  height: 100%;
  width: 100%;
} */

.my-icon > svg {
  margin-top: 9px;
/*   height: 100%;
  width: 100%; */
}
.wj-content{text-align: center;}
.wj-cell.wj-align-right{text-align: center !important;}
.pagination-pull-right{float: right;}
.wj-cell.wj-alt{
  background: #e9eaeb;
}
/* .wj-cell.wj-alt.wj-state-selected{
  background: #0085c7 !important;
} */
.wj-cell:not(.wj-header){
  border-bottom: none !important;
}
.wj-cell{
  border-right: 1px solid #d9dcdd !important;
}
div[wj-part="root"]::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 6px;
  height: 6px; 
  background: #C4C4C4;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
div[wj-part="root"]::-webkit-scrollbar-thumb {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: var(--scrollbarColor);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
div[wj-part="root"]{
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbarColor) #C2D2E4;
  padding-right: 0px;
  margin-right: -7px;
}

.wj-row .wj-cell.wj-header {
  background: white;
  color: #5F5A60 !important;
}
div[wj-part="rh"] .wj-row .wj-cell.wj-header {border: none;}
/* div[wj-part="rh"] .wj-row:nth-child(odd) .wj-cell.wj-header {
  background: white;
} */
.wj-row .wj-cell.wj-header.wj-header-alt {
    background: #e9eaeb;
}
.wj-row .wj-cell.wj-state-multi-selected {
  background: #306da5 !important;
}
/* wijmo filter start */
.wj-columnfiltereditor .wj-listbox {
  min-height: 72px;
  max-height: 150px;
  height: unset !important;
}
/* wijmo filter end */
/* wijmo row hover start */
.wj-cells .wj-cell.wj-state-selected{
  border: 2px solid #9DBCD9 !important;
  background:#ffffff !important;
  color: #5F5A60;
}
/* .wj-cell:hover{
  border: 2px solid #9DBCD9 !important;
} */
/* wijmo row hover end */
.wj-control{
  color: #5F5A60 !important;
}
.dc-grid-input-style .ant-select-selector, .dc-grid-input-style select{
  background-color:transparent;
  box-shadow: unset;
  border: none;
}
/* user permissions start */
.dc-hide-from-view .wj-cell:last-child{
  background: rgb(240, 246, 249);
  border-left: 2px solid #9DBCD9;
  padding-left: 0px;
}
/* user permissions end */
/* .wj-cell.wj-frozen,
.wj-cell.wj-alt.wj-frozen {
    background: rgb(255, 255, 125);
} */
.dc-select-transparent .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
  background-color: transparent;
  border:transparent;
}
/* .wj-cells .wj-cell:hover {
  overflow: visible;
  z-index: 6;
  color: black;
  text-shadow:
      0 0 1px white,
      0 0 2px white,
      0 0 3px white,
      0 0 4px white,
      0 0 5px white,
      0 0 6px white,
      0 0 7px white,
      0 0 8px white,
      0 0 9px white,
      0 0 10px white
} */
.dc-tags .wj-cells .wj-cell:focus{
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: thin;
}

.dc-tags .wj-cells .wj-cell:focus::-webkit-scrollbar{
  -webkit-appearance: none;
  width: 5px;
  height: 5px;
}
.dc-tags .wj-cells .wj-cell:focus::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: var(--scrollbarColor);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
.dc-tags .wj-cell:nth-child(4):not(.wj-header){
  padding: 10px;
  justify-content: flex-start;
}
.dc-boq-cell .wj-cell{
  line-height: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* .data-present .wj-btn-glyph .wj-glyph-plus{
  color: red;
} */
.highlighted-text .wj-cell{color: red;}
.highlighted-text-2{color: green;}
.room-matrix-table .wj-colheaders:first-child .wj-row:first-child .wj-cell.wj-header:not(:nth-child(2)){
  background: #ffffff !important;
}
.room-matrix-table .wj-colheaders:first-child .wj-row:nth-child(2) .wj-cell.wj-header{
  background: #ffffff !important;
}
.room-matrix-table .wj-row .wj-cell{
  background: #e9eaeb !important;
}
.room-matrix-table .wj-row .wj-cell.wj-alt{
  background: #ffffff !important;
}
.room-matrix-table .wj-colfooters .wj-row .wj-cell{
  background: #F0F6F8 !important;
  border-top: 1px solid black;
}
.room-matrix-table .wj-row .wj-cell.wj-state-multi-selected {
  background: #306da5 !important;
}
.public-area-table .wj-colfooters .wj-row .wj-cell{
  background: #F0F6F8 !important;
  border-top: 1px solid black;
}
.data-present{
  position: absolute;
  top: 0;
  left: 0;
  font-size: 20px;
  height: 100%;
  background: coral;
}
.data-present::before{
  content: '';
  border-left: 4px solid coral;
}
/* use flex display to center-align cells vertically */
.wj-flexgrid .wj-cell {
  display: flex;
  align-items: center;
  justify-content: center;
}

.wj-cell.wj-has-notes:after {
  position: absolute;
  content: '';
  width: 0;
  right: 0;
  top: -10px;
  border: 10px solid transparent;
  border-right: 10px solid rgb(0, 34, 226);
  opacity: .5;
}
.wj-cell.wj-has-notes:hover:after {
  opacity: 1;
}
.disabledCell {
  color: #aaaaaa !important;
  opacity: 0.7;
}
.disabledCell:hover {
  color: #aaaaaa !important;
  opacity: 0.7;
}
.isChanged{
  background-color: rgb(202, 248, 156) !important;
}
.column-picker-icon {
  cursor: pointer;
  color: #ff8754;
  margin: 3px;
}

/* .column-picker-div {
  display: none;
} */

.wj-listbox.column-picker {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0 10px;
  -webkit-columns: 4;
          columns: 4;
  /* IE fallback */
  padding: 12px;
  /* margin-left: 12px;
  margin-top: 26px; */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.wj-listbox .wj-listbox-item > label {
  display: flex;
  margin: 0 0 3px 0;
}

.column-picker-div .wj-listbox .wj-listbox-item.wj-state-selected {
  background: transparent;
  color: inherit;
}

.wj-listbox .wj-listbox-item:hover {
  background: rgba(0, 0, 0, 0.05);
}

.wj-listbox .drop-marker {
  position: absolute;
  background: #0085c7;
  opacity: 0.5;
  pointer-events: none;
  z-index: 1000;
}

.col-picker-collapse .ant-collapse-header{height: 46px;}

/* CSS FOR 12 VENDORS MAX */
.wj-colheaders > .wj-row:first-child .wj-cell.vendor-column1 {
  background-color: #ccc672;
}
.wj-colheaders > .wj-row:first-child .wj-cell.vendor-column2 {
  background-color: var(--palepurple);
}
.wj-colheaders > .wj-row:first-child .wj-cell.vendor-column3 {
  background-color: var(--palered);
}
.wj-colheaders > .wj-row:first-child .wj-cell.vendor-column4 {
  background-color: var(--palegreen);
}
.wj-colheaders > .wj-row:first-child .wj-cell.vendor-column5 {
  background-color: var(--paleorange);
}
.wj-colheaders > .wj-row:first-child .wj-cell.vendor-column6 {
  background-color: var(--paleblue);
}
.wj-colheaders > .wj-row:first-child .wj-cell.vendor-column7 {
  background-color: #ccc672;
}
.wj-colheaders > .wj-row:first-child .wj-cell.vendor-column8 {
  background-color: var(--palepurple);
}
.wj-colheaders > .wj-row:first-child .wj-cell.vendor-column9 {
  background-color: var(--palered);
}
.wj-colheaders > .wj-row:first-child .wj-cell.vendor-column10 {
  background-color: var(--palegreen);
}
.wj-colheaders > .wj-row:first-child .wj-cell.vendor-column11 {
  background-color: var(--paleorange);
}
.wj-colheaders > .wj-row:first-child .wj-cell.vendor-column12 {
  background-color: var(--paleblue);
}

.wj-colheaders > .wj-row:first-child .wj-cell.selection-column,
.wj-colheaders > .wj-row:first-child .wj-cell.savings-column {
  background-color: #c2ddcc;
}

/* margin start */
.mb-0{
  margin-bottom: 0px !important;
}
.mb-5{
  margin-bottom: 5px;
}
.mb-10{
  margin-bottom: 10px;
}
.mb-15{
  margin-bottom: 15px;
}
.mb-20{
  margin-bottom: 20px;
}
.mb-25{
  margin-bottom: 25px;
}
.mb-30{
  margin-bottom: 30px;
}
.ml-0{margin-left: 0px;}
.ml-33{
  margin-left: 33px;
}
.ml-24{
  margin-left: 24px;
}
.mr-0{margin-right: 0 !important;}
.mr-20{
  margin-right: 20px;
}
.mr-15{
  margin-right: 15px;
}
.ml-15{margin-left: 15px;}
.mt-15{margin-top: 15px !important;}
.mt-50{
  margin-top: 50px;
}
.mr-10{
  margin-right: 10px;
}
.mt-0{
  margin-top: 0px !important;
}
.mt-10{
  margin-top: 10px !important;
}
.mt-40{
  margin-top: 40px;
}
.mt-17{
  margin-top: 17px;
}
.mt-20{
  margin-top: 20px;
}
.mt-25{
  margin-top: 25px;
}
.mt-6{
  margin-top: 6px;
}
.ml-10{
  margin-left: 10px;
}
.my-5{
  margin: 5px 0px;
}
/* margin end */
/*padding start*/
.px-0{
  padding-left: 0px;
  padding-right: 0px;
}
.p-25-pt-0{
  padding: 0px 25px 25px 25px;
}
.pt-8-pb-25{
  padding-top: 8px;
  padding-bottom: 25px;
}
.p-23{
  padding: 23px;
}
.p-25{
  padding: 25px;
}
.p-10{
  padding: 10px;
}
.p-15{
  padding: 15px;
}
.pr-7{
  padding-right: 7px;
}
.pr-10{
  padding-right: 10px;
}
.pl-0{padding-left: 0px;}
.pl-17{
  padding-left: 17px !important;
}
.pl-15{padding-left: 15px;}
.px-10{
  padding: 0px 10px;
}
.px-15{
  padding: 0px 15px;
}
.pl-15-pr-15{
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.px-25{
  padding: 0px 25px;
}
.py-3{padding: 3px 0px;}
.py-25{
  padding: 25px 0px;
}
.py-8{padding: 8px 0px;}
.pb-30{
  padding-bottom: 30px;
}
.pb-25{
  padding-bottom: 25px;
}
.pb-15{
  padding-bottom: 15px;
}
.pb-0 {
  padding-bottom: 0px !important;
}
.pt-0{padding-top: 0 !important;}
.pt-4{padding-top: 4px !important;}
.pt-10{padding-top: 10px !important;}
.pt-20{padding-top: 20px;}
/* padding end */
.place-center{
  display: grid !important;
  place-content: center;
}
/* upload count start */
.cust-input-style{
  width: 32px;
  height: 28px;
  background: #FFFFFF;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  text-align: center;
}
/* upload count end */
/* PQF start */
.pqf-form-box{
  max-width: 935px;
  max-height: 485px;
  margin: auto;
}
.inner-pqf-form-box{
  max-width: 700px;
  margin: auto !important;
}
/* PQF end */
/*tab progress bar start*/
.tab-right-progress-bar .ant-tabs-extra-content{
  width: 46%;
  text-align: end;
}
.tab-right-progress-bar-osne .ant-tabs-extra-content{
  width: 40%;
}
/* .tab-right-progress-bar .ant-progress-text{
  right: 20px !important;
} */
.ant-tabs.ant-tabs-card .ant-tabs-extra-content{
  line-height: 12px !important;
}
/*tab progress bar end*/
/* flex properties start */
.flex-space-between{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dc-inc-space .ant-space-item:not(:last-child){margin-right: 14px !important;}
/* flex properties end */
/* tabs content inline start */
.content-inline-tabs{
  text-align: end;
  position: absolute;
  top: -36px;
  right: 0;
  margin-bottom: 50px;
  z-index: 10;
}
/* tabs content inline end */
/*tabs progress color start*/
.tab-form-progress::after {
  display: block;
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}
.red::after{border-right: 5px solid #C04804;}
.green::after{border-right: 5px solid #63AA4A;}
.amber::after{border-right: 5px solid #FFC107;}
/*tabs progress color end*/
/*Multi upload start*/
.multi-upload{
  overflow-y: auto;
}
/*Multi upload end*/
/* Draggable modal start */
.flexible-modal {
  position: fixed;
  top: 20vh !important;
  z-index: 130;
  padding: 0 25px 25px 25px;
  border-radius: 20px;
  background: #fbfbfb;
  overflow: hidden;
}
.flexible-modal-mask {
  position: fixed;
  height: 100%;
  background: rgba(0,0,0,.45);
  top:0;
  left:0;
  right:0;
  bottom:0;
  z-index: 130;
}
.flexible-modal-resizer {
  position:absolute;
  right:0;
  bottom:0;
  cursor:se-resize;
  margin:5px;
  border-bottom: solid 2px #333;
  border-right: solid 2px #333;
}
.flexible-modal-drag-area{
  height: 50px;
  position:absolute;
  right:0;
  top:0;
  cursor:move;
}
.modal-close-x {
  position: absolute;
  right: 0;
  width: 56px;
  height: 20px;
  font-size: 16px;
  font-style: normal;
  /* line-height: 56px; */
  text-align: center;
  text-transform: none;
  text-rendering: auto;
  color: rgba(0,0,0,.45);
  font-weight: 700;
  cursor: pointer;
  z-index: 140;
}
/* Draggable modal end */
.from-shift{
  cursor: pointer;
}
.shrink{width: 288.5px;}
.expand{width: 627px;}
.reset-placeholder .ant-input:placeholder-shown{
  text-transform:none;
}
.ant-upload.ant-upload-select-picture-card{
  overflow: hidden;
}
.center-object .avatar-uploader > .ant-upload{
  width:46px;
  height:46px;
  border-radius: 100px;
  background: #EFECE8;
}
.avatar-uploader:not(.project-logo) img{
  width: 46px !important;
  height: 46px;
  object-fit: cover;
}
.upload-text{
  position: absolute;
  white-space: nowrap;
  top: 50px;
  left: -10px;
}
.default-upload-icon{
  font-size: 30px;
  position: absolute;
  top: 13px;
  left: 8px;
}
.action-icon{
  font-size: 18px;
  color: #9D9E9E;
  stroke: #9D9E9E;
}
.action-icon-highlight{
  stroke: rgb(66, 135, 245) !important;
}
.grid-icon-size{
  font-size: 17px;
}
.grid-icon-highligh{
  color:#404040;
  stroke: #404040;
}
.grid-icon-new-highligh{
  color: #4287f5;
  stroke: #4287f5;
}
.grid-icon-new-highligh .action-icon{
  color: #4287f5;
  stroke: #4287f5 !important;
}
.dc-highlighted{
  color: #4287f5;
}
.cell-product:not(.wj-state-selected):not(.wj-state-multi-selected) {
  background-color: var(--scrollbarColor) !important;
}
.dc-green-icon{
  color: #0D837C;
}
.dc-red-icon{
  color: #ca2812;
}
.right-box-140{
  min-height: 140px;
}
/* Tooltip start */
[data-tip] {
	position:relative;

}
[data-tip]:before {
	content:'';
	/* hides the tooltip when not hovered */
	display:none;
	content:'';
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-bottom: 5px solid #404040;	
	position:absolute;
	top:30px;
	left:15px;
	z-index:8;
	font-size:0;
	line-height:0;
	width:0;
	height:0;
}
[data-tip]:after {
	display:none;
	content:attr(data-tip);
	position:absolute;
	top:35px;
	left:0px;
	padding:0px 8px;
	background:#404040;
	color:#fff;
	z-index:9;
	font-size: 0.75em;
	height:18px;
	line-height:18px;
	border-radius: 3px;
	white-space:nowrap;
	word-wrap:normal;
}
[data-tip]:hover:before,
[data-tip]:hover:after {
	display:block;
}
/* Tooltip end*/
/*enquiry profile start*/
.enq-noti-comments-box{
  height: 81px;
  background: #F1F0F0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
}
.enq-noti-label{
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #5F5A60;
}
.enq-envelope-btn{
  font-size: 20px;
  color: #857E7E;
}
.dc-enq-comment-box .ant-comment-avatar{display: none;}
.dc-enq-comment-box .ant-list-items{
  height: 145px;
}
.dc-enq-comment-box .ant-comment-inner{padding: 14px 0 !important;}
/* comments scroll start */
.dc-enq-comment-box .ant-list-items{
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: thin;
}
.dc-enq-comment-box .ant-list-items::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 7px;
}

.dc-enq-comment-box .ant-list-items::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: var(--scrollbarColor);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
/* comments scroll end */
.center-object .avatar-uploader-enq > .ant-upload{
  width:103px;
  height:103px;
  border-radius: 100px;
  background: #EFECE8;
}
.avatar-uploader-enq img{
  width: 103px !important;
  height: 103px;
  object-fit: cover;
}
.upload-text-enq{
  position: absolute;
  white-space: nowrap;
  top: 105px;
  left: 15px;
}
.default-upload-icon-enq{
  font-size: 88px;
  position: absolute;
  top: 13px;
  left: 8px;
}
.remove-upload-img{
  margin-bottom: 0;
  text-align: center;
}
.delete-placement{
  position: absolute;
  top: 33px;
  right: 15px;
}
/* proj enq form profile pic start  */
.center-object .avatar-uploader-enq-2 > .ant-upload{
  width:56.5px;
  height:56.5px;
  border-radius: 100px;
  background: #EFECE8;
}
.avatar-uploader-enq-2 img{
  width: 56.5px !important;
  height: 56.5px;
  object-fit: cover;
}
.upload-text-enq-2{
  position: absolute;
  white-space: nowrap;
  top: 63px;
  left: 3px;
}
.default-upload-icon-enq-2{
  font-size: 46px;
  position: absolute;
  top: 8px;
  left: 5px;
}
.profile-upload-middle{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
/* proj enq form profile pic end */
/*enquiry profile end*/
.flex-align-middle{
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-align-middle-2 .ant-space-item{
  display: flex;
  align-items: center;
  justify-content: center;
}
/* tabs hide start */
.hide-tab-headers .ant-tabs-nav-scroll{display: none;}
.hide-tab-headers .ant-tabs-bar{border:none;}
.child-tab .ant-tabs-nav-scroll{display: block;}
/* tabs hide end */
.tabs-mb-0 .ant-tabs-bar{
  margin-bottom: 0;
}
.tabs-mb-10 .ant-tabs-bar{
  margin-bottom: 10px;
}
.tabs-hide-conflicting-background .ant-tabs-bar{
  background: none !important;
}
.ant-tabs .ant-tabs-left-content{
  padding-left: 17px !important;
  border-left: none !important;
}
.dashboard-tabs{margin-top: 1.5%;}
.dashboard-tabs .ant-tabs-left-content{
  padding-left: 0px !important;
}
.ant-table-filter-dropdown .ant-dropdown-menu{
  height: 200px;
}
/*Search bar css start*/
.ant-input-search-icon::before{
  border-left:none;
}
.ant-input-affix-wrapper{
  border: none;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px !important;
  height: 28px;
}
.ant-input-affix-wrapper .ant-input{
  background:transparent;
  box-shadow: unset;
  border-radius: unset;
  height: unset;
}
/*Search bar css end*/

.pull-right{
  float: right;
}
.content-right{
  text-align: right;
}
.logo {
  width: 120px;
  height: 31px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px 28px 16px 0;
  float: left;
}

.logo {
  background: rgba(255, 255, 255, 0.3);
}
.enq-logo-placement{
  position: absolute;
  top: 20px;
  left: 20px;
}
/* In app header start */
.ant-menu-submenu-horizontal{margin-right: 10px;}
.main-header{
  box-shadow: 0px 8px 5px 2px rgba(0, 0, 0, 0.05);
}
.ant-menu-vertical.ant-menu-sub{
  min-width:136px;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled){
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #FFFFFF;
  background-color:var(--problue) !important;
}
.ant-menu-submenu-horizontal .ant-menu-submenu-selected{
  background-color: var(--problue) !important;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px !important;
  color: #FFFFFF !important;
  border-bottom: none !important;
}
.ant-menu-submenu-selected .ant-menu-submenu-title{
  color: #FFFFFF;
}
.ant-menu-vertical > .ant-menu-item{
  height: 27px !important;
  line-height: 27px !important;
}
.ant-menu-submenu-title{
  height: 24px;
  line-height: 23px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px !important;
  color: #5F5A60 !important;
}
.ant-menu-submenu-title:active, .ant-menu-submenu-title:hover{
  background-color: var(--problue) !important;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px !important;
  color: #FFFFFF !important;
  border-bottom: none !important;
}
.ant-menu-horizontal > .ant-menu-submenu, .ant-menu-horizontal > .ant-menu-submenu:hover{
  vertical-align: middle !important;
  border-bottom: none !important;
}
.ant-menu-submenu-popup > .ant-menu{
  background: #F3F5F6 !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px !important;
  text-align: center !important;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: #5F5A60;
  padding-top: 10px;
  padding-bottom: 10px;
}
.ant-menu:not(.ant-menu-horizontal, .ant-menu-inline) .ant-menu-item-selected{
  background: var(--problue) !important;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  color: #FFFFFF !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
}
.ant-layout-header {
  height: auto;
  padding: 10px 24px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 50px;
  background: white;
  text-align: right;
}
.ant-layout-header .ant-badge-count {
  background: var(--probluedark);
}
.custom-badge .ant-badge-count {
  background: var(--probluedark);
}
.ant-layout{
  background:#FFFFFF !important;
}
.ant-menu-dark .ant-menu-item > a {
  color: rgba(255, 255, 255, 0.65) !important;
}
.forced-white{
  color: rgba(255, 255, 255, 0.65) !important;
}
.top-nav-btn{
  height: 24px;
  line-height: 23px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px !important;
  color: #5F5A60;
  vertical-align: middle !important;
}
.top-nav-btn:active, .top-nav-btn:hover{
  background-color: var(--problue) !important;
  box-shadow: inset 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 3px;
  font-style: normal;
  font-weight: 600;
  color: #FFFFFF !important;
  border-bottom: none !important;
}
  /*layout styles*/
  .layout-style{
    padding: 0px 24px 0;
  }
  .main-content-area{
    background: #fff;
    width: 100%;
    /* height: 400px; */
    overflow-y: scroll;
  }
  .main-content-area::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 7px;
  }
  
  .main-content-area::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: var(--scrollbarColor);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }

  .custom-scroll, 
  .comparison-table .ant-table-content {
    height: 100vh;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: var(--scrollbarColor) #C2D2E4;
  } 
  .ant-select-dropdown > div > div:not([role="listbox"]) {
    scrollbar-width: thin;
    scrollbar-color: var(--scrollbarColor) #C2D2E4;
  }
  .custom-scroll::-webkit-scrollbar, 
  .comparison-table .ant-table-content::-webkit-scrollbar, 
  .ant-select-dropdown > div > div::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 7px;
    background: transparent;
    border-radius: 5px;
  }
  
  .custom-scroll::-webkit-scrollbar-thumb, 
  .comparison-table .ant-table-content::-webkit-scrollbar-thumb, 
  .ant-select-dropdown > div > div::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: var(--scrollbarColor);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }
  .history-table.custom-scroll, .comparison-table .ant-table-content {height: auto;}
  .comparison-table th.ant-table-cell:not(:first-child) {
    min-width: 100px;
  }
  .version-modal .ant-modal-footer {
    padding-right: 3.5%;
  }
  .fixed-height-pannel-scroll .ant-tabs-tabpane{
    min-height: calc(97vh - 120px);
/*     overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: var(--scrollbarColor) #C2D2E4; */
  }
  .dc-tab-overflow-y .ant-tabs-left-content{
    overflow: unset !important;
    margin-left: 87px;
  }
  .dc-tab-overflow-y .ant-tabs-tabpane-inactive{display: none;}
  .history-table .ant-table-thead > tr > th:not(:first-child),
  .history-table .ant-table-tbody > tr > td:not(:first-child) {
    min-width: 100px;
  }
  .history-panel .ant-collapse-content-box label {
    margin: 10px 0;
    display: block;
  }
  .ant-collapse-item.history-panel:nth-child(even) .ant-collapse-header {
    background: #e7ecee;
  }
  .history-table .ant-table-tbody {
    border: 1px solid #dddddd;
  }
  .history-table .ant-table-tbody > tr > td {text-align: center;}
  .fixed-height-pannel-scroll >.ant-tabs-content > .ant-tabs-tabpane::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 7px;
    background: transparent;
    border-radius: 5px;
  }
  .fixed-height-pannel-scroll >.ant-tabs-content > .ant-tabs-tabpane::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: var(--scrollbarColor);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }

  .unset-fixed-tab-height .ant-tabs-tabpane{
    min-height: auto;
    overflow: unset;
  }
  .unset-tab-progress .ant-tabs-tab{
    border-right: unset !important;
  }

  .ant-form-item-mb-10 .ant-form-item{
    margin-bottom: 10px;
  }
  .ant-form-item-mb-0 .ant-form-item{
    margin-bottom: 0px;
  }
  .ant-form-item-mb-31 .ant-form-item{
    margin-bottom: 31px;
  }
  .ant-form-item-mb-38 .ant-form-item{
    margin-bottom: 38px;
  }
/* .select-placeholder-center .ant-select-selector .ant-select-selection-placeholder{
  text-align: center;
  padding-right: 4px;
} */
  .page-title{
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #5F5A60;
  }
  .notify-scroll-height{
    height: 515px;
  }
    /* Custom scroll start */
    .dd-custom-scroller{
      overflow-y: scroll;
      overflow-x: hidden;
      scrollbar-width: thin;
    }
    .dd-custom-scroller::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
      height: 7px;
    }
    
    .dd-custom-scroller::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: var(--scrollbarColor);
      box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }
    .noti-blue{
      background: rgba(240, 246, 249, 0.6);
    }
    /* Custom scroll end */
/* Native table scroll */
.native-table-scroller{
  overflow-y: auto;
  overflow-x: auto;
  scrollbar-width: thin;
}
.native-table-scroller::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 7px;
}

.native-table-scroller::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #609FDB;
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

/*Input field styling*/
select{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;       /* Remove default arrow */
  background: url(/static/media/selectCaret.2089c9b1.svg) no-repeat 97% 50%;
  background-color: #ffffff;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  height: 28px;
  width: -webkit-fill-available;
  border: none;
  text-overflow: ellipsis;
  padding: 4px 11px;
}
select:focus {
  outline: -webkit-focus-ring-color auto 0px;
}
.ant-input{
  background: #FFFFFF;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px !important;
  height: 28px;
}
.ant-input-number{
  background: #FFFFFF;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px !important;
  height: 28px;
}
/* .input-crazy-style .ant-input-number{width: 100%;} */
.input-crazy-style .ant-picker{width: 60%;}
.input-crazy-style .ant-form-item-label > label{float: right;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 16px;
  
  color: #5F5A60;}
  .pro-top-search .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
    height: unset;
  }
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
  height: 28px;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 28px;
}
.ant-select-arrow{
  top: 45% !important;
}
.ant-input-affix-wrapper{
  max-height: 40px !important;
}
.ant-checkbox-inner{
  width: 12px !important;
  height: 12px !important;
}
.dc-custom-checkbox label{
  font-size: 12px;
}
.dc-custom-checkbox .ant-checkbox-checked .ant-checkbox-inner{
  background-color: #81AED7;
  border-color: #81AED7;
}
.dc-custom-checkbox .ant-checkbox-disabled .ant-checkbox-inner{
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.dc-custom-checkbox .ant-checkbox-inner::after{
  width: 3.714286px;
  height: 7.142857px;
}
.ant-checkbox:hover::after{
  border: none;
}
.ant-checkbox-checked::after{
  border: none;
}
/*Camel case place holders*/
.ant-input:placeholder-shown{
  text-transform: capitalize;
  font-weight: 300;
}
.ant-input-number-input:placeholder-shown{
  text-transform: capitalize;
  font-weight: 300;
}
.OSEPQF-placeholder .ant-input-number-input:placeholder-shown{
text-transform: unset;
}
.ant-form-item-has-error .ant-form-item-explain, .ant-form-item-has-error .ant-form-item-split{
  font-size: 10.5px;
}
.menu-drawer-style .ant-drawer-header{
  background: #ffffff;
  border-bottom: 1px solid #ffffff;
}
/* .menu-drawer-style .ant-drawer-title{
  color: #fff;
} */
.menu-drawer-style .ant-drawer-body{
  padding: 0px;
  background: #ffffff;
}
.menu-drawer-style .ant-drawer-close{
  color: #fff;
}
.menu-logo{
  height: 6%;
  padding-left: 23px;
  position: absolute;
  top: 6px;
}
/*For all modal start*/
.ant-modal-title{
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: var(--probluedarker) !important;
}
.ant-modal-header,.ant-modal-footer {
  border-bottom: unset !important;
  border-top:unset !important;
}
/*For all modal end*/

.header-menu-btn{
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  color: #5F5A60;
  background: #F1F3F5;
  border-color:#F1F3F5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
}
.header-menu-btn:hover{
  color: #3370A8;
  background: #ffffff;
  border-color:#3370A8;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
}

.header-menu-btn:active{
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  color: #ffffff;
  background: var(--probluedarker);
  border-color:var(--probluedarker);
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
}
.header-menu-btn:focus, .ant-menu-item-selected .header-menu-btn{
  color: #ffffff;
  background: var(--probluedarker);
  border-color:var(--probluedarker);
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
/* li.ant-menu-item.ant-menu-item-selected:focus, li.ant-menu-item.ant-menu-item-selected {
  margin-bottom: -1px;
} */
/* .dash-horizontal-menu{
  float: right;
} */
.ant-menu-horizontal{
  border-bottom: none !important;
  background: transparent;
}
.ant-menu-horizontal > .ant-menu-item:hover,.ant-menu-horizontal > .ant-menu-item-selected{
  color:var(--probluedarker) !important;
  border-bottom: 2px solid #ffffff !important;
}
.header-menu-btn-conatiner{
  background: #CFD8DC;
  border-radius: 5px;
}
.mx-10{
  margin: 0px 10px 0px 10px;
}
.ant-form-item-label > label{
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: #5F5A60;
  white-space: normal;
}
.PQF-OSNE-form-label-style .ant-form-item-label > label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #5F5A60;
  white-space: normal;
}
.enq-label{
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: #5F5A60;
  white-space: normal;
}
.operators-label .ant-form-item-label > label{
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #5F5A60;
  white-space: normal;
}
.medium-text-header{
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #5F5A60;
}
.medium-sub-text{
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #5F5A60;
}
.medium-sub-text-tab{
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 25px;
  color: #5F5A60;
}
.inquiry-header{
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  text-align: center;
  color: #777078;
}
.inquiry-subtext{
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: #857E7E;
  margin-bottom:0px !important;
}
.info-graphic{
/*   width: 20%;
  display: inline-block;
  line-height: 53px; */
  padding: 10px;
  flex: 50% 1;
}
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
/* table properties start */
.responsive-table .ant-table-content{
  overflow: auto;
}
.ant-table-tbody > tr.ant-table-row > td{
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  background: none;
}
.ant-table-tbody > tr.ant-table-row > td:first-child{
  border-left: 2px solid transparent;
}
.ant-table-tbody > tr.ant-table-row > td:last-child{
  border-right: 2px solid transparent !important;
}
.ant-table-tbody > tr.ant-table-row:hover > td{
  border-top: 2px solid #9DBCD9;
  border-bottom: 2px solid #9DBCD9;
  background: none;
}
.ant-table-tbody > tr.ant-table-row:hover > td:first-child{
  border-left: 2px solid #9DBCD9;
}
.ant-table-tbody > tr.ant-table-row:hover > td:last-child{
  border-right: 2px solid #9DBCD9 !important;
}

/* border-top: 0.15em solid var(--probluedarker);
border-bottom: 0.15em solid var(--probluedarker);
background: transparent;
}
.ant-table-tbody > tr.ant-table-row:hover > td:first-child{
border-left: 0.15em solid var(--probluedarker);
}
.ant-table-tbody > tr.ant-table-row:hover > td:last-child{
border-right: 0.15em solid var(--probluedarker) !important; */
/* table properties end */
.double-scroll{
  overflow-x: scroll;
  scrollbar-width: thin;
}
.double-scroll::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 4px;
}

.double-scroll::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: var(--scrollbarColor);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
.ant-table table{
  text-align: center !important;
}
/* status buttons start */
/* .radio-group-width{
  display: block !important;
} */
/* .ant-radio-button-wrapper:last-child{
  background: #DEE3E6;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: var(--probluedarker);
  text-align: center;
}
.ant-radio-button-wrapper:first-child{
  background: #DEE3E6;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: var(--probluedarker);
  text-align: center;
  margin-right: 10px;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  background: var(--probluedarker);
  border-color:var(--probluedarker);
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover{
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  background: var(--probluedarker);
  border-color:var(--probluedarker);
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within{
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  background: var(--probluedarker);
  border-color:var(--probluedarker);
} */

.ant-radio-button-wrapper{
  min-width: 110px;
  max-height: 24px;
  text-align: center;
  line-height: unset !important;
  background: #F1F0F0;
  /* box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25); */
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child{
  border-radius: 3px 0 0 3px;
  background: var(--problue);
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #FFFFFF;
}
.ant-radio-button-wrapper:last-child{
  border-radius: 0 3px 3px 0 !important;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
  background: var(--problue);
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #FFFFFF;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within{
  color: #FFFFFF;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active{
  color: #FFFFFF;
  border-color: var(--problue);
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover{
  color: #FFFFFF;
  border-color: var(--problue);
}
/* status buttons end */

/* status buttons MD start */
.tab-pannel-alignment .radio-group-width{
  display: block !important;
}
.tab-pannel-alignment .ant-radio-button-wrapper:last-child{
  background: #DEE3E6;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  /* color: var(--probluedarker); */
  color: #5F5A60;
  text-align: center;
  width: 47%;
}
.tab-pannel-alignment .ant-radio-button-wrapper:last-child:active{
  background: #DEE3E6;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  /* color: #5585b3; */
  color: #5F5A60;
  text-align: center;
  width: 47%;
}
.tab-pannel-alignment .ant-radio-button-wrapper:first-child{
  background: #DEE3E6;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  /* color: var(--probluedarker); */
  color: #5F5A60;
  text-align: center;
  width: 47%;
  margin-right: 10px;
}
.tab-pannel-alignment .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  background: var(--probluedarker);
  border-color:var(--probluedarker);
}
.tab-pannel-alignment .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover{
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  background: var(--probluedarker);
  border-color:var(--probluedarker);
}
.tab-pannel-alignment .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within{
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  background: var(--probluedarker);
  border-color:var(--probluedarker);
}
/* status buttons MD end */

.master-form-style .ant-btn[disabled]{
  width: 100px;
  background: #DEE3E6;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #5F5A60;
}
.master-form-style .ant-btn[disabled]:hover{
  width: 100px;
  background: #dee1e2;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.5);
}

/*Collapse start*/
.ant-collapse:nth-child(even){
  background: #E7ECEE;
  border-color: #E7ECEE;
  border-bottom: 0px solid #E7ECEE;
}

/* Vendor template collapse */
.assigned-questions .ant-collapse-item .ant-collapse-header{
  color: var(--probluedarker);
}
.quality-criteria-collapse .ant-collapse-content-box{
  color: var(--probluedarker);
}
/*Collapse end*/
/*Blue shadow btn start*/
.blue-shadow-btn{
  background: #3471A9;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border-color: var(--probluedarker) !important;
  color: white;
}
.blue-shadow-btn:focus{
  background-color: #578bbb !important;
  color: white;
}
.blue-shadow-btn:hover{
  background-color: #578bbb !important;
  color: white;
}
/*Blue shadow btn end*/
/* .ant-btn:hover, .ant-btn:focus{
  color: #3370A8;
  background: #ffffff;
  border-color: #3370A8;
} */
/*Typography start*/
.blue-text{
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: var(--probluedarker);
}
.OrderedListStyle{
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #FFFFFF;
}
.OrderedListStyle li{
  padding: 10px 0px 10px 0px;
}
.add-photo-label{
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #5F5A60;
}
.ant-form-item-label > label.ant-form-item-required::before {
  display: none;
}
.ant-form-item-label > label.ant-form-item-required::after {
  display: inline-block;
  margin-left: 4px;
  color: rgb(136, 136, 136);
  font-size: 10px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}
.colon-none .ant-form-item-label > label:not(label.ant-form-item-required)::after{
  content: ' ';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}
.notice-box-sm-text{
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #FFFFFF;
}

/*Typography end*/
.ant-radio-wrapper{
  white-space: normal;
}
.ant-radio-inner::after{
  background-color: #86B5E0 !important;
}
.ant-radio-inner{
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

/*default success btn start*/
.btn-success{
  border: #63AA4A;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5) !important;
}
.btn-success:focus{
  border: #63AA4A;
  background: #63AA4A;
  color: white;
}

/*default success btn end*/
/*Notification start*/
.active-notification{
  color: var(--probluedark);
  font-size: 18px;
}
.inactive-notification{
  color: #9D9E9E;
  font-size: 18px;
}
.notify-item .ant-divider{
  border: 1px solid #C4C4C4;
}
.notify-item .ant-divider-horizontal{
  margin: 15px 0;
}
.ant-popover-title{
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  padding: 15px 16px 10px !important;
  color: var(--probluedarker);;
}
.ant-popover-placement-bottomRight .ant-popover-inner{
  background: #F0F6F8 !important;
  box-shadow: -8px -8px 8px rgba(0, 0, 0, 0.25), 8px 8px 8px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.notify-header-style{
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}
.notify-header-style .ant-form-item-label > label{
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}
.notify-date{
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: var(--probluedarker);
  text-align: end;
}

.noti-proj-name {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}
.noti-description{
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}
.noti-timestamp {
  width: 95px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: var(--probluedarker);
}
.noti-middle-content{
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
}
.noti-view-btn {
  width: 151px;
  height: 20px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 10px;
  color: #FFFFFF;
  background: #81AED7;
  border-radius: 6px;
}
.noti-view-btn:hover {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 10px;
  color: #FFFFFF;
  background: #8cb8e0;
}
.noti-container{width: 935px;}
.devicer {
  width: 5em;
  display: block;
  height: 3px;
  background-color: #5F5A60;
  margin: 0px auto 17px;
}
/*Notification end*/
/*background colors start*/
.skyblue-bg{
  background: #E7ECEE;
}
.skyblue-bg2:hover{
  background: #F0F6F8;
}
.dc-gray-bg{
  background: #F0EFEF;
}
.pale-orange-bg{background-color: var(--paleorange);}
.pale-green-bg{background-color: var(--palegreen);}
.pale-blue-bg{background-color: var(--paleblue);}
.pale-red-bg{background-color: var(--palered);}
.pale-purple-bg{background-color: var(--palepurple);}
/*background colors end*/
/*image responsive start*/
.img-responsive{
  width: 100%;
  height: auto;
}
/*image responsive end*/
/*Modal bg start*/
.modal-bg .ant-modal-content{
  background: #FBFBFB;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}
.modal-bg .ant-modal-header{
  background: transparent !important;
}

.card-5{
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px;
}
.card-6{
  background: #E7ECEE;
  border-radius: 5px;
}
.card-7{
  background: #F0FBFF;
  mix-blend-mode: normal;
  box-shadow: 8px 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.card-8{
  background: #FFFFFF;
  mix-blend-mode: normal;
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.25), 0px -5px 12px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.card-9{
  background: #E0E4E3;
  mix-blend-mode: normal;
  border-radius: 5px;
}
.card-10{
  background: rgb(207, 216, 220, 0.5);
  border-radius: 3px;
}
.card-p-10 .ant-card-body{
  padding: 10px !important;
}
.card-p-16 .ant-card-body{
  padding: 16px;
}
.card-p-20 .ant-card-body{
  padding: 20px;
}
.card-p-15-pb-0 .ant-card-body{
  padding: 15px 15px 0px 15px;
}
.card-py-5-px-15 .ant-card-body{
  padding: 5px 15px;
}
.card-pt-5 .ant-card-body{
  padding-top: 5px;
}
/*Modal bg end*/
/*Default link btn start*/
.ant-btn-link{
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: var(--probluedarker);
}
/*Default link btn start*/
/*Default btn gray block start*/
.gray-block{
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #5F5A60;
  background: #F1F0F0;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5); */
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
  border: transparent;
  border-radius: 3px;
}
.gray-block:hover{
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #5F5A60;
  background: #f3f3f3;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5); */
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
  border: transparent;
  border-radius: 3px;
}
/*Default btn gray block end*/
/*progress bar start*/
/* .step-progress .ant-progress-inner{
  border-radius: 3px !important;
  background-color: #f3f7fb !important;
  border: 1px solid rgba(232, 231, 231, 0.1) !important;
}
.step-progress .ant-progress-success-bg, .ant-progress-bg {
  background: #81AED7;
  box-shadow: inset 0px -4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  height: 16px !important;
}
.step-progress .ant-progress-text{
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #ACACAC;
  position: absolute;
  right: 15px;
  top: 4px;
}
.pqf-progress .ant-progress-text{right: -30px !important;}
.step-progress .ant-progress-status-success .ant-progress-bg{
  background: #81AED7 !important;
}
.step-progress .ant-progress-status-success .ant-progress-text{
  color: #FFFFFF !important;
}
.step-progress .ant-progress-show-info .ant-progress-outer{
  padding-right: unset;
} */

.mustard-progress .ant-progress-bg{
  border-radius: 0 100px 100px 0;
}
.mustard-progress .ant-progress-bg::after {
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 6px solid #DBC715;
  float: right;
  content: "";
  position: relative;
  left: 2px;
}
.mustard-progress .ant-progress-inner{
  border: solid .5px #DBC715;
  border-radius: 0;
}
.mustard-progress .ant-progress-status-success .ant-progress-text {
  color: #DBC715;
}
/*progress bar end*/
button.ant-btn.grey-btn {
  background: #F1F0F0;
  border-radius: 0;
  border: none;
  min-width: 80px;
  color: rgba(0, 0, 0, 0.65);
  height: 30px;
  --antd-wave-shadow-color: transparent;
} 
button.ant-btn.grey-btn.width-50 {
  width: 50%;
}
.grey-btn-outer {
box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
position: relative;
margin-bottom: 28px;
display: flex;
border-radius: 3px;
}
.grey-btn-outer button.ant-btn.grey-btn:first-child {
  border-radius: 3px 0 0 3px;
  /* padding-right: 0px; */
}
.grey-btn-outer button.ant-btn.grey-btn:last-child {
  border-radius: 0 3px 3px 0;
  /* padding-left: 0px; */
}
.grey-btn-outer button.ant-btn.grey-btn:not(:first-child)::after {
  content: "";
  width: 1px;
  height: 20px;
  display: block;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  background: rgb(0 0 0 / 0.1);
  left: 0;
}
button.ant-btn.grey-btn a {
  color: rgba(0, 0, 0, 0.65) !important;
}
.ant-table-container table th.ant-table-cell.ant-table-cell-fix-right, 
.ant-table-container table td.ant-table-cell.ant-table-cell-fix-right {
  border-left: 1px solid rgb(0 0 0 / 0.1);
}
.ant-input-search.search-field.ant-input-affix-wrapper .ant-input-search-icon::before {display: none;}
.group-menu {
  padding: 0 20px;
}
li.ant-menu-item.gap-left {
  padding-left: 48px;
  padding-right: 0;
}
li.ant-menu-item.gap-right {
  padding-left: 0;
  padding-right: 48px;
}
.ant-col.ant-col-24.Small{
  width:33%;
  display: inline-block;
}
.ant-col.ant-col-24.Medium{
  width:50%;
  display: inline-block;
}
.ant-col.ant-col-24.Large{
  width:100%;
}
.ant-table-pagination.ant-pagination li.ant-pagination-item {
  display: none;
}
.ant-table-pagination.ant-pagination li a {
  background: transparent;
  border: none;
}
.ant-table-pagination.ant-pagination li.ant-pagination-next, 
.ant-table-pagination.ant-pagination li.ant-pagination-prev {
  min-width: 20px;
}
.ant-table-pagination.ant-pagination li.ant-pagination-prev .anticon, 
.ant-table-pagination.ant-pagination li.ant-pagination-next .anticon {
  color: #5F5A60 !important;
}
.pagination-display {
  position: absolute;
  right: 70px;
  bottom: 6px;
  word-spacing: 5px;
  color: #5F5A60;
}
.pagination-display-inside-modal{
  position: absolute;
  bottom: 22px;
  right: 85px;
  word-spacing: 5px;
  color: #5F5A60;
}
.pagination-display-inside-modal-2{
  position: absolute;
  bottom: 40px;
  right: 85px;
  word-spacing: 5px;
  color: #5F5A60;
}
.pagination-display-user-listing{
  left: 81.5%;
  position: relative;
  bottom: 27px;
  word-spacing: 5px;
  color: #5F5A60;
  width: 122px;
}
.pagination-display-input-matrix {
  position: absolute;
  right: 67px;
  bottom: 5px;
  word-spacing: 5px;
  color: #5F5A60;
}
li.ant-pagination-options {
  display: none;
}
/* .ant-popover-buttons {
  text-align: center;
} */
.ant-modal-body {
  background: linear-gradient(90deg,transparent 7.5%, rgba(230, 241, 243, 0.5) 7.5%, rgba(230, 241, 243, 0.5) 92.5%, transparent 92.5%);
  padding-left: 14%;
  padding-right: 14%;
}
.ant-modal-body span.anticon.anticon-exclamation-circle {
  color: #faad14;
  font-size: 22px;
  margin-bottom: 10px;
}
.modal-body-resource-dev .ant-modal-body {
  background: unset !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  padding-top: 0px;
}
.modal-body-resource-dev .ant-modal-header{
  background: unset !important;
}
header.ant-layout-header .header-logo {
  display: block;
  float: left;
  margin-top: -4px;
  /* padding-left: 25px; */
}
.ant-table-pagination.ant-pagination {
  margin: 0;
}
header.ant-layout-header .ant-menu-item .anticon {
  font-size: 20px;
}
li.ant-menu-item.user-menu-item {
  padding-left: 30px;
  padding-right: 0;
}
header.ant-layout-header .ant-menu-item.user-menu-item .anticon {
  font-size: 18px;
}
.top-arrow-btn {line-height: 38px;}
.top-arrow-btn .ant-space-item {
  line-height: 0px;
}
.top-arrow-btn .anticon:hover svg line {
  stroke: var(--probluedarker);
}
.ant-form-item-label > label {
  min-height: 32px;
  height: auto;
}
/*Enquiry modal start*/
.jumbo-btn{
  /* width: 170px; */
  height: 65px;
  background: #F1F0F0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #5F5A60;
}
.jumbo-btn:hover, .jumbo-btn:active, .jumbo-btn:focus{
  background: #e2e2e2;
  color: #5F5A60;
  border-color:#e2e2e2;
}
.jumbo-text-1 span{
  white-space: break-spaces;
  width: 107px;
}
.jumbo-text-2 span{
  white-space: break-spaces;
  width: 64px;
}
.jumbo-text-3 span{
  white-space: break-spaces;
  width: 91px;
}
.enq-page{
  display: flex;
  height: calc( 100vh - 30px);
}
.enq-sidebar{
  width: 250px;
}
.enq-main-content{
  flex: 1 0 auto;
}
.enq-modal .ant-modal-body{
  background: none !important;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 0;
}
.enq-modal .ant-modal-content, .ant-modal-header{
  border-radius: 20px;
  background: #FBFBFB;
}
.enq-modal2 .flexible-modal-mask{
  z-index: 99 !important;
}
.enq-modal3{
  z-index: 100 !important;
}
.send-mail-btn{
  width: 150px;
  border-radius: 3px;
}
.send-enq{
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  width: 215.5px;
  height: 127px;
}
.send-enq span{
  text-decoration: underline;
}
.enq-sidemenu-text{
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #777078;
  width: 214px;
  height: 109px;
}
.enq-left{
  position: absolute;
  left: 0;
  top: 43%;
}
.flex-container{
  display: flex;
  flex-direction: column;
}
.hide-empty-rows .ant-table-body .anticon{
  color: #9D9E9E;
  stroke: #9D9E9E;
}
/*Enquiry modal end*/
/* Chart start */
.responsive-pie{
  width: 350px;
  height: 87px;
}
/* Chart end */

select::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 7px;
 }
select::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: var(--scrollbarColor);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.spin-middle{
  position: absolute;
  top: 50%;
  left: 50%;
}

.pro-top-search{
  width: 389px;
  background: #F1F0F0;
  border-radius: 3px;
  text-align: center;
}
.send-email-box{
  background: #F1F0F0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
}
.proj-search-btn{
  background: transparent;
  border: none;
}
.proj-search-btn:active,
.proj-search-btn:focus,
.proj-search-btn.active {
  background: var(--problue);
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  color: white;
}

/* Add on start */
.addon .ant-input-group-addon{
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  background: #F0EFEF;
  box-shadow: inset 0px 4px 4px rgb(0 0 0 / 25%);
}
.addon .ant-input{
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
/* Add on end */

.dc-exp-table-pb-4 .wj-row .wj-detail{
  padding-bottom: 4px;
  /* padding: 0; */
}
.red-btn{
  background: #fbb1b1 !important;
  color: white;
  border-color: #fb7070;
}
.red-btn:hover,.red-btn:active,.red-btn:focus{
  color:red;
  border-color: red;
}
.green-btn{
  background: #8df389 !important;
  color: white;
  border-color: #2fc536;
}
.green-btn:hover,.green-btn:active,.green-btn:focus{
  color:green;
  border-color: green;
}
/* Target Internet Explorer 9 to undo the custom arrow */
.dc-min-tabgrid-height{
  max-height: 70vh;
}
.dc-min-tabgrid-height-2{
  min-height: 80vh;
}
.dc-phone-input .react-tel-input .form-control{
  height: 28px !important;
  width: 100%;
  box-shadow: 4px 4px 10px rgb(0 0 0 / 5%), inset 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 3px !important;
}
.dc-phone-input .react-tel-input .form-control::-webkit-input-placeholder{
  color: #c4c4c4;
}
.dc-phone-input .react-tel-input .form-control:-ms-input-placeholder{
  color: #c4c4c4;
}
.dc-phone-input .react-tel-input .form-control::-ms-input-placeholder{
  color: #c4c4c4;
}
.dc-phone-input .react-tel-input .form-control::placeholder{
  color: #c4c4c4;
}
.dc-phone-input .react-tel-input .invalid-number-message{left: 0; top: 30px;}
.text-right{text-align: right;}
.text-left{text-align: left !important;}
.text-end{text-align: end;}
.text-center{text-align: center;}
.text-start{text-align: start;}
.dc-absolute-bottom{position: absolute;bottom: 0;width: 100%;}
.comment-textbox{
  width: 274px;
  resize: none;
}
.comment-date-time{
  font-style: normal;
  font-weight: 300;
  font-size: 9px;
  line-height: 11px;
  color: #857E7E;
}
.blue-text{color: #8BADCD;}
.cursor-pointer{cursor: pointer;}
.allowance-textbox{resize: none;}
.dc-explain-msg .ant-form-item-explain{text-align: start;}
.font-18{font-size: 18px !important;}
.font-15{font-size: 15px;}
.font-24{font-size: 24px;}
.dc-trad-input{
  background: #FFFFFF;
  box-shadow: 4px 4px 10px rgb(0 0 0 / 5%), inset 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 3px !important;
  height: 28px;
  border: none;
  text-align: center;
}
.width-60px{width: 60px;}
.width-100{width: 100%;}
.width-200{width: 200px !important;}
.width-90px{width: 90px;}
.width-180{width:180px;}
.width-100px{width: 100px;}
.width-auto{width: auto;}
.width-155{width: 155px;}
.width-255{width: 255px;}
.height-28 .ant-select-selector{height: 28px;}
.exclamation-comment-btn .exclamation-comment{
  stroke: #857E7E;
  fill: #857E7E;
}
.exclamation-comment-btn:focus .exclamation-comment, .exclamation-comment-btn:hover .exclamation-comment{
  stroke: #ffffff;
  fill: #ffffff;
  border: none;
}
.exclamation-comment-btn:focus, .exclamation-comment-btn:hover{
  background: var(--problue);
  border-color: var(--problue);
}
.tab-border{border: 1px solid #C4C4C4; border-radius: 3px;}
.tab-mb-0 .ant-tabs-bar{margin-bottom: 0px !important;}
.ant-form label{color: rgb(136, 136, 136) !important;}
.dark-gray-color{color: rgb(136, 136, 136);}
.float-right{float: right;}
.float-left{float: left;}
.z-index-20{z-index: 20;}
.noti-popover-width{width: 510px;}
.select-proj-width{width: 269px;}
.dash-layout{
  margin: 0;
  padding: 24px 24px 0px;
  min-height: 248px;
}
.border-radius-in-per{border-radius: 3%;}
.overflow-hidden{overflow: hidden;}
.overflow-unset{overflow: unset !important;}
.relative-wrap{position: relative;}
.overflow-hidden-tabs{overflow: hidden !important; position: static;}
.overflow-hidden-tabs .ant-tabs-content{position: absolute;}
.border-bottom-gray{border-bottom: 2px solid #C4C4C4;}
.z-index-3{z-index: 3;}
.overflow-x-hidden{overflow-x: hidden;}
.agreement-doc{width: 100%; height: 100vh;}
.allowance-upload-btn .ant-upload-list{
  position: absolute;
  right: 200px;
  top: 0;
}
.icon-btn{background: #F1F0F0;border-radius: 3px; box-shadow: 0px 1px 3px rgb(0 0 0 / 25%); width: 28px; height: 28px;}
.gray-border{border: 0.1px solid #c1c1c1;}
.table-container-height{min-height: auto}
.table-container-height2{min-height: 70vh;}
.dark-label{color: #5F5A60; font-size: 14px;font-weight: 600;}
.ant-switch-inner{
  display: block;
  margin: 0 14px 0 25px;
  color: #fff;
  font-size: 12px;
  transition: margin .2s;
}
.ant-badge-count-sm .ant-badge-count{
  min-width: 14px;
  height: 14px;
  padding: 0;
  font-size: 12px;
  line-height: 14px;
  border-radius: 7px;
}
.pro-top-search-2 .ant-select-selector {
  height: 32px !important;
}
.flexible-modal .country-list{position: fixed !important;}
.site-layout .site-layout-background {
  background: #fff;
}
.sider-fixed{
    overflow: auto;
    height:100vh;
    position: fixed;
    left: 0;
}
.promenu .single-menu:first-child{margin-top: 0 !important;}
.promenu .ant-menu-item{
  padding-left: unset !important;
  padding-right: unset !important;
  font-size: 15px;
  color: #5f5a60;
  font-weight: 500;
  width: 70px;
  height: 30px !important;
  /* background: #f0efef;
  box-shadow: 4px 4px 10px rgb(0 0 0 / 5%), inset 0 4px 4px rgb(0 0 0 / 25%); */
  border-radius: 3px;
  margin: 7px 12px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.promenu .ant-menu-submenu-title{
  font-style: normal;
  font-weight: 600;
  font-size: 18px !important;
  line-height: 22px !important;
  color: #5f5a60 !important;
  height: 22px !important;
  padding: unset !important;
  text-align: center;
  cursor: not-allowed;
}
.promenu .ant-menu-item-selected{
  background-color: var(--problue) !important;
  box-shadow: 4px 4px 10px rgb(0 0 0 / 5%), inset 0 4px 4px rgb(0 0 0 / 25%);
  border-radius: 3px;
  color: #fff;
}
.promenu .ant-menu-item::after{border-right: unset;}
.promenu .ant-menu-submenu-arrow{display: none;}
.promenu .ant-menu-submenu-selected{background-color:var(--probluelight) !important;}
.promenu .ant-menu-submenu-open{margin-bottom: 11px;background-color: #F8F7F7;border-radius: 3px;padding: 11px 0;}
.promenu .ant-menu-submenu > .ant-menu{background-color: transparent;}
.promenu{border-right: unset;}
.promenu .single-menu{
  width: 95px;
  height: 54px !important;
  white-space: break-spaces;
  line-height: 1.5;
  margin: 11px 0 !important;
  background-color: #F8F7F7;
}
.promenu .ant-menu-submenu-title{pointer-events: none;}
.pro-menu-btn{background-color: #3c7ab4;border-color:#3c7ab4; color: #ffffff;border-radius: 3px;}
.pro-menu-btn:focus{background-color: var(--problue);border-color:var(--problue); color: #ffffff;box-shadow: inset 0px 4px 4px rgb(0 0 0 / 25%);}
.pro-menu-btn:active{background-color: #3c7ab4;border-color:#3c7ab4;box-shadow: inset 0px 4px 4px rgb(0 0 0 / 25%);}
.sider-ml-15 .ant-layout-sider:not(.ant-layout-sider-collapsed){margin-left: 15px; margin-right: 15px;}
.x-sm-btn{height: 20px;font-size: 12px;line-height: 20px;}
.vendor-req-pqq .ant-tabs-content{position:relative;}
@media screen and (min-width:0\0) {
  select {
      background-image:none\9;
      padding: 5px\9;
  }
}

@media (min-width: 320px) {
  .master-form-style .ant-btn[disabled]{
    width: 70px;
  }
  .main-content-area{
    height: unset;
    overflow-y: unset; 
  }
  .hide-pc-menu{
    display: none;
  }
  .hide-mobile-menu-btn{
    display: block;
    position: absolute;
    top: 14px;
    left: 17px;
  }
  .ant-tabs-nav-scroll{
    overflow: scroll;
  }
  .ant-tabs-nav-scroll::-webkit-scrollbar{
    display: none;
  }
/*   .ant-table-cell-fix-left, .ant-table-cell-fix-right{
    background: #fff;;
  } */
  .responsive-display{
    display: inline-block;
  }
  .responsive-display-2{
    display: none;
  }
}

@media (min-width: 732px) {
  .master-form-style .ant-btn[disabled]{
    width: 100px;
  }
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 882px) {
  .hide-pc-menu{
    display: block;
  }
  .hide-mobile-menu-btn{
    display: none;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
  .flex-container {
    flex-direction: row;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
  .ant-tabs-nav-scroll{
    overflow: hidden;
  }
  .responsive-display{
    display: none;
  }
  .responsive-display-2{
    display: inline-block;
  }
}
.ant-tabs {overflow: visible;}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab:last-child {
  margin-right: 0 !important;
}
.ant-select .ant-select-arrow .anticon {
  background: url(/static/media/selectCaret.2089c9b1.svg) center bottom no-repeat;
  color: transparent;
}
.ant-table-filter-dropdown .ant-table-filter-dropdown-btns button.ant-btn.ant-btn-primary:not([disabled]),
.ant-picker-footer button.ant-btn.ant-btn-primary:not([disabled]) {
  background: var(--probluedarker);
  border-color: var(--probluedarker);
  line-height: normal;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  background: var(--probluedarker);
  border-color: var(--probluedarker);
}
header.ant-layout-header .header-logo img {max-width: 100%;}
@media screen and (max-width: 1440px) {
  .layout-style {
    padding: 0px 0px 20px;
  }
  main.ant-layout-content.main-content-area {
    padding: 24px 16px 0 !important;
  }
}
@media screen and (max-width: 905px) {
  .dash-horizontal-menu{
    float: unset;
  }
}
@media screen and (min-width: 1440px) {
  .responsive-pie{
    width: 370px;
    height: 200px;
  }
}
@media screen and (min-width: 1800px) {
  .dc-curve-bg{
    width: 85%;
  }
}

/* .editable-row .ant-form-explain {
    position: absolute;
    font-size: 12px;
    margin-top: -4px;
  } */
  .editable-cell {
    position: relative;
  }
  
  .editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
  }
  
  .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 4px 11px;
  }
  
  [data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #434343;
  }
  .ant-table-tbody > tr .ant-table-wrapper:only-child .ant-table{
    margin:unset !important;
    padding: 5px;
  }
  .upload-icon-style{
    font-size: 18px;
    color: skyblue;
  }
  .highlight-icon-style{
    font-size: 18px;
  }
  .project-title{
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    /* line-height: 22px; */
    color: #5F5A60;
  }
  .transparent-btn{
    border: transparent;
    padding: 0px;
    background: transparent;
  }
  .ptable-1 .editable-cell-value-wrap{
    padding: 0px !important;
  }
  .btn-success{
    background: #63AA4A;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}
.border-right{border-right: 1px solid #9D9E9E;}
.project-name {
  /* border-right: 1px solid #9D9E9E; */
  text-align: center;
  flex: inherit;
  width: 353px;
}
.project-name.verticalLayout-form .ant-col.ant-form-item-label {
  text-align: center !important;
}
.project-management.ant-tabs .select-tabpanel::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: calc(100% + 5px);
  /* background: var(--problue); */
  top: -1px;
  left: 0;
}
.reset-cascade-2 .reset-cascade .ant-tabs-bar{
  width: unset !important;
  margin-left: unset !important;
}
.reset-cascade-2 .reset-cascade .ant-tabs-nav-wrap{
  border-top: none !important;
}
.operations-data-tabs .project-management .ant-tabs-bar {
  width: 70%;
  margin-left: auto;
}
.select-tabpanel {
  position: absolute;
  top: 0;
  width: 30%;
  padding: 8px 17px 2px;
}
.project-dtls-right {
  padding: 0% 5% 4% !important;
  flex: inherit;
  width: calc(100% - 353px);
  max-width: inherit;
}
.project-management.ant-tabs .select-tabpanel .ant-select.searchSelect .ant-select-arrow {
  top: 51% !important;
  left: 328px;
  right: auto;
}
/* .project-dtls-right > .ant-row > .ant-col {
  padding: 4% !important;
} */
/* .project-dtls-right .ant-row.ant-form-item {
  margin-bottom: 20px !important;
  max-width: 341px;
  margin: auto;
} */
.project-name .ant-picker {
  background: #FFFFFF;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px !important;
  width: 230px;
  max-width: 100%;
}
.operations-data-tabs.ant-tabs .ant-tabs-tab {
  text-align: center;
}
.reset-cascade .ant-tabs-tab {
  width: unset !important;
  border-bottom: none !important;
}
.operations-data-tabs.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  padding: 4px 10px;
  width: auto;
  margin-right: 0;
  box-shadow: none;
}
/* .project-dtls-right > .ant-row > .ant-col:first-child {
  padding: 4% 2% 4% 4% !important;
}
.project-dtls-right > .ant-row > .ant-col:nth-child(2) {
  padding: 4% 4% 4% 2% !important;
} */
.project-dtls-right > .ant-row {
  padding-left: 12px;
  max-width: 1000px;
  margin-left: auto !important;
  margin-right: auto !important;
}
.project-management.ant-tabs .ant-select.searchSelect {
  width: 333px;
  min-width: 286px;
  max-width: 100%;
  text-align: left;
  position: unset;
}
/* form#ProjectItem .ant-form-item-label label::after {
  display: none;
} */
.reset-cascade .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab{
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
}
.reset-cascade .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab:first-child{
  display: block;
  border-radius: 5px 0px 0px 0px !important;
}
.reset-cascade .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab:last-child{
  /* display: block; */
  border-radius: 0px 6px 0px 0px !important;
}
.project-management .ant-tabs-bar .ant-tabs-nav-wrap .ant-tabs-tab:first-child{
  display: none;
}
.operations-data-tabs.ant-tabs .ant-tabs-nav > div {
  display: flex;
}
.project-management.ant-tabs .ant-tabs-tab:first-child > div {
  display: flex;
  justify-content: space-between;
}
.project-management.ant-tabs .ant-tabs-tab:first-child > div span.project-tab-name {
  width: 28%;
  text-align: center;
  padding: 6px 0 0 15px;
  min-width: 80px;
}
.project-management .ant-tabs-nav-wrap .ant-tabs-tab:first-child {
  padding: 1px 16px 3px;
  border-top: 0;
}
/* .ant-table-wrapper.ptable-1.specification-table th:not(:first-child):not(:nth-child(2)) {
  min-width: 100px;
} */
.project-name .ant-picker-input > input {
  text-align: center;
}
.avatar-uploader.project-logo .ant-upload.ant-upload-select-picture-card {
  float: none;
  margin: 8px auto 10px;
  border: none;
  background: transparent;
  width: 87px;
  height: 87px;
}
.project-name .ant-select, 
.project-name .ant-input-number {
  width: 230px;
  margin: 0 auto;
  max-width: 100%;
}
.project-name .ant-checkbox-wrapper {
  width: auto;
  display: inline-block;
}
.proj-clientName input.ant-input,
form#ProjectItem .ant-select-selection-item {
  color: rgba(0, 0, 0, 0.65);
}
.project-name .ant-input-number input {
  text-align: center;
}
/* .project-name .ant-form-item {
  margin-bottom: 35px;
} */
.project-logo .ant-upload button.ant-btn {
  margin-top: 50px;
  border: none;
  background: transparent;
  box-shadow: none;
  color: #3C7AB4;
  position: absolute;
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
}
.project-logo .ant-upload > .ant-upload {
    border-radius: 50%;
    background: gainsboro;
    /* background: url("./images/profile.svg") no-repeat;
    background-size: cover; */
    width: 87px;
    height: 87px;
    overflow: hidden;
    padding: 0;
}
.project-logo .ant-upload > .ant-upload img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.master-outer-card .ant-input-number {
  width: 100%;
}
.card-pl-0 .card-body{
  padding-left: 0 !important;
}
.ant-input-search.search-field.ant-input-affix-wrapper {
  border: none;
  background: #FFFFFF;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px !important;
  padding: 1px 0 1px 11px;
  width: 230px;
  float: right;
  max-width: 100%;
}
.ant-input-search.search-field.ant-input-affix-wrapper input.ant-input {
  box-shadow: none;
  background: transparent;
}
.ant-table-container table > thead > tr:first-child th:first-child,
.ant-table.ant-table-bordered thead > tr > th, 
.ant-table.ant-table-bordered tbody > tr > td, 
.ant-table.ant-table-bordered tfoot > tr > th, 
.ant-table.ant-table-bordered tfoot > tr > td {
  border-right-color: rgb(0 0 0 / 0.1) !important;
}
.taxType-table tr:not([data-row-key]).ant-table-row td:first-child > * {
  display: none;
}
.taxType-table tr:not([data-row-key]).ant-table-row td:nth-child(2) {
  font-size: 0;
}
.projDtls-save {
  float: right;
  /* margin-right: 7.25%; */
}
.grey-btn-outer.projDtls-save button.ant-btn.grey-btn:last-child {
  border-radius: 3px;
}
.project-tables .ant-select .ant-select-selector,
.project-tables .ant-input-number,
.project-tables textarea,
.project-tables .ant-picker, 
.project-tables input {
  box-shadow: none;
  border: none;
  background: transparent;
}
.project-tables textarea:focus {
  box-shadow: none;
  outline: none;
  border: none;
}
.project-tables .ant-input-number input, 
.project-tables input {
    text-align: center;
}
/* .project-tables .ant-select .ant-select-arrow {
    visibility: hidden;
} */
.project-tables textarea {
  padding: 4px 0;
  resize: none;
  text-align: center;
}
.project-tables tr.ant-table-row:hover .editable-cell-value-wrap {
  border: none;
}
.project-tables .ant-row.ant-form-item {
  margin-bottom: 0;
}
.project-tables td.ant-table-cell {
  padding: 0 8px;
}
/* .project-tables .ant-table-tbody > tr > td {
  height: auto;
} */
/* .project-tables td.ant-table-cell .editable-cell-value-wrap {
  padding: 0px !important;
} */
.project-tables td.ant-table-cell .ant-form-item-control-input {  
  min-height: 28px;
}
.clear-float {clear:both;}
/* .proj-catMarkup table, .proj-docRegister table {table-layout: auto !important;} */
.ant-table-row:nth-child(even) td.ant-table-cell.ant-table-cell-fix-right {
  background: white;
}
.ant-table-row:nth-child(odd) td.ant-table-cell.ant-table-cell-fix-right {
  background: #E7ECEE;
}
.ant-table-row:nth-child(even) td.ant-table-cell.ant-table-cell-fix-left {
  background: white;
}
.ant-table-row:nth-child(odd) td.ant-table-cell.ant-table-cell-fix-left {
  background: #E7ECEE;
}
.specification-table .ant-table-row.table-row-light td.ant-table-cell.ant-table-cell-fix-right {
  background: white;
}
.specification-table .ant-table-row.table-row-dark td.ant-table-cell.ant-table-cell-fix-right {
  background: #E7ECEE;
}
td .ant-select-selection-item {
    color: rgb(0 0 0 / 65%);
    font-weight: 400;
}
.specification-table .ant-table .ant-table-expanded-row-fixed {
  padding: 0;
  margin: 0;
}
.specification-table .ant-table .ant-table-expanded-row td.ant-table-cell,
.specification-table .ant-table .ant-table {
  padding: 0 !important;
}
.specification-table .ant-table .ant-table th.ant-table-cell:first-child {
  width: 45px;
  min-width: 45px;
}
.specification-table .ant-table .ant-table th.ant-table-cell:nth-child(2) {
  min-width: 50px;
  width: 50px;
}
.specification-table th.ant-table-cell {
  font-size: 15px;
  font-weight: 600;
}
.specification-table .ant-table .ant-table th.ant-table-cell {
  font-size: 14px;
}
/* .specification-table .ant-table-wrapper.ptable-1.specification-table th:not(:first-child):not(:nth-child(2)):not(:nth-child(3)) {
  min-width: 100px;
} */
.specification-table td.ant-table-cell.ant-table-row-expand-icon-cell button {
  margin: auto;
  float: none;
}
.specification-table .ant-table .ant-table-expanded-row td.ant-table-cell:first-child button {
  border: 1px solid #f0f0f0;
  background: white;
  padding: 1px 1px;
  height: auto;
  line-height: 0;
}
.specification-table .ant-table .ant-table-expanded-row td.ant-table-cell:first-child button .anticon {
  font-size: 11px;
  line-height: 0;
}
.specification-table .ant-table .ant-table-expanded-row .ant-table-thead tr th {
  background: white;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
}
.specification-table .ant-table .ant-table-expanded-row:nth-child(odd) tr.ant-table-row,
.specification-table .ant-table tr.table-row-dark + .ant-table-expanded-row tr.ant-table-row  {
  background: #E7ECEE;
}
.specification-table .ant-table .ant-table-expanded-row:nth-child(even) tr.ant-table-row,
.specification-table .ant-table tr.table-row-light + .ant-table-expanded-row tr.ant-table-row {
  background: white;
}
.file-upload-search {
  width: 315px;
  margin-left: auto;
  max-width: 100%;
}
.specification-table span.anticon, 
.project-tables span.anticon {
  color: #9D9E9E;
}
.specification-table .ant-table th {
  padding: 0 7px;
}
.specification-table td.ant-table-cell .ant-select {
  width: 100%;
}
/* .project-management.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
  box-shadow: none;
} */
.ant-tabs.operations-data-tabs.ant-tabs-card .ant-tabs-nav-scroll {
  text-align: right;
  box-shadow: 0px 0.17em 0.17em rgb(0 0 0 / 25%);
  margin: 10px 17px 10px 10px;
  z-index: 2;
}
.operations-data-tabs.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab::after {
  content: "";
  width: 1px;
  height: 20px;
  display: block;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  background: rgb(0 0 0 / 0.1);
  left: -1px;
}
.tab-title-left .ant-tabs-nav-scroll{
  text-align: left !important;
}
.ant-tabs.operations-data-tabs .master-justify-between {
  padding: 20px 0px;
}
.project-name .ant-form-item-label > label, 
.project-dtls-right .ant-form-item-label > label,
.project-name .ant-input, 
.project-dtls-right .ant-input {
  font-weight: 300;
  color: black;
  font-size: 14px;
}
.operations-data-tabs .project-management {
  margin-top: 3px;
}
.proj-catMarkup .ant-table-header {
  width: calc(100% + 3px);
}
.project-search-outer.projCat-search-outer {
  max-width: inherit;
}
.project-search-outer.projCat-search-outer {
    margin: 0 15px;
}
.proj-docRegister table tr td:not(:first-child) {
  min-width: 100px;
}
.proj-catMarkup.master-table table td:nth-child(2) .ant-select {
  max-width: 150px;
}
.proj-catMarkup table td:not(:first-child):not(:last-child),
.proj-catMarkup table th:not(:first-child):not(:last-child) {
  min-width: 150px;
  width: 150px;
}
.proj-catMarkup.master-table table tr td:nth-child(2),
.proj-catMarkup.master-table table tr th:nth-child(2) {
  min-width: 170px;
  width: 170px;
}
.master-table.proj-catMarkup table th:nth-last-child(2),
.master-table.proj-catMarkup table td:nth-last-child(1) {
  min-width: 70px;
  width: 70px;
}
.proj-catMarkup.master-table table tr td:first-child,
.proj-catMarkup.master-table table tr th:first-child {
  min-width: 50px;
  width: 50px;
}
.master-table.proj-catMarkup table .ant-table-row:nth-child(even) td:nth-last-child(1),
.specification-table table .ant-table-row:nth-child(even) td:nth-last-child(1) {background: white;}
.proj-docRegister table th.ant-table-cell:nth-last-child(2) {
  border-right: none;
}
.proj-docRegister table th.ant-table-cell:last-child {
  display: none;
}
.proj-catMarkup.proj-docRegister .ant-table-header {
  width: 100%;
}
/* .hide-empty-rows.specification-table .ant-table-body {
  margin-right: -7px;
  padding-right: 0;
} */
.specification-table .ant-table table tr th:nth-last-child(2) {
  border-left: 2px solid #d8d8d8 !important;
}
.specification-table .ant-table table tr td:last-child {
  border-left: 0.12em solid rgba(0, 0, 0, 0.1) !important;
}
.slick-vertical .slick-slide:not(.slick-current) {
  padding-top: 6px;
}
.specification-table + .pagination-display {
  right: 85px;
}
.project-tables .ant-select,
.specification-markup .ant-form-item-control-input-content .ant-input-number {
  max-width: 100%;
}
.delete-image .ant-form-item-control-input {
  margin-bottom: -25px;
}
th.ant-table-cell.scrollRight::after {
  box-shadow: none;
}
.ant-table-cell.scrollRight {
  font-size: 0;
}
/* .ant-table-wrapper.specification-table td.ant-table-cell.ant-table-cell-fix-right.ant-table-cell-fix-right-first {
  display: none;
} */
.specification-markup .ant-modal-content {
  padding-bottom: 40px;
}
/* th.ant-table-cell.spec-action.ant-table-cell-fix-right, td.ant-table-cell.spec-action.ant-table-cell-fix-right {
  min-width: 100px;
  width: 100px;
} */
td.ant-table-cell.spec-action.ant-table-cell-fix-right {
  padding-left: 3px;
  padding-right: 3px;
}
.spec-action .ant-space-item:not(:last-child) {
  margin-right: 5px !important;
}
.specification-view-modal .ant-modal-content {
  padding-top: 30px;
}
.specification-table .ant-table-row.table-row-light {
  background-color: #ffffff;
}
.specification-table .ant-table-row.table-row-dark {
  background-color: #E7ECEE;
}
th.ant-table-cell.scrollRight::before {
  right: -20px;
}
.tab-extra-left .ant-tabs-extra-content{float: left !important;}
.tab-extra-right .ant-tabs-extra-content{float: right !important;}
.width-266{width:266px !important;}
@media screen and (max-width: 991px) {
  .project-name {
    border-right: none;
    width: 100%;
  } 
}
/* @media screen and (max-width: 1400px) and (min-width: 1366px) {
  .operations-data-tabs.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
    width: 151px;
  }
} */
@media screen and (max-width: 2560px) and (min-width: 1200px) {
  .operations-data-tabs.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
    /* min-width: 110px; */
    min-width: 80px;
    height: 24px;
  }
  .project-name {
    width: 286px;
  }
  .project-dtls-right {
    width: calc(100% - 286px);
  }
  .project-management.ant-tabs .ant-select.searchSelect {
    width: 266px;
    min-width: 266px;
  }
  .project-management.ant-tabs .select-tabpanel .ant-select.searchSelect .ant-select-arrow {
    left: 244px;
  }
  
}

@media screen and (min-width: 1441px) {
  /* .ant-table-wrapper.specification-table th.ant-table-cell.ant-table-cell-fix-right.ant-table-cell-fix-right-first,
  .ant-table-wrapper.specification-table td.ant-table-cell.ant-table-cell-fix-right.ant-table-cell-fix-right-first {
    width: 0;
    padding: 0;
    min-width: 0;
    right: 0 !important;
  } */
}
@media screen and (max-width: 1440px) {
  .specification-table table tr td:not(:first-child):not(:nth-child(2)):not(:nth-last-child(2)):not(:last-child) {
    min-width: 150px;
    width: 150px;
  }
  .hide-empty-rows.specification-table .ant-table-body {
    padding-right: 0px;
    margin-right: -7px;
  }
  .ant-table-cell.scrollRight::before {
    content: "";
    width: 53px;
    display: block;
    height: 100%;
    border-left: 1px solid rgb(0 0 0 / 0.1);
    margin-left: -60px;
    border-right: 1px solid rgb(0 0 0 / 0.1);
}
  .ant-table-cell.scrollRight {
      border: none !important;
  }
  .ant-table-row:nth-child(odd) .ant-table-cell.scrollRight::before {
    background: #E7ECEE;
  }
  .ant-table-row:nth-child(even) .ant-table-cell.scrollRight::before {
    background: white;
  }
  .specification-table .ant-table-row.table-row-dark .ant-table-cell.scrollRight::before {
    background: #E7ECEE;
  }
  .specification-table .ant-table-row.table-row-light .ant-table-cell.scrollRight::before {
    background: white;
  }
  th.ant-table-cell.scrollRight::before {
    top: 0;
    position: absolute;
    content: "...";
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f1f0f0;
    width: 53px;
    /* margin-left: -61px; */
    /* border-right: 2px solid rgb(0 0 0 / 0.1); */
    right: -20px;
  }
  th.ant-table-cell.scrollRight {
    right: 159px !important;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td.scrollRight::before {
    border-top: 2px solid #9DBCD9;
    border-bottom: 2px solid #9DBCD9;
  }
  .ant-table-cell.hs-code {padding-right: 60px !important;}
}
@media screen and (max-width: 1200px) {
  .project-management.ant-tabs .select-tabpanel .ant-select.searchSelect .ant-select-arrow {
    left: auto;
    right: 30px;
  }
  .project-management.ant-tabs .ant-select.searchSelect {
    min-width: inherit;
  }
}
.header-boq{
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 27px;
    color: var(--probluedarker);
}
.boq-header-label{
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 17px;
  color: #3370A8;
}
.boq-header-label-2{
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 17px;
  color: #000000;
}
.boq-outer-card{
    background: #F0EFEF;
    border-radius: 6px 6px 0px 0px;
}
.boq-outer-card-2{
  background: #F6F9F9;
  border-radius: 6px;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active{
    background: var(--problue) !important;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    border-color: var(--problue);
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab{
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    background: #F1F0F0;
    border: none;
    padding: 4px 16px;
    margin-right: 5px;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab:first-child {
    border-radius: 5px 0px 0px 0px;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab:last-child,
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab:last-child .tab-form-progress::after {
    border-radius: 0px 6px 0px 0px;
}
.boq-table {
    border-collapse: collapse;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 10px 10px 0px 0px;
  }
  .boq-table-header{
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.12), -4px 4px 4px rgba(0, 0, 0, 0.05);
  }
  
  .boq-table td{
    border-right: 1px solid #ddd;
    padding: 8px;
    height: 65px;
  }
  
  .boq-table tr:nth-child(odd){
    background: #E7ECEE;
    opacity: 0.8;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  }
  
  .boq-table tr:hover {background-color: #ddd;}
  
  .boq-table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    color: var(--probluedarker);
    border-right: 1px solid #525252;
    padding: 8px;
    height: 33px;
    background: #FFFFFF;
  }
  .boq-table th:first-child {
    border-radius: 5px 0px 0px 5px;
  }
  .boq-table th:last-child {
    border-radius: 0px 5px 5px 0px;
    border-right:none;
  }
  .boq-table-header{
    background: #F1F0F0;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.12), -4px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
  }
/*   .table-responsive-boq{
      overflow-y: scroll;
  }
  .table-responsive-boq::-webkit-scrollbar {
    height: 7px;
    width: 7px;
    border-radius: 5px;
  }
  
  .table-responsive-boq::-webkit-scrollbar-thumb {
    background: var(--scrollbarColor);
    border-radius: 5px;
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  } */
  /*ant table start*/
  .ant-table-thead{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  }
  .ant-table-thead > tr > th{
    background: #F1F0F0;
    text-align: center !important;
    border-right: 1px solid #bebebe;
    padding: 0px 5px;
    height: 26px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    /* text-decoration-line: underline; */
    color: #5F5A60;
  }
  .ant-table-container table > thead > tr:first-child th:first-child{
    border-radius: 5px 0px 0px 0px;
    border-color: #F1F0F0;
  }
  .ant-table-container table > thead > tr:first-child th:last-child{
    border-radius: 0px 5px 0px 0px;
    border-color: #F1F0F0;
  }
  .ant-table-row:nth-child(odd){
    background: #E7ECEE;
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05); */
  }
  .ant-table.ant-table-bordered thead > tr > th, 
  .ant-table.ant-table-bordered tbody > tr > td, 
  .ant-table.ant-table-bordered tfoot > tr > th, 
  .ant-table.ant-table-bordered tfoot > tr > td {
    border-right: 1px solid rgb(0 0 0 / 0.1);
  }
  .ant-table-tbody > tr > td{
    border-right: 1px solid #ddd;
    padding: 6px 8px;
    height: 33px;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;

    color: #857E7E;
  }
  td.ant-table-column-sort{
    background: transparent !important;
  }
  .ant-table-column-sorters{
    padding: 0px;
  }
  /*ant table end*/

  /*comment btn start*/
  .comment-btn{
    border: 0px;
    background: transparent;
  }
  .comment-btn:hover{
    background: transparent;
  }
  .comment-btn:active{
    background: transparent;
  }
  .comment-btn:focus{
    background: transparent;
  }
  /*comment btn end*/
  .content-right{
    text-align: right;
  }
  .black-color .ant-table-filter-trigger{
    color: black;
  }
  /*auto search start*/
  .certain-category-search-dropdown .ant-select-dropdown-menu-item-group-title {
    color: #666;
    font-weight: bold;
  }
  
  .certain-category-search-dropdown .ant-select-dropdown-menu-item-group {
    border-bottom: 1px solid #f6f6f6;
  }
  
  .certain-category-search-dropdown .ant-select-dropdown-menu-item {
    padding-left: 16px;
  }
  
  .certain-category-search-dropdown .ant-select-dropdown-menu-item.show-all {
    text-align: center;
    cursor: default;
  }
  
  .certain-category-search-dropdown .ant-select-dropdown-menu {
    max-height: 300px;
  }
  .col-border{
    border:1px solid black;
  }
  .col-color{
    background: #028ac8;
    color: white !important;
  }
  .comment-modal textarea {width: 100%;}
  /* .boq-mngmt-search .ant-select-selector {
    min-width: 190px;
  } */
  .boq-mngmt-search {
    width: 75%;
    display: flex;
  }
  .boq-mngmt-search .ant-form {
    display: flex;
    grid-column-gap: 6px;
    -webkit-column-gap: 6px;
            column-gap: 6px;
    width: 100%;
  }
  .filter-btn button.ant-btn.grey-btn {
    width: 35px;
    min-width: 0;
    min-width: initial;
    padding: 5px 5px 3px;
  }
  .ant-row.boq-mngmt-filter {
    display: flex;
    flex-wrap: nowrap;
    grid-column-gap: 2px;
    -webkit-column-gap: 2px;
            column-gap: 2px;
    align-items: start;
    justify-content: space-between;
  }
  .grey-btn-outer.filter-btn {
    margin-left: 2px;
  }
  .boq-mngmt-search > .ant-form > *:not(:last-child) {
    width: 100%;
  }
  .ant-dropdown.ant-dropdown-placement-bottomRight {
    z-index: 999 !important;
  }
  .spec-ID .ant-select-selection-item .anticon {
    display: none;  
  }
  .boq-mgmt-table th.ant-table-cell:not(:first-child):not(:last-child):not(:nth-last-child(2)), 
  .boq-mgmt-table td.ant-table-cell:not(:first-child):not(:last-child) {
    min-width: 140px;width: 140px;
  } 
  .boq-mgmt-table .ant-table-cell .ant-space, 
  .boq-mgmt-table .ant-table-cell .ant-space-item, 
  .boq-mgmt-table .ant-table-cell .ant-select {
      max-width: 100%;
      padding: 0 3px 0 0;
  }
  .boq-mgmt-table .ant-table-cell:not(:last-child) .ant-space-item .anticon.anticon-comment {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
  }
  .boq-mgmt-table .ant-table-cell:last-child .ant-space-item .anticon.anticon-comment {
    font-size: 18px;
  }
  .hide-empty-rows.boq-mgmt-table .ant-table-body {
    max-height: calc(100vh - 375px) !important;
  }
  .hide-empty-rows.boq-mgmt-table.boq-coordinator-table .ant-table-body {
    max-height: calc(100vh - 445px) !important;
  }
  .boq-search-table .ant-table-content::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 7px;
    background: #C4C4C4;
    /* border-radius: 5px; */
  }
  
  .boq-search-table .ant-table-content::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: var(--scrollbarColor);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }
  .hide-empty-rows.boq-mgmt-table .ant-table-body {
      padding-right: 0px;
      margin-right: -7px;
  }
  .boq-mngmt-search .ant-select-multiple .ant-select-selector {
    flex-wrap: nowrap;
    overflow: hidden;
  }
  .boq-mngmt-search .ant-form .ant-form-item:not(:last-child) {
    min-width: 20%;
    max-width: calc(33.33% - 17px);
  }
  /*auto search end*/
  /*Selection column start*/
/*   .ant-table th.ant-table-selection-column{
    width: 50%;
  } */
  .boq-mngmt-btns {
    display: flex;
    align-items: center;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
    justify-content: flex-end;
  }
  .boq-mngmt-btns .anticon-comment {
    font-size: 18px;
    color: #9D9E9E;
  }
  .grey-btn .ant-btn[disabled] {
    border: transparent;
  }
  .boq-details .specification-table .ant-table-body {
    max-height: calc(100vh - 375px) !important;
  }
  td.ant-table-cell.boq-action-outer div, td.ant-table-cell.uom-col div{
    width: 100%;
    padding-right: 0 !important;
  }
  .boq-action-outer .boq-action {
    display: flex;
    justify-content: space-around;
  }
  .boq-modal .ant-comment-content-author-name {
    color: rgb(48 109 165);
    text-transform: capitalize;
  }
  .boq-modal .ant-comment-inner {
    border: 1px solid rgb(48 109 165 / 0.1);
    padding: 10px 0;
    margin-bottom: 15px;
    border-radius: 0 20px 20px 20px;
  }
  .boq-modal .ant-comment-nested {
    margin-left: 24px;
  }
  .ant-tabs.ant-tabs-card.boq-mgmt-tab-1 .ant-tabs-card-bar .ant-tabs-tab {
    padding: 4px 10px 0px;
    font-size: 14px;
    font-weight: 400;
  }
  .progress-slider .ant-slider-rail {background-color: #c4c4c4;}
  .progress-slider.ant-slider-disabled .ant-slider-track {
    background-color: rgb(49 110 166 / 0.5) !important;
  }
  .progress-slider .ant-slider-track {
    background-color: var(--probluedarker);
  }
  .disabled-px-0 .ant-btn{
    padding-left: 0px;
    padding-right: 0px;
  }
  .dc-boq-filter-hide-pannel{overflow: hidden;}
  .comment-present{color: #0085ff !important;font-size: 18px;}
  .position-relative{position: relative;}
  .ant-row.boq-mngmt-filter .ant-col {width: 100%;}
  .comment-box-height{
    max-height: 50vh;
    margin-bottom: 10px;
  }
  .comment-box-height{
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: thin;
  }
  .comment-box-height::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
    height: 5px;
  }
  
  .comment-box-height::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(230, 242, 253, 0.8);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }
  @media screen and (max-width: 1799px) {
    .ant-row.boq-mngmt-filter {flex-wrap: wrap;}
    /* .ant-row.boq-mngmt-filter .ant-col {width: 100%;} */
    .ant-row.boq-mngmt-filter .grey-btn-outer {float: right;}
    .hide-empty-rows.boq-mgmt-table .ant-table-body {
      max-height: calc(100vh - 415px) !important;
    }
    .hide-empty-rows.boq-mgmt-table.boq-coordinator-table .ant-table-body {
      max-height: calc(100vh - 485px) !important;
    }
    .boq-mgmt-table th.ant-table-cell.scrollRight::before {
      width: 53px;
      right: -20px;
    }
  }
  @media screen and (max-height: 870px) {
    .hide-empty-rows.boq-mgmt-table .ant-table-body {
      max-height: calc(100vh - 320px) !important;
    }
    .hide-empty-rows.boq-mgmt-table.boq-coordinator-table .ant-table-body {
      max-height: calc(100vh - 390px) !important;
    }
    .boq-details .specification-table .ant-table-body {
      max-height: calc(100vh - 335px) !important;
    }
  }
  @media screen and (max-width: 1440px) and (max-height: 870px)  {
    .hide-empty-rows.boq-mgmt-table .ant-table-body {
      max-height: calc(100vh - 375px) !important;
    }
    .hide-empty-rows.boq-mgmt-table.boq-coordinator-table .ant-table-body {
      max-height: calc(100vh - 445px) !important;
    }
    .boq-details .specification-table .ant-table-body {
      max-height: calc(100vh - 390px) !important;
    }
  }
  @media screen and (max-height: 800px) {
    .hide-empty-rows.boq-mgmt-table .ant-table-body {
      max-height: calc(100vh - 265px) !important;
    }
    .hide-empty-rows.boq-mgmt-table.boq-coordinator-table .ant-table-body {
      max-height: calc(100vh - 335px) !important;
    }
    .boq-details .specification-table .ant-table-body {
      max-height: calc(100vh - 290px) !important;
    }
  }
  @media screen and (max-width: 1440px) and (max-height: 800px)  {
    .hide-empty-rows.boq-mgmt-table .ant-table-body {
      max-height: calc(100vh - 320px) !important;
    }
    .hide-empty-rows.boq-mgmt-table.boq-coordinator-table .ant-table-body {
      max-height: calc(100vh - 390px) !important;
    }
    .boq-details .specification-table .ant-table-body {
      max-height: calc(100vh - 280px) !important;
    }
    .my-custom-cell.wj-cell .my-icon {
      cursor: pointer;
    }
    /* .my-custom-cell.wj-cell {
      padding: 3px;
    } */
    
    .my-custom-cell.wj-cell > div {
      display: flex;
      justify-content: space-between;
    }
  }
@-webkit-keyframes fadeIn {
	0% {
		transform: translateY(-25%);
	}
	50%{
		transform: translateY(4%);
	}
	65%{
		transform: translateY(-2%);
	}
	80%{
		transform: translateY(2%);
	}
	95%{
		transform: translateY(-1%);
	}			
	100% {
		transform: translateY(0%);
	}		
}

@keyframes fadeIn {
	0% {
		transform: translateY(-25%);
	}
	50%{
		transform: translateY(4%);
	}
	65%{
		transform: translateY(-2%);
	}
	80%{
		transform: translateY(2%);
	}
	95%{
		transform: translateY(-1%);
	}			
	100% {
		transform: translateY(0%);
	}		
}

.popup {
  -webkit-animation-name: fadeIn;
          animation-name: fadeIn;
  -webkit-animation-duration: 0.4s;
          animation-duration: 0.4s;
  background-clip: padding-box;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  left: 0px;
  list-style-type: none;
  margin: 0;
  outline: none;
  padding: 0;
  position: absolute;
  text-align: left;
  top: 0px;
  overflow: hidden;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.popup li {
  clear: both;
  color: rgba(0, 0, 0, 0.65);
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  margin: 0;
  padding: 5px 12px;
  transition: all .3s;
  white-space: nowrap;
  -webkit-transition: all .3s;
}

.popup li:hover {
  background-color: #e6f7ff;
}

.popup li > i {
  margin-right: 8px;
}
.field-border{
    border: grey;
}

.example {
    text-align: center;
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 30px 50px;
    margin: 20px 0;
}

.ant-checkbox-group-item {
    padding: 6px;
    display: block;
}
.pqf-header{
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #5F5A60;
}
.pqf-inner-card{
    background: rgba(214, 244, 252, 0.25);
    border-radius: 10px;
}
.pqf-outer-card{
    background: #F0EFEF;
    border-radius: 6px;
    /* height: 500px;
    overflow-y: scroll; */
}
/* .pqf-outer-card::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  
  .pqf-outer-card::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  } */
.pqf-title{
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 27px;
    color: var(--probluedarker);
    text-transform: uppercase;
}
.pqf-input-box{
    width: 100%;
    background: #FFFFFF;
    opacity: 0.5;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    height: 28px;
    color: black;
}
/* .ant-input:placeholder-shown{
    font-size: 12px !important;
} */
/* .ant-input-number-input{
    font-size: 12px !important;
} */
.pqf-item-mb{
    margin-bottom: 5px !important;
}
/* .pqf-labels{
    text-align: left !important;
} */
.ant-form-item-label{
    text-align: left !important;
    color: #000000 !important;
    font-size: 12px !important;
    padding: 0 !important;
}
.mb-20{
    margin-bottom: 20px;
}
.item-mb{
    margin-bottom: 10px !important;
}
.btn-success{
    background: #63AA4A;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}
.radio-btns{
    background: rgba(255, 255, 255, 0.43);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px !important;
    width: 84px;
    height: 23px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px !important;
    line-height: 18px;
    color: #9D9E9E;
    text-align: center;
    margin-left: 10px;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before{
    background-color:transparent !important;
}
.ant-radio-button-wrapper:not(:first-child)::before{
    background-color: transparent !important;
}
/* .view-revisions{
    text-align: center;
} */
#customers {
    border-collapse: collapse;
    width: 100%;
    text-align: center;
  }
  
  #customers td, #customers th {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  #customers tr:nth-child(even){background-color: #f2f2f2;}
  
  #customers tr:hover {background-color: #ddd;}
  
  #customers th {
    background-color: var(--probluedarker);
    color: white;
  }
  .health-club-card{
    background: #F7FDFE;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    margin-bottom: 20px;
  }
  .checkbox-label-style-2{
    display: inline-flex;
  }
  .checkbox-label-style-2 .ant-checkbox{
    margin-top: 5px;
  }
  .pqf-col-pl-30{
    padding-left: 30px;
  }
  .dragger-height{
      height: 50vh;
  }
  .link-btn{
    line-height: 1.5715;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    border: 1px solid transparent;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    touch-action: manipulation;
    height: 32px;
    padding: 4px 15px;
    font-size: 14px;
    border-radius: 2px;
    color: rgba(0, 0, 0, 0.65);
    background-color: #fff;
    border-color: #d9d9d9;
  }

  .pqf-form-style .ant-form-item-label > label{
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 16px;
    /* text-align: right; */
    color: #000000;
  }

  .pqf-form-style .ant-divider-horizontal.ant-divider-with-text::before{
    border-top: 1px solid #5F5A60;
  }
  .pqf-form-style .ant-divider-horizontal.ant-divider-with-text-right::after{
    display: none;
  }
  .pqfOSE-form-style .ant-divider-horizontal.ant-divider-with-text-left::after{
    border-top: 1px solid #5F5A60;
  }
  .pqfOSE-form-style .ant-divider-horizontal.ant-divider-with-text-left::before{
    display: none;
  }
  .pqfOSE-form-style .ant-divider-inner-text{
    padding-left: 0;
  }

  .notice-box{
    background: #9DBCD9;
    border-radius: 3px 0px 0px 3px;
    width: 257px;
  }

  .centered-notice-items{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
  }

  .bill-ship-form-width{
    max-width: 455px;
    margin: auto;
  }
.bill-ship-block-btn2{
  padding-right: 0px;
  color: var(--probluedarker);
  font-style: normal;
  font-weight: 600;
  /* font-size: 12px; */
}
  .bill-ship-block-btn{
    height: 63px;
    text-align: right;
    background: #F0F6F8;
    border: transparent;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    color: var(--probluedarker);
    padding-right: 67px;
  }

  .bill-ship-block-btn:hover,
  .bill-ship-block-btn:active,
  .bill-ship-block-btn:focus
  {
    height: 63px;
    text-align: right;
    background: #F0F6F8;
    border: transparent;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    color: var(--probluedarker);
    padding-right: 67px;
  }
  .list-temp-modal .ant-modal-body {
    background: linear-gradient(90deg,transparent 4.5%, rgba(230, 241, 243, 0.5) 4.5%, rgba(230, 241, 243, 0.5) 95.5%, transparent 95.5%);
    padding-left: 8%;
    padding-right: 8%;
    margin-bottom: 30px;
  }
  .version-modal .ant-modal-body {
    background: linear-gradient(90deg,transparent 3.5%, rgba(230, 241, 243, 0.5) 3.5%, rgba(230, 241, 243, 0.5) 96.5%, transparent 96.5%);
    padding-left: 6%;
    padding-right: 6%;
    margin-bottom: 10px;
    padding-bottom: 5px;
  }
  .boq-modal .ant-modal-body {
    margin-bottom: 30px;
  }
.list-temp-modal .ant-modal-content, .boq-modal .ant-modal-content {
  padding-bottom: 5px;
}
.list-temp-modal:not(.comment-modal) .ant-modal-body .ant-form-item, .version-modal .ant-modal-body .ant-form-item {
  margin-bottom: 0;
}
.bill-cross {text-align: right;}
.copyAddress-icon {
  color: var(--probluedarker);
  font-size: 25px;
  position: absolute;
  margin-left: 5px;
  margin-top: 45px;
  z-index: 10;
}
.ant-picker.date-style {
  width: 100%;
  background: #FFFFFF;
  opacity: 0.5;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  height: 28px;
  color: black;
}
.ant-picker.date-style .ant-picker-input > input {
  color: black;
}
.dc-inp-num-hide-controls{
  width: 100%;
}
.dc-inp-num-hide-controls .ant-input-number-handler-wrap{
  visibility: hidden;
}
.picker-translucent .ant-picker,.picker-translucent .ant-picker-focused{
  background:none;
  border: none;
  box-shadow:none;
}

.benchmark-field {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    grid-gap: 8px;
    gap: 8px;
  }
  .benchmark-field .ant-input-number {
    width: 70px;
  }
  .template-form-outer {
    padding: 15px;
  }
  form.ant-form.template-form {
    margin: -15px;
    padding: 4px;
  }
  .ant-collapse.template-collapse,
  .ant-collapse.template-collapse .ant-collapse-item {
    border: none;
  }
  .ant-collapse.template-collapse .ant-collapse-header {
    background: #f7f7f7 !important;
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important; */
  }
  .ant-collapse.template-collapse:nth-child(even)  .ant-collapse-header, 
  .edit-criteria-form, 
  .edit-criteria-form .ant-row:nth-child(even) .ant-collapse-content.ant-collapse-content-active {
    background: #E7ECEE !important;
  }
  .ant-collapse.template-collapse .ant-collapse-item-active .ant-collapse-header {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .template-collapse .ant-form-item-label > label::after {
    display: none;
  }
  .template-collapse .ant-form-item-label > label {
    padding-right: 10px;
    text-align: right;
    word-break: break-word;
  }
  .pqq-template-collapse .ant-form-item-label > label:not(.pqq-template-collapse .file-div .ant-form-item-label > label) {
    /* padding-right: 10px; */
    text-align: right;
    /* word-break: break-word; */
  }
  .template-collapse .ant-form-item {
    margin-bottom: 0px;
    /* width: 100%; */
  }
  /* .template-collapse textarea {
    resize: none;
  } */
  .file-div .ant-form-item-label label {
    color: #857E7E;
    font-size: 11px;
  }
  .file-div .ant-form-item .ant-upload {
    float: right;
  }
  .ant-col.file-div {
      padding: 5px 0;
  }
  /* .add-question-outer {
    margin: -7px -15px;
  } */
  .add-question-btn {
    background: #f7f7f7;
    border-radius: 0;
    height: 35px;
    border: none;
  }
  .add-question-btn:hover {
    color: #3471A9;
  }
  .template-collapse .ant-collapse-content,
/*   .template-collapse .gray-bg .ant-collapse-content > .ant-collapse-content-box,
  .ant-collapse.template-collapse {
    background: transparent;
  } */
  .template-collapse .gray-bg .ant-collapse-content > .ant-collapse-content-box {
    padding: 16px 0;
  }
  .dynamic-form-outer form {
    margin: 0 -24px 0 -16px;
    padding: 0 24px 0 16px;
  }
  .dynamic-form-outer form .ant-card {
    background: #f7f7f7;
  }
  .template-form-outer .block-btn-3 {
    border: none;
  }
  .question-close-btn {font-size: 18px;margin-top: -15px;margin-right: -15px;}
  .Change-psd-close-btn {
    float: right;
    color: rgb(102, 102, 102);
    position: absolute;
    top: -17px;
    right: -15px;
    font-size: 20px;
  }
  .dynamic-form-outer .ant-card-body {padding: 10px 12px;}
  .add-ques-field .ant-col.add-questn {
    width: calc(100% - 130px);
  }
  .add-ques-field .ant-col.add-quesn-btn {
    width: 130px;
  }
  .template-collapse .dynamic-form-outer .login-form-remember span:not(.ant-checkbox),
  .template-collapse .login-form-remember span:not(.ant-checkbox) {
    float: left;
  }
  .template-collapse .pqq-questn-delete {
    display: none;
  }
  .template-collapse .ant-collapse-item-active .pqq-questn-delete {
    display: block;
  }
  .template-form-outer .custom-scroll {
    margin-right: -25px;
    padding-right: 12px;
    height: calc(100vh - 350px);
  }
  .vendor-score-sectn .custom-scroll {
    margin-right: -7px;
    padding-right: 0px;
    height: calc(100vh - 295px);
    margin-top: 15px;
}
  .template-collapse .ant-checkbox-wrapper:not(.login-form-remember), 
  .template-collapse .ant-radio-wrapper {
    display: flex;
    flex-wrap: wrap;
    min-width: 50px;
    justify-content: center;
    text-align: center;
    margin: auto;
    font-size: 11px;
  }
  .template-collapse .ant-checkbox-wrapper:not(.login-form-remember) span.ant-checkbox,
  .template-collapse .ant-radio-wrapper span.ant-radio {
    order: 2;
  }
  .template-collapse .ant-checkbox-wrapper:not(.login-form-remember) span:not(.ant-checkbox),
  .template-collapse .ant-radio-wrapper span:not(.ant-radio) {
    width: 100%;
    order: 1;
    margin-bottom: 3px;
  }
  .fixed-mr {
    margin-right: 133px;
  }
  form.add-criteria-form {
    background: #e7ecee;
    padding: 6px 10px 8px;
    display: flex;
    grid-gap: 15px;
    gap: 15px;
    align-items: start;
  }
  .add-criteria-form .criteria-form-weight input {
    width: 68px;
    padding: 4px 10px;
    font-size: 12px;
    line-height: 12px;
  }
  .add-criteria-form .criteria-form-name {
    width: 70%;
  }
  .add-criteria-form .criteria-form-btn {
    width: 160px;
    padding: 0 !important
  }
  .ant-form-item.criteria-form-weight input::-webkit-input-placeholder {
    font-size: 12px;
    text-align: center;
    line-height: 12px;
  }
  .ant-form-item.criteria-form-weight input:-ms-input-placeholder {
    font-size: 12px;
    text-align: center;
    line-height: 12px;
  }
  .ant-form-item.criteria-form-weight input::-ms-input-placeholder {
    font-size: 12px;
    text-align: center;
    line-height: 12px;
  }
  .ant-form-item.criteria-form-weight input::placeholder {
    font-size: 12px;
    text-align: center;
    line-height: 12px;
  }
  .criteria-form-btn .grey-btn-outer,
  form.add-criteria-form .ant-form-item {
    margin-bottom: 0;
  }
  .add-criteria-form .ant-row {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
  }
  .template-collapse .ant-radio-wrapper span.ant-radio {min-width: 16px;}
  .options-field {flex-wrap: nowrap;}
  .options-field .ant-col:not(:last-child) {
    width: 50%;
  }
  .template-collapse .ant-tabs-bar {
    margin-bottom: 15px;
  }
/* PQQ */
.template-collapse .ant-col.Small .ant-col-16 input, .template-collapse .ant-col.Small .ant-select{
  width: 90px;
}
.template-collapse .ant-col.Medium .ant-col-16 input, .template-collapse .ant-col.Medium .ant-select {
  width: 150px;
}
.template-collapse .ant-col.Large .ant-col-16 input, .template-collapse .ant-col.Large .ant-select {
  width: 228px;
}
/* Country */
.template-collapse .ant-col.Small select{
  width: 90px;
}
.template-collapse .ant-col.Medium select{
  width: 150px;
}
.template-collapse .ant-col.Large select{
  width: 228px;
}
/* typography */
.template-collapse .ant-col.Small .ant-col-20 .ant-typography{
  width: 90px;
}
.template-collapse .ant-col.Medium .ant-col-20 .ant-typography {
  width: 150px;
}
.template-collapse .ant-col.Large .ant-col-20 .ant-typography {
  width: 228px;
}
.template-collapse .ant-typography{word-break: break-word}
.template-collapse .ant-input-number{width: unset;}
/* Vendor PQQ start */
.pqq-template-collapse .ant-col.Small .ant-col-19 input, .pqq-template-collapse .ant-col.Small .ant-select{
  width: 90px;
}
.pqq-template-collapse .ant-col.Medium .ant-col-19 input, .pqq-template-collapse .ant-col.Medium .ant-select{
  width: 150px;
}
.pqq-template-collapse .ant-col.Large .ant-col-19 input, .pqq-template-collapse .ant-col.Large .ant-select{
  width: 100%;
}
/* Country */
.pqq-template-collapse .ant-col.Small select{
  width: 90px;
}
.pqq-template-collapse .ant-col.Medium select{
  width: 150px;
}
.pqq-template-collapse .ant-col.Large select{
  width: 228px;
}
.pqq-template-collapse .ant-input-number{width: unset;}
/* Vendor PQQ end */
.ques-component .ant-form-item-control {
    max-width: 130px;
    width: 130px !important;
}
/* .template-collapse .input-crazy-style .ant-form-item-label > label {
    text-align: right;
} */
.options-field .ant-col.options-input input {
    width: 140px;
    font-size: 12px;
}
.template-collapse .options-3 .ant-col.ant-form-item-control {
    width: 350px;
}
.template-collapse .options-3 .ant-col.ant-form-item-label {
    width: calc(90% - 350px);
}
/* .template-collapse .options-2 .ant-col.ant-form-item-control {
    width: 240px;
} */
.template-collapse .options-2 .ant-col.ant-form-item-label {
    width: calc(90% - 240px);
}
/* .options-field .ant-col:not(.options-input) {
    padding: 6px 0 !important;
} */
.template-collapse .options-2 .ant-col.ant-form-item-control .ant-col.options-input {padding-right: 0 !important;}
.options-field {
    margin-top: -4px;
}
.options-field .ant-col.options-input {
    margin-top: 4px;
}
/* .options-field .ant-col.options-input {
    margin-top: 7px;
} */
.template-collapse .options-field .ant-radio-inner {
    width: 12px !important;
    height: 12px;
}
.template-collapse .options-field .ant-radio-inner::after {
    width: 6px;
    height: 6px;
    top: 2px;
    left: 2px;
}
.options-2 .ant-col.ant-form-item-label label {
    text-align: left;
    float: none;
}
.ant-row.ques-component {
    margin-right: 125px !important;
    justify-content: space-between;
}
.criteria-component  .ant-col.ant-form-item-label {
    min-width: 59px;
}
.criteria-component  .ant-col.ant-form-item-label label {
    text-align: left;
    float: none;
}
.ant-form-item.add-content-textarea {
    margin-left: 59px;
    padding: 14px 0 0px;
    width: calc(100% - 55px);
}
.ant-form-item.add-content-textarea textarea {
    width: calc(100% - 30px);
    margin-bottom: 3px;
}
.ant-form-item.add-content-textarea .anticon {
    font-size: 14px;
    color: var(--probluedarker);
}
.template-collapse .ant-picker, .boq-modal .ant-picker {
    background: #FFFFFF;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px !important;
    height: 28px;
}
.boq-modal .ant-picker {width: 100%;}
.ant-card.new-section {
  background: #f7f7f7;
  border: none;
}
.ant-card.new-section .ant-card-body {
  padding: 10px;
  background: #f7f7f7;
}
.ant-card.new-section .ant-card-head {
  padding: 0 10px;
}
.ant-card.new-section .ant-card-head .ant-card-head-title {
  padding: 5px 0;
  font-weight: 400;
  font-size: 14px;
  color: var(--probluedarker);
}
.new-section .ant-form-item {
  margin-bottom: 12px;
}
.new-section .ant-card-body .add-questn-sectn  .ant-form-item {
  margin-bottom: 0;
}
.ant-collapse.template-collapse.pqq-collapse .ant-collapse-header,
.edit-criteria-form .ant-collapse .ant-collapse-header {
  padding-right: 30px;
}
.ant-collapse.template-collapse.pqq-collapse .ant-collapse-header .ant-collapse-extra,
.edit-criteria-form .ant-collapse .ant-collapse-header .ant-collapse-extra {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
}
.benchmark-field .ant-input-number .ant-input-number-input {
  height: 28px;
}
.score-input{
  width: 64px !important;
  height: 24px;
  padding: 4px 4px !important;
}
.score-input .ant-input-number-input{height: auto !important;}
.points-input .ant-input-number-input{
  width: 30px !important;
  height: 26px;
}
.action-popover{
  padding-bottom: 0px !important;
}
.action-popover .ant-popover-inner-content{
  padding: 3px 12px;
}
.action-popover .ant-popover-arrow{
  display: none;
}
.icon-rotate{
  transform: rotate(44deg);
  position: relative;
  top: -12px;
  left: -9px;
}
.error-text-color{
  font-size: 16px;
  color: #ee2d2d;
}
.triangle-error-action-btn{
  font-size: 26px;
  color: #ee2d2d;
}
.triangle-action-btn{
  font-size: 20px;
  color: var(--problue);
}
.triangle-action-btn:hover,.triangle-action-btn:focus,.triangle-action-btn:active{
  color: #C4C4C4 !important;
}
.dc-flex{display: flex;}
.btn-tl{
  position: absolute;
  left: 0;
  padding: 0;
  height: 15px;
  width: 15px;
}
.cellcomment-tl{position: absolute;top: 0;left: 0;}
.cellcomment-tl .ant-badge-count{left: 0; top: 13px;}
.cellcomment-btn{padding: 0;height: 15px;width: 15px;}
.dc-pts .ant-input-number-input{
  padding: 0 7px;
}
.dc-pts .ant-input[type=number]{
  padding: 0 7px;
}
.dc-pts-width{
  width: 37px !important;
}
.dc-pts-width-pqq{
  width: 75px;
}
.modal-question-card{
  background: rgba(225, 231, 233, 0.5);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
}
.modal-question-card .ant-card-body{
  padding: 14px 25px 36px 18px;
}
.dc-visible .pqq-collapse-scroll, .dc-visible .ant-collapse-content-active{
  overflow: visible !important;
}
.range-inp-width{
  width: 63px !important;
}
.question-label-px label{
  padding: 5px 9px 5px 25px;
}
.dc-dark-label label{
  text-align: right;
  color: rgba(0, 0, 0, 0.65);
  font-weight: unset !important;
}
.dc-flex-start{
  align-items: flex-start;
}
/* .dc-hid-overflow-col-8 .ant-col-8{
  overflow: hidden;
} */
.login-form {
    max-width: 300px;
  }
  .login-form-forgot {
    float: right;
  }
  .login-form-remember{
    float: left;
  }
  .ant-col-rtl .login-form-forgot {
    float: left;
  }
  .login-form-button {
    background: var(--probluedarker);
    border-color: var(--probluedarker);
    color: white;
    border-radius: 5px;
  }
  .login-form-button:focus{
    background-color: #578bbb;
    color: #e4e4e4;
  }
  .form-div {
    padding-top: 5%;
  }
  .login-header-style{
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: var(--probluedarker);
    padding-bottom: 15px;
    text-align: left;
  }
  .forgot-header-style{
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: var(--probluedarker);
    text-align: left;
  }
  .or-register{
    padding-top: 20px;
  }
  .card-style{
    background: #FBFBFB;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
  }
  .input-box-style{
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    background: #FFFFFF !important;
    height: 38px !important;
  }
  .login-logo{
    width: 158px;
    height: 41.24px;
    margin-bottom: 23px;
  }
  .submit-btn{
    background: var(--probluedarker) !important;
    border-color: var(--probluedarker) !important;
    border-radius: 5px;
    color: white;
  }
  .submit-btn:focus{
    background-color: #578bbb !important;
    color: white;
  }
  .submit-btn:hover{
    background-color: #578bbb !important;
    color: white;
  }
  .ml-4px{
    margin-left: 4px;
  }
  .forgot-text{
    text-align: left;
    padding-bottom: 15px;
  }
  .validation-alignment .ant-form-item-explain{
    text-align: left;
  }
  .Checkbox-prevent-text-wrap{
    display: block;
    margin-left: 18px;
    text-align: left;
  }
  .Checkbox-prevent-text-wrap .ant-checkbox{margin-left: -20px;}
.client-form-multicol {
    margin-bottom: 0px;
  }
  .client-multicol-form-item {
    display: 'inline-block';
    width: 'calc(50% - 8px)';
  }
  .pqq-title{
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 27px;
    color: var(--probluedarker);
    text-transform: uppercase;
}
.client-title{
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  color: var(--probluedarker);
}
.pqq-card-col-padding{
  padding-right: 10px;
}
.pqq-mt{
  margin-top: 20px;
}
.pqq-mb-20{
  margin-bottom: 20px;
}
.pqq-collapse-scroll::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 7px;
}

.pqq-collapse-scroll::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.pqq-collapse-scroll{
  /* height: 500px; */
  overflow-y: scroll;
  overflow-x: hidden;
}
/* .pqq-radio{
  display: table-cell;
  line-height: 30px;
  height: 30px;
} */
.tab-panel{
  padding: 15px;
}
.benchmrk-label{
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  
  color: #5F5A60;
}
.black-label{
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  /* text-align: center; */
}
.benchmrk-input-style{
  background: #FFFFFF;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
}
/* .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab .ant-tabs-close-x{
  color: #FFFFFF !important;
} */
.save-btn{
  background: #F1F0F0;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #5F5A60 !important;
  width: 80px;
  height: 30px;
}
.save-btn2{
  background: #F1F0F0;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #5F5A60;
  width: 67px;
  height: 28px;
  text-align: center;
}
.save-btn3{
  background: #F1F0F0;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  height: 29px;
  width: 30px;
  padding: 5px;
}
.save-btn3 .ant-btn[disabled]{
  display: contents;
  border: none;
  background: #F1F0F0;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
}
.add-btn{
  background: #F1F0F0;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #5F5A60;
  height: 28px;
  text-align: center;
}
.add-btn:hover,.add-btn:focus,.add-btn:active{
  background: #e2e2e2 !important;
  color: #5F5A60 !important;
  border-color: #e2e2e2 !important;
}
.cancel-btn{
  background: #FFFFFF;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #63AA4A;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
}
.cancel-btn-2{
  background: #FFFFFF;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #9D9E9E;
  width: 114px;
}
.checkbox-stacked{
      display: list-item;
      height: 25px;
      line-height: 25px;
      margin-left: 8px;
}
.sort-checkbox .ant-checkbox-wrapper{
  display: list-item;
  height: 25px;
  line-height: 25px;
  margin-left: 8px;
}
.gray-outer-card{
  background: #F6F9F9;
  border-radius: 6px;
}
.center-align .ant-form-item-control{
  align-items: center;
}
.blue-table-btn{
  background: #d7e1e4;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-style: normal;
/*   font-weight: 600;
  font-size: 14px;
  line-height: 17px; */
  color: var(--probluedarker);
}
.block-btn-3{
  background: #E7ECEE;
  color: #3471A9;
  text-align: start;
  height: 46px;
}
.block-btn-4{
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  text-align: start;
  color: #3471A9;
}
.pull-end{
  display: flex;
  justify-content: flex-end;
}

.input-crazy-style .ant-radio-group{
display: grid;
}
.input-crazy-style .ant-checkbox-group{
  display: grid;
}
.input-crazy-style .ant-checkbox-wrapper:first-child{
  margin-left: 8px;
}
.input-crazy-style .ant-radio-wrapper{
  white-space: nowrap;
}
.display-none{display: none;}
.editable-row .ant-form-explain {
  position: absolute;
  font-size: 12px;
  margin-top: -4px;
}

.btn-success{
  background-color: #44bd44;
  color: white
}

.btn-success:hover{
  background-color: #44bd44;
  color: white
}

.btn-margin{
  margin-left: 13%;
}

.master-outer-card{
  background: #F6F9F9;
  border-radius: 6px;
}
.master-card-text{
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: var(--probluedarker);
}
.master-sub-text{
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}
.master-label-text{
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: var(--probluedarker);
}
.master-card-input{
  background: #FFFFFF;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  height: 38px;
}
.default-btn-style{
  background: #FFFFFF;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
}
.master-pr-20{
  padding-right: 20px;
}
.required-style{
  display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
}
.division-label{
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
.addnew-taxes-dropdown{
  color: var(--probluedarker);
}
.center-object{
  align-items: center;
  text-align: center;
}
.center-object .ant-form-item-control{
  align-items: center;
  text-align: center;
}
.blue-form{
  background: #F6F9F9;
  box-shadow: 4px 0px 10px rgba(0, 0, 0, 0.1);
}
.blue-bg{
  background: #F6F9F9;
}
.mud-bg{
  /* background: rgba(240, 239, 239, 0.5); */
  /* background: #f8f7f7; */
  border: 2px solid #c5c5c5;
  border-radius: 2px;
  min-height: 70vh;
}
.mud-bg-2{border: 2px solid #c5c5c5;border-radius: 2px;}
.h-75vh{min-height: 75vh;}
.faded-blue-bg{
  background: rgba(214, 244, 252, 0.25);
}
/* .gray-bg .ant-collapse-content > .ant-collapse-content-box{
  background: #F0EFEF;
} */
.add-question-btn{
  color: #3471A9;
  background: #EAEEF0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.tab-pannel-alignment{
  padding: 15px;
  margin-top: 17px;
}
.master-tabs .ant-tabs-bar{
  margin: 0 0 7px 0 !important;
}
.master-form-style .ant-form-item-label > label{
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #5F5A60;
}
.master-form-style .ant-form-item-label > label::after{
  content: '' !important;
}
.master-form-style .ant-space-align-center{
  float: unset;
}
.grid-active-left:hover{
  background: #F0EFEF;
  /* box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.25); */
  box-shadow: 4px 6px 7px 0px rgba(0,0,0,0.25);
}
.grid-active-right:hover{
  background: #F0EFEF;
  /* box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.25); */
  box-shadow: -4px 6px 7px 0px rgba(0,0,0,0.25);
}
.master-col-350 {
  min-width: 353px;
  max-width: 353px;
  /* width: 25%;
  flex: initial; */
}
.master-col {
  width: calc(100% - 353px);
  max-width: inherit;
  flex: initial;
  padding-left: 0 !important;
}
.master-col.masterAuth-col {
  width: 100%;
  padding-left: 10px !important;
}
.master-col-350 .tab-pannel-alignment {
  padding: 1px 0px 15px 12px;
  margin-top: 0;
}
.blue-bg-padding {
  padding: 10px 5%;
}
.master-col-350 .blue-bg-padding {
    max-width: 264px;
    margin: 0 auto;
}
/* .master-col-350 button.ant-btn {
  width: 128px;
} */
.master-btn-outer {
  padding-top: 20px;
}
.ant-form label.master-tab-title {
  font-size: 18px;
  color: #5F5A60;
  font-family: 'Signika Negative';
  padding-left: 15px;
}
.master-justify-between {
  display: flex;
  justify-content: space-between;
  padding: 20px 0px 20px 9px;
}
.master-justify-between.master-justify-end {
  justify-content: flex-end;  
}
/* .master-col .master-table-top-btn button.ant-btn {
  background: #FFFFFF;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
} */
.master-col .grey-btn-outer {
  margin-bottom: 0;
}
.master-col .master-table-top-btn .anticon,
.master-table table .anticon {
  font-size: 10px;
}
.master-col-350 .ant-input-number {
  width: 100%;
}
.master-col .tab-pannel-alignment {
  margin-top: 0;
  padding: 0 24px;
}
.master-table table th.ant-table-cell:nth-last-child(2) {
  min-width: 70px;
}
table tr.ant-table-row td {
  padding: 4px 8px;
  height: 33px !important;
}
.master-col .ant-input-search.search-field.ant-input-affix-wrapper {
  margin-top: 1px;
}
.ant-input-search.search-field.ant-input-affix-wrapper .anticon {
  font-size: 20px;
  padding-top: 5px;
}
.master-proj-list {
  width: 333px;
  /* max-width: 100%;
  margin-bottom: 0; */
  /* min-width: 286px;
  width: 25%; */
  flex: initial;
  /* max-width: 333px; */
}
.master-justify-between .ant-row.ant-form-item,
.master-justify-between .ant-row.ant-form-item .grey-btn-outer {
  margin-bottom: 0;
}
.master-data-tabs .ant-tabs-bar {
  margin-bottom: 0px;
}
.master-proj-list .ant-select-selector {
  height: 30px !important;
}
.master-col-350 .ant-form-item-label > label{
  font-size: 13px;
  min-height: inherit;
}
.master-col-350 .ant-form-item-label {
  padding-bottom: 0;
}
.master-table .ant-space svg path {
  color: #9D9E9E;
  stroke: #9D9E9E;
}
.master-table .ant-space .anticon:hover svg path {
  color: var(--probluedarker);
  stroke: var(--probluedarker);
}
.master-table .ant-space.Inactive .anticon:hover svg path {
  color: #9D9E9E;
  stroke: #9D9E9E;
}
.active-btn-outer button.ant-btn.grey-btn:first-child {
  padding-right: 10px;
}
.active-btn-outer button.ant-btn.grey-btn:last-child {
  padding-left: 10px;
}
.master-data-tabs.ant-tabs.ant-tabs-card .ant-tabs-tab {
  padding: 6px 12px;
  margin-right: 5px;
}
/* .ant-tabs.master-data-tabs.ant-tabs-card {
  margin-top: -8px;
} */
.ant-tabs.master-data-tabs > .ant-tabs-bar > .ant-tabs-nav-container > .ant-tabs-nav-wrap > .ant-tabs-nav-scroll {
  text-align: right;
}
.master-col .master-table-outer .ant-form-item-control-input-content {
  max-width: 1180px;
  margin: 0 auto;
  position: relative;
  width: 100%;
}
.ant-row.master-row {
  display: flex;
  flex-wrap: nowrap;
}
.search-field-outer .ant-form-item-control-input {
  min-height: 28px;
  margin-top: 0px;
}
.ant-form-item.search-field-outer {
  margin-bottom: -4px !important;
}
.active-btn-outer .grey-btn .anticon {
  font-size: 10px;
}
.master-search-outer {
  width: calc(100% - 333px);
  justify-content: flex-end;
  max-width: 1200px;
  margin: 0 auto;
}
.master-justify-end .master-search-outer {
  width: 100%;
  max-width: 1533px;
}

.hide-empty-rows .ant-table-body {
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbarColor) #C2D2E4;
  padding-right: 0px;
  margin-right: -7px;
}
.hide-empty-rows .ant-table-body::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 7px; 
  background: #C4C4C4;
  /* border-radius: 5px; */
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.hide-empty-rows .ant-table-body::-webkit-scrollbar-thumb {
  /* border-radius: 4px; */
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: var(--scrollbarColor);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
.hide-empty-rows .ant-table-header th.ant-table-cell.ant-table-cell-fix-right:last-child {
  right: 0 !important;
}
.hide-empty-rows .ant-table-header th.ant-table-cell.ant-table-cell-fix-right:not(.ant-table-cell-scrollbar):not(.scrollRight) {
  right: 0 !important;
}
.hide-empty-rows .ant-table-header th.ant-table-cell.ant-table-cell-scrollbar {
  background: transparent;
  border: none;
}
.multi-select-width .ant-table.ant-table-bordered tbody > tr > td:first-child{
  width: 63px;
}

tr[data-row-key="DND"] > td {
  color: transparent !important;
  font-size: 0 !important;
}
tr[data-row-key="DND"] > td > * {
  display: none;
}

.master-table .ant-table-body{
  /* height: 100vh; */
  /* overflow-y: scroll; */
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbarColor) #C2D2E4;
  padding-right: 0px;
  margin-right: -7px;
}
.master-table .ant-table-body::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 7px; 
  background: #C4C4C4;
  /* border-radius: 5px; */
}
.master-table .ant-table-header th.ant-table-cell.ant-table-selection-column, 
.master-table .ant-table-body td.ant-table-cell.ant-table-selection-column {
  width: 70px;
  max-width: 70px;
  min-width: 70px;
}
.master-table .ant-table-body::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: var(--scrollbarColor);
}
.master-table .ant-table-header th.ant-table-cell.ant-table-cell-fix-right {
  right: 0 !important;
}
.master-table .ant-table-header th.ant-table-cell.ant-table-cell-scrollbar {
  background: transparent;
  border: none;
}

.master-table tr:not([data-row-key]).ant-table-row td > * {
  display: none;
}
.master-table tr:not([data-row-key]).ant-table-row td {
  font-size: 0;
}
.master-table table tr.ant-table-row td .ant-select,
.master-table table tr.ant-table-row td .ant-input-number {
  max-width: 100%;
}
.pqq-proj-list {
  padding: 20px 24px 10px 9px;
}
.master-search-outer.project-search-outer {
  width: 100%;
}
.project-management .master-col {
  padding: 0 23px !important;
}
/* formula builder start */
.formula-input-end{
  display: flex;
  align-items: end;
}
.formula-input-inline{
  display: flex;
  align-items: flex-end;
  width: 96%;
}
.formula-input-item{
  display: inline-block;
  width: 20%;
  margin-right: 5px;
}
  /* formula scroll start */
  .formula-scroll{
    height: 80vh;
    overflow-y: scroll;
    scrollbar-width: thin;
  }
  .formula-scroll::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 7px;
  }
  
  .formula-scroll::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: var(--scrollbarColor);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }
  /* formula scroll end */
/* formula builder end */
.item-baseline{align-items: baseline !important;}
.ant-tabs-bar-none .ant-tabs-bar{border-bottom:none}
@media (min-width: 768px) {
  .master-form-style .ant-space-align-center{
    float: right;
  }
}
@media (max-width: 991px) {
.ant-input-search.search-field.ant-input-affix-wrapper {
  max-width: 90% !important;
}
.master-col .tab-pannel-alignment {
  padding: 0 24px 0 10px;
}
.master-col-350 {
  min-width: 200px;
  width: 25%;
}
.master-col {
  width: calc(100% - 200px);
}
.master-table .ant-table-body {
  padding-right: 0px;
  margin-right: -7px;
}
.master-proj-list {
  width: 36%;
}
.master-search-outer {
  width: 64%;
}
}
@media (min-width: 1780px) {
  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
    font-size: 16px;
 }
 .master-data-tabs.ant-tabs.ant-tabs-card .ant-tabs-tab {
  padding: 7px 18px;
 }
/*  .ant-tabs.master-data-tabs.ant-tabs-card {
  margin-top: -30px;
 } */
 .formula-input-inline{
   width: 100%;
 }
}
/* @media (min-width: 1780px) {
  .master-data-tabs.ant-tabs.ant-tabs-card .ant-tabs-tab {
    padding: 7px 25px;
  }
} */
@media screen and (max-width: 1400px) and (min-width: 1366px) {
  .master-data-tabs.ant-tabs.ant-tabs-card .ant-tabs-tab {
    padding: 6px 8px;
  }
}
@media screen and (max-width: 1440px) and (min-width: 1200px) {
  .master-col-350 {
    min-width: 286px;
    max-width: 286px;
  }
  .master-proj-list {
    width: 266px;
  }
  .master-col {
    width: calc(100% - 286px);
  }
  .master-search-outer {
    width: calc(100% - 266px);
  }
}
.react-resizable {
  position: relative;
  background-clip: padding-box;
}

.react-resizable-handle {
  position: absolute;
  right: -5px;
  bottom: 0;
  z-index: 1;
  width: 10px;
  height: 100%;
  cursor: col-resize;
}
.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
.selection-col-width colgroup col:first-child {
  width: 40px;
  min-width: 40px;
}
.vendor-heading {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: var(--probluedarker);
  margin-bottom: 20px;
}
.outer-vendor-card {
  background: #fbfbfb;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}
.inner-vender-card {
  background: #f7fdfe;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
}
.inner-vender-card .ant-card-body {
  padding-top: 10px;
}
.question_key_styling {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #3370a8;
  margin-right: 15px;
  word-break: break-word;
}
.question_styling {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  /* background-color: #f7fdfe; */
}
.action-btn-col-padding {
  float: right;
  margin-top: 20px;
}
.action-btn-padding {
  padding: 10px;
}
.pts-style {
  margin-left: 30px;
  color: rgba(0, 0, 0, 0.65);
}
.pts-input-style {
  width: 25%;
  height: 20px;
}
.radio-checkbox-style {
  display: block !important;
  height: 30px;
  line-height: 30px;
}
.checkbox-first {
  margin-left: 8px;
}

.possible-score {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #9d9e9e;
}
.poss-score-col {
  padding-left: 15px;
}
.weightage-style {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-top: 15px;
}
.vsm-input {
  width: 30%;
}
.vsm-close-btn {
  text-align: right;
}
.close-icon-style {
  color: var(--probluedarker);
}
.minus-style {
  margin-left: 10px;
}
.vsm-my-5 {
  margin: 5px 0px 5px 0px;
}
.vsm-mt-20 {
  margin-top: 20px;
}
.vsm-center {
  text-align: center;
}
.vsm-btn-right {
  text-align: right;
  padding: 20px;
}
.vsm-btn {
  text-align: left;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #857e7e;
}
.vsm-pb-10 {
  padding-bottom: 10px;
}
.vsm-mt-5 {
  margin-top: 5px;
}
.vsm-radio-stack {
  display: inline-block;
  height: 30px;
  line-height: 30px;
}
.add-quest {
  color: var(--probluedarker);
  margin-right: 10px;
}
.vsm-radio-stack span.ant-radio {
  display: none;
}
.icon-left .anticon-paper-clip {
  left: 0;
}
.ant-upload-list-item-error,
.ant-upload-list-item-error .ant-upload-text-icon > .anticon,
.ant-upload-list-item-error .ant-upload-list-item-name {
  left: 0;
}
.edit-criteria-form .criteria-inner input {
  width: 64px;
  height: 22px;
}
.benchmark-field .ant-input-number-handler-wrap {
  display: none;
}

/* .edit-criteria-form {
  padding: 10px 19px 10px 10px;
} */
.edit-criteria-form .ant-collapse .ant-collapse-header {
  padding: 6px 25px;
  padding-left: 40px;
  color: #5f5a60;
}
.edit-criteria-form .ant-row:nth-child(even) .ant-collapse-header {
  background: #e7ecee;
}
.edit-criteria-form .ant-collapse,
.edit-criteria-form .ant-row .ant-collapse-item,
.edit-criteria-form .ant-row .ant-collapse-content.ant-collapse-content-active {
  border: none;
}
/* .edit-criteria-form .possible-score input {
  width: calc(100% - 100px);
} */
.ant-row.add-criteria-form {
  padding: 10px 19px 0px 10px;
  width: 100%;
}
.edit-criteria-form .possible-score {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  font-size: 12px;
  font-weight: 300;
  align-items: center;
}
.edit-criteria-form
  .ant-collapse
  .ant-collapse-item-active
  .ant-collapse-header {
  color: var(--probluedarker);
}
.edit-criteria-form .ant-row:nth-child(odd) .ant-collapse {
  background: #f5f7f7;
}
.edit-criteria-form .ant-collapse .ant-collapse-content {
  background: transparent;
  padding-bottom: 7px;
}
.edit-criteria-form .ant-collapse .ant-collapse-content-box {
  padding: 0;
}
.add-more-col button.ant-btn {
  padding: 0 4px;
  height: 22px;
  font-size: 12px;
}
.ant-col.add-more-col {
  width: 75px;
}
.criteria-inner .ant-col.poss-score-col {
  padding-left: 10px;
  width: 144px;
  padding-right: 5px;
}
.ant-col.criteria-first-col {
  width: calc(100% - 220px);
}
.cust-collapse-icon {
  width: 30px;
  display: inline-flex !important;
  align-items: center;
}
.cust-collapse-content {
  width: calc(100% - 30px);
}
.options-to {
  display: flex;
  justify-content: space-between;
  grid-gap: 5px;
  gap: 5px;
}
.ant-col.criteria-first-col .options-outer {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-evenly;
}
.score-inner {
  display: flex;
}
.ant-col.criteria-first-col .ant-radio-group {
  width: 100%;
}
.edit-criteria-form .ant-collapse .ant-collapse-extra {
  display: none;
}
.edit-criteria-form
  .ant-collapse.assigned-questions
  .ant-collapse-item-active
  .ant-collapse-extra {
  display: block;
}
.edit-criteria-form .criteria-inner {
  padding: 0 10px;
}
.criteria-first-col .ops-name-inner .pts-style span {
  padding-right: 5px;
}
.ant-col.criteria-first-col .options-outer .ops-name-inner {
  text-align: right;
  width: 180px;
  color: #9d9e9e;
  font-weight: normal;
}
.ant-col.criteria-first-col .options-outer .ops-name-inner span span {
  padding-right: 5px;
  font-size: 12px;
  font-weight: 300;
}
.add-criteria-form input.ant-input {
  font-size: 12px;
}
.edit-criteria-form {
  padding-top: 5px;
}
.add-vendor-btn {
  box-shadow: none;
  width: unset;
  border: transparent;
  box-shadow: -1px 5px 9px -5px rgba(0, 0, 0, 0.5);
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  height: 32px;
}
button.ant-btn.close-edit-criteria {
  font-size: 18px;
  margin-top: -7px;
  margin-right: -9px;
}
.vendor-score-sectn {
  margin-top: 37px;
}
.edit-delete-inner {
  grid-gap: 5px;
  gap: 5px;
  padding-left: 7px;
}
.vendor-score-sectn .medium-sub-text {
  margin-bottom: 0;
}
button.ant-btn.save-btn2:hover {
  background: #f1f0f0;
  border: none;
  color: #5f5a60;
}
.ant-collapse.unassigned-questions {
  opacity: 0.7;
}
.range-error {
  color: red;
  margin-left: 20px;
  padding: 5px;
  display: block;
}
.scoring-criteria {
  padding-top: 0px !important;
}
.rebate-checkbox {
  float: left;
  flex-direction: unset !important;
  grid-gap: 12px;
  gap: 12px;
}
.rebate-checkbox .ant-form-item-label {
  order: 2;
}
.rebate-checkbox .ant-form-item-control {
  order: 1;
}
.vendor-modal select#createVendor_country,
.vendor-modal select#createVendor_State,
select#ProjectItem_project_location {
  padding: 4px 11px;
  outline: none;
  border: 1px solid #d9d9d9;
}
.vendor-modal select#createVendor_country:focus,
.vendor-modal select#createVendor_State:focus,
select#ProjectItem_project_location:focus {
  border: 1px solid #40a9ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.vendor-name{
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 20px;
color: #5F5A60;
}
.card-900{
  width: 900px;
}

/* added for vendorlandingpage */
.welcome-vendor-card {
  text-align: center;
  padding: 10vh 5vh;
  border-radius: 0;
  background: rgba(240, 246, 249, 0.6);
}
.dc-select-proj{
  width: 269px;
}
.dc-select-vendor{width: 272px;}
.dc-select-vendor .ant-select-selection-item:nth-child(-n + 2){width: 84px;}
.custom-extra-content-left{
  position: absolute;
  top: 0;
  left: 15px;
}
.grey-tick svg{stroke: gray; fill: gray;}
.orange-tick svg{stroke: orange; fill: orange;}
.green-tick svg{stroke: green; fill: green;}
.red-tick svg{stroke: red; fill: red;}
.pro-comment-badge{
  color: white;
  position: absolute !important;
  left: 0;
  top: 3px;
  font-size: 11px;
}
.vendor-notice-title{
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #5F5A60;
}
.vendor-notice-outer .ant-modal-body{padding: unset;}
.vendor-notice-outer .ant-modal-header{background: unset;}
.vendor-notice-outer .ant-modal-title{text-align: left;}
.vendor-notice-outer .ant-modal-body{background:unset;padding-left:unset;padding-right:unset;}
.vendor-notice-text{
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: #5F5A60;
}
.warning-msg{
  color: red;
}
.dc-vendor-tabs .ant-tabs-extra-content{padding: 0px 15px;}
.dc-vendor-tabs .ant-tabs-tab:first-child{display: none;}
.display-hidden-child .ant-tabs-tab:first-child{display: inline-block;}
.vendlist-actions{
  width: 50%;
  position: absolute;
  top: -40px;
}
.ant-upload-list-item .ant-progress{display: none;}
.ant-upload-list-item-error, .ant-upload-list-item-error .ant-upload-text-icon > .anticon, .ant-upload-list-item-error .ant-upload-list-item-name{color: #5F5A60;}
.pcs-inp .ant-select-selector{
  max-height: 60px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.pcs-inp .ant-select-selector{
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: thin;
}
.pcs-inp .ant-select-selector::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
  height: 5px;
}

.pcs-inp .ant-select-selector::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: var(--problue);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
.move {cursor: move;}
.fake-inp{
  background: #f5f5f5;
  box-shadow: 4px 4px 10px rgb(0 0 0 / 5%), inset 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 3px !important;
  border-style: solid;
  border-width: 1px;
  border-color: #bfbfbf;
  min-height: 28px;
  padding: 2px 7px;
  cursor: not-allowed;
}
.fake-inp{
  max-height: 36px;
  overflow-y: scroll;
  scrollbar-width: thin;
}
.fake-inp::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
  height: 5px;
}
.fake-inp::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: var(--scrollbarColor);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
.chamfered-box{
  border-radius: 5px;
  overflow: hidden;
  border: 0.1px solid #838383;
  background-color: #f2f2f2;
}
.gray-border-left{border-left: 0.1px solid #838383;}
.issue-rfp-upload .ant-upload-list{
  position: absolute;
  right: 17px;
  bottom: 58px;
}
.issue-rfp-upload .ant-upload-list-item-name{width: 203px;}
.inp-center .ant-input-number-input{text-align: center;}

.input-no-border{
  border: none;
  box-shadow: none;
  background:transparent;
}
.checkbox-mr-0 .ant-checkbox-group-item{margin-right: 0 !important;}
.profile-img{
  border-radius: 50%;
  height: 200px;
  width: 180px;
  margin-left: auto;
  margin-right: auto;
  display: block; 
}
.role-permission-header{
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: var(--probluedarker);
  margin-bottom: 0px;
}
.role-permissions-card{
  background: #F7FDFE;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  margin-bottom: 20px;
}
.roles-per-card-text{
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #3370A8;
  padding-left: 15px;
}
.select-input-style{
  width: 229px !important;
  height: 28px;
}
.ant-select-selector{
  background: #FFFFFF;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px !important;
}
.options-style{
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #3370A8;
}
.ant-checkbox-inner{
  background: #FFFFFF;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: none;
}
.checkbox-label-style{
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}
.checkbox-mb{
  margin-bottom: 15px;
}
.btn-success{
  background: #63AA4A;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.operations-data-tabs .ant-tabs-bar{
  margin: 0 0 0;
}
.operations-data-tabs.ant-tabs .ant-tabs-nav > div {
  display: flex;
}
.permissionsTabs.ant-tabs .ant-tabs-nav>div {
  flex-wrap: wrap;
}
/* .user-management .ant-tabs-bar .ant-tabs-nav-wrap .ant-tabs-tab{
  border-bottom: 3px solid var(--probluedarker);
} */
.user-management .ant-tabs-bar .ant-tabs-nav-wrap .ant-tabs-tab:first-child{
  border-top-left-radius:3px !important;
  border-bottom-left-radius:3px !important;
}
.user-management .ant-tabs-bar .ant-tabs-nav-wrap .ant-tabs-tab:last-child{
  border-top-right-radius:3px !important;
  border-bottom-right-radius:3px !important;
}
.user-management .ant-tabs-nav-wrap{
  display: flex;
  justify-content: flex-end;
}
.tab-header-center .ant-tabs-nav-wrap{
  justify-content: center;
}
.tabs-header-end .ant-tabs-nav-wrap{justify-content: flex-end !important;}
.tabs-header-start .ant-tabs-nav-wrap{justify-content: flex-start !important;}
.pill-tabs.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  min-width: 80px;
  height: 24px;
}
.pill-tabs.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  padding: 4px 10px;
  width: auto;
  margin-right: 0;
  box-shadow: none;
}
.pill-tabs.ant-tabs-card .ant-tabs-nav-scroll {
  text-align: center;
  box-shadow: 0 0.17em 0.17em rgb(0 0 0 / 25%);
  margin: 10px 17px 10px 10px;
  z-index: 2;
}
.pill-tabs.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab:after {
  content: "";
  width: 1px;
  height: 20px;
  display: block;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  background: rgb(0 0 0/.1);
  left: -1px;
}
.user-verification-tab .ant-tabs-bar .ant-tabs-nav-wrap .ant-tabs-tab{
  border-bottom: none;
  margin: -1px 0px 7px 0 !important;
}
.user-verification-tab .ant-tabs-ink-bar{
  background-color:transparent !important;
}
.user-verification-tab .ant-tabs-nav-wrap{
  border-top: none;
}
.user-management-tab-bg{
  background: rgba(240, 239, 239, 0.5);
}
.blue-block-btn{
  background: #E7ECEE;
  text-align: left;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: var(--probluedarker);
}
.user-verification-tab .ant-tabs-top-bar{
  float: right;
}
.verify-style{
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #5F5A60;

  background: #F1F0F0;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  padding: 5px 10px 5px;
}
.permissionsTabs.ant-tabs .ant-tabs-tab {
  background: rgba(231, 236, 238,  0.8);
  text-align: left;
  padding: 12px 24px 11px 34px;
  border: none !important;
  color: var(--probluedarker);
  font-weight: 600;
  width: 100% !important;
  text-align: left !important;
  margin-bottom: 12px;
}
.permissionsTabs .ant-tabs-tab::before {
  width: 4px;
  height: 4px;
  background: #3C7AB4;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  border: none;
}
.permissionsTabs .ant-tabs-nav-wrap {
  border: none;
}
.permissionsTabs.ant-tabs .ant-tabs-ink-bar,
.permissionsTabs.ant-tabs .ant-tabs-tab-next, 
.permissionsTabs.ant-tabs .ant-tabs-tab-prev {
  display: none !important;
}
.permissionsTabs.ant-tabs .ant-tabs-bar.ant-tabs-left-bar {
  border: none !important;
  width: 42%;
  min-width: 235px;
  max-width: 365px;
}
.permissionsTabs.ant-tabs .ant-tabs-tab.ant-tabs-tab-active {
  background: rgba(51, 112, 168, 0.8);
  color: white;
  overflow: visible;
}
.permissionsTabs .ant-tabs-tab.ant-tabs-tab-active::before {
  background: white;
}
.permissionsTabs.ant-tabs .ant-tabs-tab.ant-tabs-tab-active::after {
  content: "";
  background: #5a8bb7;
  width: 50px;
  height: 100%;
  position: absolute;
  top: 0;
  right: -49px;
  display: block;
}
.permissionsTabs.ant-tabs .ant-tabs-nav-container, 
.permissionsTabs.ant-tabs .ant-tabs-nav-wrap, 
.permissionsTabs.ant-tabs .ant-tabs-nav-scroll, 
.permissionsTabs.ant-tabs .ant-tabs-nav, 
.permissionsTabs.ant-tabs .ant-tabs-nav > div {
  overflow: visible;
}
.permissionsTabs.ant-tabs .ant-tabs-content {
  height: calc(100% - 40px);
  width: calc(62% - 23px);
  padding: 50px 58px;
  margin-left: 23px;
  background: rgba(231, 236, 238, 0.8);
  max-width: 365px;
  display: flex;
  align-items: center;
}
.permissionsTabs.ant-tabs .ant-tabs-content .ant-tabs-tabpane {
  display: none;
}
.permissionsTabs.ant-tabs .ant-tabs-content .ant-tabs-tabpane.ant-tabs-tabpane-active {
  display: block;
} 
.ant-tabs.permissionsTabs {
  display: flex;
  height: 100%;
}
.permissionsTabs .ant-tabs-bar .ant-tabs-nav-wrap .ant-tabs-tab {
  max-width: 365px;
}
.dc-permissions-collapse .ant-collapse-content-box{
  padding: 0px;
}
/* green tick mark start */
.dc-green-tick .ant-checkbox-inner{
  background-color: unset;
  border: none;
  box-shadow:none;
  width:20px !important;
  height:20px !important;
}
.dc-green-tick .ant-checkbox-checked .ant-checkbox-inner:after {
  border-color: #189780 !important;
  width: 6.714286px;
  height: 14.142857px;
}
/* green tick mark end */
.dc-permissions-form-top{
  position: absolute;
  top: -37px;
  width: 85%;
}
.reset-pill-tabs .pill-reset-2 .ant-tabs-nav-wrap .ant-tabs-nav-scroll{
  margin: 10px 0 0 0 !important;
  padding: unset !important;
  box-shadow: none !important;
}
.reset-pill-tabs .pill-reset-2 .ant-tabs-bar .ant-tabs-nav-wrap .ant-tabs-tab{
  margin-right: 6px !important;
}
.dc-hide-from-view .ant-select-selection-search{
  width: 100% !important;
}
.scrollTabs .ant-tabs-bar.ant-tabs-left-bar {
    display: none;
}
/* .inp-matrix-table {padding-right: 17px;} */
.inp-matrix-table + .pagination-display {
    bottom: 37px;
}
.osne-fileupload .project-search-outer.projCat-search-outer {
    margin: 0;
}

/*Typography start*/
.proj-name{
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    color: #000000;

    margin-bottom: 5px;
}
.black-label-2{
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
  }
.gray-label-1{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #857E7E;
}
.gray-label-2{
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #9D9E9E;
}
.gray-label-3{
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 21px;
    color: #9D9E9E !important;
}
.gray-label-3-pale{
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #9D9E9E !important;
}
.gray-label-4-dark{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #5F5A60 !important;
}
.gray-label-5-dark-uppercase{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #5F5A60;
    text-transform: uppercase;
}
.gray-label-6-dark{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #5F5A60;
}
.blue-label-1-sm{
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 14px;
    color: #3C7AB4;
}
.blue-label-3-md{
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    color: #3C7AB4;
}
.blue-label-4-pale{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #3C7AB4;
}
.blue-label-5-pale{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #3C7AB4;
}
.blue-label-5-dark{
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #3370A8;
}
.blue-label-6-md-dark{
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: var(--probluedarker);
}
.red-label-1{
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 34px;
    color: #FF5722;
}
.green-label-1{
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 34px;
    color: green;
}
.deviation-label-1{
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 34px;
    color: #3a3a3a;
}
.ellipse-dots{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
/* typography end */
/* Alignment & positions start */
.justify-center{
    display: flex;
    justify-content: center;
}
.pull-bottom{
    position: absolute;
    bottom: 0;
    width: 100%;
}
.vertical-center{
    text-align: center;
    margin-top: 25%;
}
/* Alignment & positions end */
/* chart styling start*/
svg > g > g:last-child { pointer-events: none }
.chart-1-info-box-1{
    background: #C4C4C4;
    border-radius: 2px 0px 0px 2px;
    text-align: center;
    padding: 5px 0 5px 0;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #FFFFFF;
}
.chart-1-info-box-2{
    border: 2px solid #ACACAC;
    box-sizing: border-box;
    border-radius: 2px;
    border-top: none;
    text-align: center;
    padding: 10px 0 10px 0;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #ACACAC;
}

.chart-2-info-box-1{
    background: #8BADCD;
    border-radius: 2px 0px 0px 2px;
    text-align: center;
    padding: 5px 0 5px 0;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #FFFFFF;
}
.chart-2-info-box-2{
    border: 2px solid #8BADCD;
    box-sizing: border-box;
    border-radius: 2px;
    border-top: none;
    text-align: center;
    padding: 10px 0 10px 0;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #99B8D4;
}
/* chart styling end*/
.mtb-2 {
    margin-top: 2rem;
    margin-bottom: 2rem;
}
.slick-slider.verSlider-inner.slick-vertical {
    /* box-shadow: inset -15px 0px 30px rgba(0, 0, 0, 0.03); */
    width: 220px;
    height: 542px;
    position: relative;
    border-radius: 0 50% 50% 0;
    display: flex;
    align-items: center;
}
.slick-slider.verSlider-inner.slick-vertical:not(.verSlider-small) .slick-list {
    height: 542px !important;
}
.slick-slider.verSlider-inner.slick-vertical .slick-list {
    padding: 0 !important;
    display: flex;
    /* overflow-y: auto; */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
    width: 100%;
}
.slick-slider.verSlider-inner.slick-vertical .slick-list::-webkit-scrollbar {
    display: none;
}
.slick-slider.verSlider-inner.slick-vertical.verSlider-small-10 .slick-list {
    height: 542px !important;
    padding-top: 50px !important;
}
.slick-slider.verSlider-inner.slick-vertical.verSlider-small-9 .slick-list {
    height: 442px !important;
}
.slick-slider.verSlider-inner.slick-vertical.verSlider-small-8 .slick-list {
    height: 442px !important;
    padding-top: 50px !important;
}
.slick-slider.verSlider-inner.slick-vertical.verSlider-small-7 .slick-list {
    height: 342px !important;
}
.slick-slider.verSlider-inner.slick-vertical.verSlider-small-6 .slick-list {
    height: 342px !important;
    padding-top: 50px !important;
}
.slick-slider.verSlider-inner.slick-vertical.verSlider-small-5 .slick-list {
    height: 242px !important;
}
.slick-slider.verSlider-inner.slick-vertical.verSlider-small-4 .slick-list {
    height: 242px !important;
    padding-top: 50px !important;
}
.slick-slider.verSlider-inner.slick-vertical.verSlider-small-3 .slick-list,
.slick-slider.verSlider-inner.slick-vertical.verSlider-small-1 .slick-list {
    height: 142px !important;
}
.slick-slider.verSlider-inner.slick-vertical.verSlider-small-2 .slick-list,
.slick-slider.verSlider-inner.slick-vertical.verSlider-small-0 .slick-list {
    height: 142px !important;
    padding-top: 50px !important;
}
.slick-slider .slick-track {
display: flex;
align-items: center;
justify-content: space-around;
/* flex-direction: column; */
flex-wrap: wrap;
margin: auto;
}
.verSlider-outer {
    width: 220px;
    margin-left: -15px;
    padding: 50px 15px;
    position: relative;
    background: url(/static/media/CurveSmall.10220af9.svg) no-repeat;
    background-size: contain;
    background-position: 0px center;
}
.slick-slide span {
    color: #857E7E;
    padding: 5px 0;
    display: block;
    font-size: 15px;
    /* text-decoration: underline; */
    max-width: 126px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    transition: all 0.3s ease;
}
.slick-slide.slick-active.slick-current span {
    font-size: 21px;
    color: #3471A9;
    font-weight: 600;
    max-width: 175px;
}
.verSlider-curve {
    /* box-shadow: inset -6px 0px 10px rgba(0, 0, 0, 0.25); */
    border-radius: 0 50% 50% 0;
    /* padding: 50px 10px; */
    /* background: white; */
}
.slick-arrow.slick-prev {
    top: -35px;
}
.slick-arrow {
    position: absolute;
    left: 10px;
    transform: translateX(-50%) rotate(-90deg);
}
.slick-arrow::before {
content: "\276F";
color: #5F5A60;
opacity: 1;
}
.slick-arrow.slick-next {
    top: auto;
    bottom: -35px;
    transform: translateX(-50%) rotate(90deg);
}
.verSlider-sectn {
    position: fixed;
    z-index: 10;
    margin-left: -30px;
}
.project-details-outer {
    /* margin: 0 0 0 -75px; */
    text-align: right;
}
.project-details-inner {
    background: rgb(231 235 237 / 0.5);
    margin-bottom: 18px;
    padding-bottom: 15px;
}
/* .verSlider-sectn::before {
    content: "";
    position: fixed;
    background: linear-gradient(180deg, transparent 0, transparent 30%, white 31%, white);
    /* background-image: url('../Assets/images/curve.svg'); */
    /* box-shadow: inset -15px 0px 30px rgba(0, 0, 0, 0.03);
    width: 240px;
    height: 100%;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: -1; */
    /* border-radius: 0 50% 50% 0; */
    /* border: 7px solid white; */
/* }  */
/* .verSlider-sectn:before {
    content: "";
    position: fixed;
    background-image: url('../Assets/imgs/solar-system.svg');
    background-repeat: no-repeat;
    background: linear-gradient(180deg,transparent,transparent 30%,#fff 31%,#fff);
    box-shadow: inset -15px 0 30px rgba(0,0,0,.03);
    width: 2000px;
    height: 200%;
    top: 50%;
    left: -1760px;
    transform: translateY(-50%);
    z-index: -1;
    border-radius: 0 50% 50% 0;
    border: 7px solid #fff;
} */
.verSlider-outer::before {
    content: "";
    position: fixed;
    width: 245px;
    left: -5px;
    height: 100%;
    top: 0;
    z-index: -1;
    background: url(/static/media/CurveBig.51cf80d1.svg) no-repeat;
    /* background-size: cover; */
    background-position: 0px 0px;
    
}
.sidebarMenuTitle {
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #5F5A60;
    margin: 30px 0 15px 38px;
}
.details-btn{
    background: var(--probluedarker);
    color: white;
    /* padding: 3px 20px 4px 60px; */
    border-radius: 5px;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 12px;
    font-weight: 700;
    font-size: 15px;
    height: 45px;
    width: 111px;
}
.titleTop {
    background: #F0EFEF;
    color: #5F5A60;
    padding: 11px 10px;
    border-radius: 5px;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05), inset 0px 4px 4px rgba(0, 0, 0, 0.25), 0 0 0px 10px white;
    margin: 40px 0;
    font-weight: 700;
    font-size: 15px;
    text-align: center;
    display: none;
    width: 100px;
    margin-left: auto !important;
}
.ant-tabs-tab-active .titleTop {
    background: var(--probluedarker);
    color: white;
}
.project-details-inner span.badge {
    width: 20px;
    border-radius: 50%;
    background: #CFD8DC;
    height: 20px;
    display: flex;
    font-size: 15px;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
}
.project-details-inner::after {
    clear: both;
    display: block;
    content: "";
}
.project-details-outer h3.titlebottom {
    background: #F0EFEF;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    color: #5F5A60;
}

.verSliderDiv {
    width: 190px;
}
.verSliderSubDiv {
    width: 195px;
    margin: 0 20px 0 -130px;
}
.verSliderContent {
    width: calc(100% - 190px);
}
.pqf-header-outer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

}
.tabs-style .ant-tabs-tab:nth-child(1) {
    border-radius: 5px 0px 0px 0px;
    margin-right: 5px;
}
.tabs-style .ant-tabs-tab:nth-child(2) {
    border-radius: 0px 5px 0px 0px;
}
.tabs-style .ant-tabs-tab {
    background: #F1F0F0;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-weight: bold;
    font-size: 15px;
    padding: 5px 18px 7px;
    line-height: 1.2;
    color: #5F5A60;
    ;
}
.tabs-style .ant-tabs-tab.ant-tabs-tab-active {
    background: #3370A8;
    color: white;
}
.tabs-style .ant-tabs-ink-bar {
    display: none !important;
}
label.pqf-formTitle {
    display: block;
    text-align: right;
    position: relative;
    margin-bottom: 15px;
}
label.pqf-formTitle span {
    display: inline-block;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #5F5A60;
    line-height: 1.4;
    position: relative;
    background: rgb(240 239 239);
    padding: 2px 10px;
}
label.pqf-formTitle::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background: #5F5A60;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}
.guestroomOuter {
    background: #F3F5F6;
    border-radius: 6px;
}
.verticalLayout-form .ant-col.ant-form-item-label {
    width: 100%;
}
.verticalLayout-form textarea {
    resize: none;
}
.ant-col.guestroomOuter {
    padding: 50px 45px 50px 25px !important;
}
.ant-tabs.ant-tabs-left.menuTabs {
    overflow: visible;
}

.ant-tabs.menuTabs .subMenuTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #5F5A60;
    margin: 16px 11px 16px auto;
    display: block;
    width: 75px;
}
.ant-tabs.menuTabs .ant-tabs-bar.ant-tabs-left-bar {
    background: rgba(231, 235, 237, 0.5);
    width: 95px;
    margin-left: -8px;
    /* margin-top: 70px; */
    /* padding: 8px 0px; */
    border-radius: 3px;
}
.ant-tabs.menuTabs > .ant-tabs-bar > .ant-tabs-nav-container > .ant-tabs-nav-wrap > .ant-tabs-nav-scroll > .ant-tabs-nav > div > .ant-tabs-tab {
    padding: 0;
    margin-bottom: 18px; 
}
.ant-tabs.menuTabs .subMenu-tab {
    font-size: 15px;
    color: #5F5A60;
    font-weight: 700;
    padding: 6px 5px;
    width: 70px;
    background: #F0EFEF;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    margin: 15px 12px 15px auto;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ant-tabs.menuTabs .ant-tabs-ink-bar {
    display: none !important;
}
.ant-tabs.menuTabs .ant-tabs-tab.ant-tabs-tab-active .subMenu-tab {
    background: var(--problue);
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    color: white;
}
/* .ant-tabs.ant-tabs-left.FFE-open.menuTabs > .ant-tabs-bar {background: linear-gradient(180deg, rgba(125, 169, 209, 0.3) 50%, rgba(231, 235, 237, 0.5) 50%, rgba(231, 235, 237, 0.5) );} */
/* .ant-tabs.ant-tabs-left.OSE-open.menuTabs .ant-tabs-bar {background: linear-gradient(180deg, rgba(231, 235, 237, 0.5) 50%, rgba(125, 169, 209, 0.3) 50%, rgba(125, 169, 209, 0.3) );} */
.slick-vertical .slick-slide {height: 50px;transition: all 0.3s ease;}
.tabName {
    position: absolute;
    margin-top: 45px;
    font-size: 15px;
    font-weight: 600;
    width: 185px;
    text-align: right;
    color: #5F5A60;
    margin-left: -83px;
}
@media screen and (max-width: 1440px) {
    .verSlider-outer {margin-left: 15px;}
    /* .ant-tabs.menuTabs .ant-tabs-bar.ant-tabs-left-bar {margin-left: -45px;} */
}

@media screen and (max-height: 870px) {
    header.ant-layout-header .header-logo {
      max-width: 190px;
      margin-top: -30px;
    }
/*     .fixed-height-pannel-scroll .ant-tabs-tabpane {
      height: calc(100vh - 175px);
    }
    .fixed-height-pannel-scroll .ant-tabs-tabpane .ant-tabs-tabpane-active {
        height: auto;
    } */
    .verSlider-sectn .sidebarMenuTitle {margin: 0px 0 15px 38px;}
    /* .ant-tabs.menuTabs div.ant-tabs-bar.ant-tabs-left-bar {margin-top: 40px;} */
    .verSlider-sectn .verSlider-outer::before {background-position: 0px -60px;left: -10px;}
    /* .layout-style {padding: 0px 0px 0px;} */
  }
  @media screen and (max-height: 800px) {
    .layout-style {padding: 0px 0px 0px;}
    header.ant-layout-header .header-logo {
      margin-top: -55px;
      max-width: 146px;
      margin-left: 130px;
    }
    .verSlider-outer::before {
      background-position: top -45px center;
    }
    main.ant-layout-content.main-content-area {
      padding: 0px 16px 0 !important;
    }
    .verSliderContent .ant-tabs.menuTabs .subMenuTitle {margin: 9px 11px 7px auto;}
/*     .fixed-height-pannel-scroll .ant-tabs-tabpane {
      height: calc(97vh - 120px);
    }
    .fixed-height-pannel-scroll .ant-tabs-tabpane .ant-tabs-tabpane-active {
        height: auto;
    } */
    .verSliderContent .ant-tabs.menuTabs .ant-tabs-bar.ant-tabs-left-bar {
      padding: 0px;
    }
    .verSliderContent .ant-tabs.menuTabs > .ant-tabs-bar > .ant-tabs-nav-container > .ant-tabs-nav-wrap > .ant-tabs-nav-scroll > .ant-tabs-nav > div > .ant-tabs-tab {
      margin-bottom: 7px;
    }
    .verSliderContent .ant-tabs.menuTabs .subMenu-tab {
      margin: 8px 11px 15px auto;padding: 5px 5px 2px;
    }
    .verSliderContent > .ant-tabs > .ant-tabs-content {
      padding-left: 13px;
    }
    .verSlider-sectn .verSlider-outer::before {
      background-position: 0px -212px;
      top: 0px;
      left: -45px;
    }
    .slick-slide span {
      text-decoration: none;
    }
    .slick-vertical .slick-track .slick-slide {
      height: 42px;
    }
    .verSlider-curve .slick-slider.verSlider-inner.slick-vertical:not(.verSlider-small) .slick-list {
      height: 462px !important;
    }
    .verSlider-curve .slick-slider.verSlider-inner.slick-vertical {
      height: 462px;
    }
    .verSlider-curve .slick-arrow.slick-prev {
      top: -20px;
    }
    .verSlider-curve .slick-arrow.slick-next {
      bottom: -20px;
    }
    .verSlider-sectn .verSlider-outer {
      padding: 35px 15px;
      background-image: url(/static/media/CurveSmallTab.10220af9.svg);
    }
    .verSlider-sectn .sidebarMenuTitle {
      margin: 0px 0 10px 33px;
      font-size: 15px;
      line-height: 19px;
    }
    .verSliderDiv {
      margin-top: -35px;
    }
    .verSlider-outer .slick-slide span {
      text-decoration: none;
    }
    .ant-tabs.menuTabs div.ant-tabs-bar.ant-tabs-left-bar {
      margin-top: -5px;
      /* margin-left: -85px; */
    }
    .verSliderContent .titleTop {
      margin: 36px 0;
    }
    
    .verSlider-curve .slick-slider.verSlider-inner.slick-vertical.verSlider-small-10 .slick-list {
        height: 462px !important;
        padding-top: 42px !important;
    }
    .verSlider-curve .slick-slider.verSlider-inner.slick-vertical.verSlider-small-9 .slick-list {
        height: 378px !important;
    }
    .verSlider-curve .slick-slider.verSlider-inner.slick-vertical.verSlider-small-8 .slick-list {
        height: 378px !important;
        padding-top: 42px !important;
    }
    .verSlider-curve .slick-slider.verSlider-inner.slick-vertical.verSlider-small-7 .slick-list {
        height: 294px !important;
    }
    .verSlider-curve .slick-slider.verSlider-inner.slick-vertical.verSlider-small-6 .slick-list {
        height: 294px !important;
        padding-top: 42px !important;
    }
    .verSlider-curve .slick-slider.verSlider-inner.slick-vertical.verSlider-small-5 .slick-list {
        height: 210px !important;
    }
    .verSlider-curve .slick-slider.verSlider-inner.slick-vertical.verSlider-small-4 .slick-list {
        height: 210px !important;
        padding-top: 42px !important;
    }
    .verSlider-curve .slick-slider.verSlider-inner.slick-vertical.verSlider-small-3 .slick-list,
    .verSlider-curve .slick-slider.verSlider-inner.slick-vertical.verSlider-small-1 .slick-list {
        height: 126px !important;
    }
    .verSlider-curve .slick-slider.verSlider-inner.slick-vertical.verSlider-small-2 .slick-list,
    .verSlider-curve .slick-slider.verSlider-inner.slick-vertical.verSlider-small-0 .slick-list {
        height: 126px !important;
        padding-top: 42px !important;
    }
    .slick-slide span {
        max-width: 117px;
    }
    .slick-slide.slick-active.slick-current span {
        max-width: 155px;
    }    
    li.ant-menu-item.gap-left {
        padding-left: 28px;
    }
    li.ant-menu-item.gap-right {
        padding-right: 28px;
    }
  }
  @media screen and (min-width: 1441px) and (max-height: 800px) {
    .verSlider-sectn .verSlider-outer {margin-left: 15px;}
  }
  
  
