.field-border{
    border: grey;
}

.example {
    text-align: center;
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 30px 50px;
    margin: 20px 0;
}

.ant-checkbox-group-item {
    padding: 6px;
    display: block;
}
.pqf-header{
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #5F5A60;
}
.pqf-inner-card{
    background: rgba(214, 244, 252, 0.25);
    border-radius: 10px;
}
.pqf-outer-card{
    background: #F0EFEF;
    border-radius: 6px;
    /* height: 500px;
    overflow-y: scroll; */
}
/* .pqf-outer-card::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  
  .pqf-outer-card::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  } */
.pqf-title{
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 27px;
    color: var(--probluedarker);
    text-transform: uppercase;
}
.pqf-input-box{
    width: 100%;
    background: #FFFFFF;
    opacity: 0.5;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    height: 28px;
    color: black;
}
/* .ant-input:placeholder-shown{
    font-size: 12px !important;
} */
/* .ant-input-number-input{
    font-size: 12px !important;
} */
.pqf-item-mb{
    margin-bottom: 5px !important;
}
/* .pqf-labels{
    text-align: left !important;
} */
.ant-form-item-label{
    text-align: left !important;
    color: #000000 !important;
    font-size: 12px !important;
    padding: 0 !important;
}
.mb-20{
    margin-bottom: 20px;
}
.item-mb{
    margin-bottom: 10px !important;
}
.btn-success{
    background: #63AA4A;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}
.radio-btns{
    background: rgba(255, 255, 255, 0.43);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px !important;
    width: 84px;
    height: 23px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px !important;
    line-height: 18px;
    color: #9D9E9E;
    text-align: center;
    margin-left: 10px;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before{
    background-color:transparent !important;
}
.ant-radio-button-wrapper:not(:first-child)::before{
    background-color: transparent !important;
}
/* .view-revisions{
    text-align: center;
} */
#customers {
    border-collapse: collapse;
    width: 100%;
    text-align: center;
  }
  
  #customers td, #customers th {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  #customers tr:nth-child(even){background-color: #f2f2f2;}
  
  #customers tr:hover {background-color: #ddd;}
  
  #customers th {
    background-color: var(--probluedarker);
    color: white;
  }
  .health-club-card{
    background: #F7FDFE;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    margin-bottom: 20px;
  }
  .checkbox-label-style-2{
    display: inline-flex;
  }
  .checkbox-label-style-2 .ant-checkbox{
    margin-top: 5px;
  }
  .pqf-col-pl-30{
    padding-left: 30px;
  }
  .dragger-height{
      height: 50vh;
  }
  .link-btn{
    line-height: 1.5715;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    border: 1px solid transparent;
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
    cursor: pointer;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    height: 32px;
    padding: 4px 15px;
    font-size: 14px;
    border-radius: 2px;
    color: rgba(0, 0, 0, 0.65);
    background-color: #fff;
    border-color: #d9d9d9;
  }

  .pqf-form-style .ant-form-item-label > label{
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 16px;
    /* text-align: right; */
    color: #000000;
  }

  .pqf-form-style .ant-divider-horizontal.ant-divider-with-text::before{
    border-top: 1px solid #5F5A60;
  }
  .pqf-form-style .ant-divider-horizontal.ant-divider-with-text-right::after{
    display: none;
  }
  .pqfOSE-form-style .ant-divider-horizontal.ant-divider-with-text-left::after{
    border-top: 1px solid #5F5A60;
  }
  .pqfOSE-form-style .ant-divider-horizontal.ant-divider-with-text-left::before{
    display: none;
  }
  .pqfOSE-form-style .ant-divider-inner-text{
    padding-left: 0;
  }

  .notice-box{
    background: #9DBCD9;
    border-radius: 3px 0px 0px 3px;
    width: 257px;
  }

  .centered-notice-items{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
  }

  .bill-ship-form-width{
    max-width: 455px;
    margin: auto;
  }
.bill-ship-block-btn2{
  padding-right: 0px;
  color: var(--probluedarker);
  font-style: normal;
  font-weight: 600;
  /* font-size: 12px; */
}
  .bill-ship-block-btn{
    height: 63px;
    text-align: right;
    background: #F0F6F8;
    border: transparent;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    color: var(--probluedarker);
    padding-right: 67px;
  }

  .bill-ship-block-btn:hover,
  .bill-ship-block-btn:active,
  .bill-ship-block-btn:focus
  {
    height: 63px;
    text-align: right;
    background: #F0F6F8;
    border: transparent;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    color: var(--probluedarker);
    padding-right: 67px;
  }
  .list-temp-modal .ant-modal-body {
    background: linear-gradient(90deg,transparent 4.5%, rgba(230, 241, 243, 0.5) 4.5%, rgba(230, 241, 243, 0.5) 95.5%, transparent 95.5%);
    padding-left: 8%;
    padding-right: 8%;
    margin-bottom: 30px;
  }
  .version-modal .ant-modal-body {
    background: linear-gradient(90deg,transparent 3.5%, rgba(230, 241, 243, 0.5) 3.5%, rgba(230, 241, 243, 0.5) 96.5%, transparent 96.5%);
    padding-left: 6%;
    padding-right: 6%;
    margin-bottom: 10px;
    padding-bottom: 5px;
  }
  .boq-modal .ant-modal-body {
    margin-bottom: 30px;
  }
.list-temp-modal .ant-modal-content, .boq-modal .ant-modal-content {
  padding-bottom: 5px;
}
.list-temp-modal:not(.comment-modal) .ant-modal-body .ant-form-item, .version-modal .ant-modal-body .ant-form-item {
  margin-bottom: 0;
}
.bill-cross {text-align: right;}
.copyAddress-icon {
  color: var(--probluedarker);
  font-size: 25px;
  position: absolute;
  margin-left: 5px;
  margin-top: 45px;
  z-index: 10;
}
.ant-picker.date-style {
  width: 100%;
  background: #FFFFFF;
  opacity: 0.5;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  height: 28px;
  color: black;
}
.ant-picker.date-style .ant-picker-input > input {
  color: black;
}
.dc-inp-num-hide-controls{
  width: 100%;
}
.dc-inp-num-hide-controls .ant-input-number-handler-wrap{
  visibility: hidden;
}
.picker-translucent .ant-picker,.picker-translucent .ant-picker-focused{
  background:none;
  border: none;
  box-shadow:none;
}