@import url('https://fonts.googleapis.com/css2?family=Signika+Negative:wght@300;400;600;700&display=swap');
*{
  font-family: Signika Negative;
}
body {
  margin: 0;
  font-family: 'Signika Negative', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a{
  color: var(--probluedarker) !important;
}
/* Themes */
:root {
  --problue: #88B7E2;
  --probluelight: #D8E5F1;
  --probluedark: #80ADD6;
  --probluedarker: #316EA6;
  --paleblue: rgb(217, 226, 243);
  --paleorange: rgb(255, 242, 204);
  --palegreen: rgb(226, 239, 217);
  --palered: rgb(247, 202, 172);
  --palepurple: rgb(229, 214, 241);
  --scrollbarColor:#87B6E2;
}