.header-boq{
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 27px;
    color: var(--probluedarker);
}
.boq-header-label{
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 17px;
  color: #3370A8;
}
.boq-header-label-2{
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 17px;
  color: #000000;
}
.boq-outer-card{
    background: #F0EFEF;
    border-radius: 6px 6px 0px 0px;
}
.boq-outer-card-2{
  background: #F6F9F9;
  border-radius: 6px;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active{
    background: var(--problue) !important;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    border-color: var(--problue);
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab{
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    background: #F1F0F0;
    border: none;
    padding: 4px 16px;
    margin-right: 5px;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab:first-child {
    border-radius: 5px 0px 0px 0px;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab:last-child,
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab:last-child .tab-form-progress::after {
    border-radius: 0px 6px 0px 0px;
}
.boq-table {
    border-collapse: collapse;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 10px 10px 0px 0px;
  }
  .boq-table-header{
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.12), -4px 4px 4px rgba(0, 0, 0, 0.05);
  }
  
  .boq-table td{
    border-right: 1px solid #ddd;
    padding: 8px;
    height: 65px;
  }
  
  .boq-table tr:nth-child(odd){
    background: #E7ECEE;
    opacity: 0.8;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  }
  
  .boq-table tr:hover {background-color: #ddd;}
  
  .boq-table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    color: var(--probluedarker);
    border-right: 1px solid #525252;
    padding: 8px;
    height: 33px;
    background: #FFFFFF;
  }
  .boq-table th:first-child {
    border-radius: 5px 0px 0px 5px;
  }
  .boq-table th:last-child {
    border-radius: 0px 5px 5px 0px;
    border-right:none;
  }
  .boq-table-header{
    background: #F1F0F0;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.12), -4px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
  }
/*   .table-responsive-boq{
      overflow-y: scroll;
  }
  .table-responsive-boq::-webkit-scrollbar {
    height: 7px;
    width: 7px;
    border-radius: 5px;
  }
  
  .table-responsive-boq::-webkit-scrollbar-thumb {
    background: var(--scrollbarColor);
    border-radius: 5px;
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  } */
  /*ant table start*/
  .ant-table-thead{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  }
  .ant-table-thead > tr > th{
    background: #F1F0F0;
    text-align: center !important;
    border-right: 1px solid #bebebe;
    padding: 0px 5px;
    height: 26px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    /* text-decoration-line: underline; */
    color: #5F5A60;
  }
  .ant-table-container table > thead > tr:first-child th:first-child{
    border-radius: 5px 0px 0px 0px;
    border-color: #F1F0F0;
  }
  .ant-table-container table > thead > tr:first-child th:last-child{
    border-radius: 0px 5px 0px 0px;
    border-color: #F1F0F0;
  }
  .ant-table-row:nth-child(odd){
    background: #E7ECEE;
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05); */
  }
  .ant-table.ant-table-bordered thead > tr > th, 
  .ant-table.ant-table-bordered tbody > tr > td, 
  .ant-table.ant-table-bordered tfoot > tr > th, 
  .ant-table.ant-table-bordered tfoot > tr > td {
    border-right: 1px solid rgb(0 0 0 / 0.1);
  }
  .ant-table-tbody > tr > td{
    border-right: 1px solid #ddd;
    padding: 6px 8px;
    height: 33px;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;

    color: #857E7E;
  }
  td.ant-table-column-sort{
    background: transparent !important;
  }
  .ant-table-column-sorters{
    padding: 0px;
  }
  /*ant table end*/

  /*comment btn start*/
  .comment-btn{
    border: 0px;
    background: transparent;
  }
  .comment-btn:hover{
    background: transparent;
  }
  .comment-btn:active{
    background: transparent;
  }
  .comment-btn:focus{
    background: transparent;
  }
  /*comment btn end*/
  .content-right{
    text-align: right;
  }
  .black-color .ant-table-filter-trigger{
    color: black;
  }
  /*auto search start*/
  .certain-category-search-dropdown .ant-select-dropdown-menu-item-group-title {
    color: #666;
    font-weight: bold;
  }
  
  .certain-category-search-dropdown .ant-select-dropdown-menu-item-group {
    border-bottom: 1px solid #f6f6f6;
  }
  
  .certain-category-search-dropdown .ant-select-dropdown-menu-item {
    padding-left: 16px;
  }
  
  .certain-category-search-dropdown .ant-select-dropdown-menu-item.show-all {
    text-align: center;
    cursor: default;
  }
  
  .certain-category-search-dropdown .ant-select-dropdown-menu {
    max-height: 300px;
  }
  .col-border{
    border:1px solid black;
  }
  .col-color{
    background: #028ac8;
    color: white !important;
  }
  .comment-modal textarea {width: 100%;}
  /* .boq-mngmt-search .ant-select-selector {
    min-width: 190px;
  } */
  .boq-mngmt-search {
    width: 75%;
    display: flex;
  }
  .boq-mngmt-search .ant-form {
    display: flex;
    column-gap: 6px;
    width: 100%;
  }
  .filter-btn button.ant-btn.grey-btn {
    width: 35px;
    min-width: initial;
    padding: 5px 5px 3px;
  }
  .ant-row.boq-mngmt-filter {
    display: flex;
    flex-wrap: nowrap;
    column-gap: 2px;
    align-items: start;
    justify-content: space-between;
  }
  .grey-btn-outer.filter-btn {
    margin-left: 2px;
  }
  .boq-mngmt-search > .ant-form > *:not(:last-child) {
    width: 100%;
  }
  .ant-dropdown.ant-dropdown-placement-bottomRight {
    z-index: 999 !important;
  }
  .spec-ID .ant-select-selection-item .anticon {
    display: none;  
  }
  .boq-mgmt-table th.ant-table-cell:not(:first-child):not(:last-child):not(:nth-last-child(2)), 
  .boq-mgmt-table td.ant-table-cell:not(:first-child):not(:last-child) {
    min-width: 140px;width: 140px;
  } 
  .boq-mgmt-table .ant-table-cell .ant-space, 
  .boq-mgmt-table .ant-table-cell .ant-space-item, 
  .boq-mgmt-table .ant-table-cell .ant-select {
      max-width: 100%;
      padding: 0 3px 0 0;
  }
  .boq-mgmt-table .ant-table-cell:not(:last-child) .ant-space-item .anticon.anticon-comment {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
  }
  .boq-mgmt-table .ant-table-cell:last-child .ant-space-item .anticon.anticon-comment {
    font-size: 18px;
  }
  .hide-empty-rows.boq-mgmt-table .ant-table-body {
    max-height: calc(100vh - 375px) !important;
  }
  .hide-empty-rows.boq-mgmt-table.boq-coordinator-table .ant-table-body {
    max-height: calc(100vh - 445px) !important;
  }
  .boq-search-table .ant-table-content::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 7px;
    background: #C4C4C4;
    /* border-radius: 5px; */
  }
  
  .boq-search-table .ant-table-content::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: var(--scrollbarColor);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }
  .hide-empty-rows.boq-mgmt-table .ant-table-body {
      padding-right: 0px;
      margin-right: -7px;
  }
  .boq-mngmt-search .ant-select-multiple .ant-select-selector {
    flex-wrap: nowrap;
    overflow: hidden;
  }
  .boq-mngmt-search .ant-form .ant-form-item:not(:last-child) {
    min-width: 20%;
    max-width: calc(33.33% - 17px);
  }
  /*auto search end*/
  /*Selection column start*/
/*   .ant-table th.ant-table-selection-column{
    width: 50%;
  } */
  .boq-mngmt-btns {
    display: flex;
    align-items: center;
    column-gap: 10px;
    justify-content: flex-end;
  }
  .boq-mngmt-btns .anticon-comment {
    font-size: 18px;
    color: #9D9E9E;
  }
  .grey-btn .ant-btn[disabled] {
    border: transparent;
  }
  .boq-details .specification-table .ant-table-body {
    max-height: calc(100vh - 375px) !important;
  }
  td.ant-table-cell.boq-action-outer div, td.ant-table-cell.uom-col div{
    width: 100%;
    padding-right: 0 !important;
  }
  .boq-action-outer .boq-action {
    display: flex;
    justify-content: space-around;
  }
  .boq-modal .ant-comment-content-author-name {
    color: rgb(48 109 165);
    text-transform: capitalize;
  }
  .boq-modal .ant-comment-inner {
    border: 1px solid rgb(48 109 165 / 0.1);
    padding: 10px 0;
    margin-bottom: 15px;
    border-radius: 0 20px 20px 20px;
  }
  .boq-modal .ant-comment-nested {
    margin-left: 24px;
  }
  .ant-tabs.ant-tabs-card.boq-mgmt-tab-1 .ant-tabs-card-bar .ant-tabs-tab {
    padding: 4px 10px 0px;
    font-size: 14px;
    font-weight: 400;
  }
  .progress-slider .ant-slider-rail {background-color: #c4c4c4;}
  .progress-slider.ant-slider-disabled .ant-slider-track {
    background-color: rgb(49 110 166 / 0.5) !important;
  }
  .progress-slider .ant-slider-track {
    background-color: var(--probluedarker);
  }
  .disabled-px-0 .ant-btn{
    padding-left: 0px;
    padding-right: 0px;
  }
  .dc-boq-filter-hide-pannel{overflow: hidden;}
  .comment-present{color: #0085ff !important;font-size: 18px;}
  .position-relative{position: relative;}
  .ant-row.boq-mngmt-filter .ant-col {width: 100%;}
  .comment-box-height{
    max-height: 50vh;
    margin-bottom: 10px;
  }
  .comment-box-height{
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: thin;
  }
  .comment-box-height::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
    height: 5px;
  }
  
  .comment-box-height::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(230, 242, 253, 0.8);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }
  @media screen and (max-width: 1799px) {
    .ant-row.boq-mngmt-filter {flex-wrap: wrap;}
    /* .ant-row.boq-mngmt-filter .ant-col {width: 100%;} */
    .ant-row.boq-mngmt-filter .grey-btn-outer {float: right;}
    .hide-empty-rows.boq-mgmt-table .ant-table-body {
      max-height: calc(100vh - 415px) !important;
    }
    .hide-empty-rows.boq-mgmt-table.boq-coordinator-table .ant-table-body {
      max-height: calc(100vh - 485px) !important;
    }
    .boq-mgmt-table th.ant-table-cell.scrollRight::before {
      width: 53px;
      right: -20px;
    }
  }
  @media screen and (max-height: 870px) {
    .hide-empty-rows.boq-mgmt-table .ant-table-body {
      max-height: calc(100vh - 320px) !important;
    }
    .hide-empty-rows.boq-mgmt-table.boq-coordinator-table .ant-table-body {
      max-height: calc(100vh - 390px) !important;
    }
    .boq-details .specification-table .ant-table-body {
      max-height: calc(100vh - 335px) !important;
    }
  }
  @media screen and (max-width: 1440px) and (max-height: 870px)  {
    .hide-empty-rows.boq-mgmt-table .ant-table-body {
      max-height: calc(100vh - 375px) !important;
    }
    .hide-empty-rows.boq-mgmt-table.boq-coordinator-table .ant-table-body {
      max-height: calc(100vh - 445px) !important;
    }
    .boq-details .specification-table .ant-table-body {
      max-height: calc(100vh - 390px) !important;
    }
  }
  @media screen and (max-height: 800px) {
    .hide-empty-rows.boq-mgmt-table .ant-table-body {
      max-height: calc(100vh - 265px) !important;
    }
    .hide-empty-rows.boq-mgmt-table.boq-coordinator-table .ant-table-body {
      max-height: calc(100vh - 335px) !important;
    }
    .boq-details .specification-table .ant-table-body {
      max-height: calc(100vh - 290px) !important;
    }
  }
  @media screen and (max-width: 1440px) and (max-height: 800px)  {
    .hide-empty-rows.boq-mgmt-table .ant-table-body {
      max-height: calc(100vh - 320px) !important;
    }
    .hide-empty-rows.boq-mgmt-table.boq-coordinator-table .ant-table-body {
      max-height: calc(100vh - 390px) !important;
    }
    .boq-details .specification-table .ant-table-body {
      max-height: calc(100vh - 280px) !important;
    }
    .my-custom-cell.wj-cell .my-icon {
      cursor: pointer;
    }
    /* .my-custom-cell.wj-cell {
      padding: 3px;
    } */
    
    .my-custom-cell.wj-cell > div {
      display: flex;
      justify-content: space-between;
    }
  }