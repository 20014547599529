.scrollTabs .ant-tabs-bar.ant-tabs-left-bar {
    display: none;
}
/* .inp-matrix-table {padding-right: 17px;} */
.inp-matrix-table + .pagination-display {
    bottom: 37px;
}
.osne-fileupload .project-search-outer.projCat-search-outer {
    margin: 0;
}

/*Typography start*/
.proj-name{
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    color: #000000;

    margin-bottom: 5px;
}
.black-label-2{
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
  }
.gray-label-1{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #857E7E;
}
.gray-label-2{
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #9D9E9E;
}
.gray-label-3{
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 21px;
    color: #9D9E9E !important;
}
.gray-label-3-pale{
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #9D9E9E !important;
}
.gray-label-4-dark{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #5F5A60 !important;
}
.gray-label-5-dark-uppercase{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #5F5A60;
    text-transform: uppercase;
}
.gray-label-6-dark{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #5F5A60;
}
.blue-label-1-sm{
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 14px;
    color: #3C7AB4;
}
.blue-label-3-md{
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    color: #3C7AB4;
}
.blue-label-4-pale{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #3C7AB4;
}
.blue-label-5-pale{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #3C7AB4;
}
.blue-label-5-dark{
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #3370A8;
}
.blue-label-6-md-dark{
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: var(--probluedarker);
}
.red-label-1{
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 34px;
    color: #FF5722;
}
.green-label-1{
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 34px;
    color: green;
}
.deviation-label-1{
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 34px;
    color: #3a3a3a;
}
.ellipse-dots{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
/* typography end */
/* Alignment & positions start */
.justify-center{
    display: flex;
    justify-content: center;
}
.pull-bottom{
    position: absolute;
    bottom: 0;
    width: 100%;
}
.vertical-center{
    text-align: center;
    margin-top: 25%;
}
/* Alignment & positions end */
/* chart styling start*/
svg > g > g:last-child { pointer-events: none }
.chart-1-info-box-1{
    background: #C4C4C4;
    border-radius: 2px 0px 0px 2px;
    text-align: center;
    padding: 5px 0 5px 0;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #FFFFFF;
}
.chart-1-info-box-2{
    border: 2px solid #ACACAC;
    box-sizing: border-box;
    border-radius: 2px;
    border-top: none;
    text-align: center;
    padding: 10px 0 10px 0;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #ACACAC;
}

.chart-2-info-box-1{
    background: #8BADCD;
    border-radius: 2px 0px 0px 2px;
    text-align: center;
    padding: 5px 0 5px 0;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #FFFFFF;
}
.chart-2-info-box-2{
    border: 2px solid #8BADCD;
    box-sizing: border-box;
    border-radius: 2px;
    border-top: none;
    text-align: center;
    padding: 10px 0 10px 0;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #99B8D4;
}
/* chart styling end*/