.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
.selection-col-width colgroup col:first-child {
  width: 40px;
  min-width: 40px;
}
.vendor-heading {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: var(--probluedarker);
  margin-bottom: 20px;
}
.outer-vendor-card {
  background: #fbfbfb;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}
.inner-vender-card {
  background: #f7fdfe;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
}
.inner-vender-card .ant-card-body {
  padding-top: 10px;
}
.question_key_styling {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #3370a8;
  margin-right: 15px;
  word-break: break-word;
}
.question_styling {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  /* background-color: #f7fdfe; */
}
.action-btn-col-padding {
  float: right;
  margin-top: 20px;
}
.action-btn-padding {
  padding: 10px;
}
.pts-style {
  margin-left: 30px;
  color: rgba(0, 0, 0, 0.65);
}
.pts-input-style {
  width: 25%;
  height: 20px;
}
.radio-checkbox-style {
  display: block !important;
  height: 30px;
  line-height: 30px;
}
.checkbox-first {
  margin-left: 8px;
}

.possible-score {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #9d9e9e;
}
.poss-score-col {
  padding-left: 15px;
}
.weightage-style {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-top: 15px;
}
.vsm-input {
  width: 30%;
}
.vsm-close-btn {
  text-align: right;
}
.close-icon-style {
  color: var(--probluedarker);
}
.minus-style {
  margin-left: 10px;
}
.vsm-my-5 {
  margin: 5px 0px 5px 0px;
}
.vsm-mt-20 {
  margin-top: 20px;
}
.vsm-center {
  text-align: center;
}
.vsm-btn-right {
  text-align: right;
  padding: 20px;
}
.vsm-btn {
  text-align: left;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #857e7e;
}
.vsm-pb-10 {
  padding-bottom: 10px;
}
.vsm-mt-5 {
  margin-top: 5px;
}
.vsm-radio-stack {
  display: inline-block;
  height: 30px;
  line-height: 30px;
}
.add-quest {
  color: var(--probluedarker);
  margin-right: 10px;
}
.vsm-radio-stack span.ant-radio {
  display: none;
}
.icon-left .anticon-paper-clip {
  left: 0;
}
.ant-upload-list-item-error,
.ant-upload-list-item-error .ant-upload-text-icon > .anticon,
.ant-upload-list-item-error .ant-upload-list-item-name {
  left: 0;
}
.edit-criteria-form .criteria-inner input {
  width: 64px;
  height: 22px;
}
.benchmark-field .ant-input-number-handler-wrap {
  display: none;
}

/* .edit-criteria-form {
  padding: 10px 19px 10px 10px;
} */
.edit-criteria-form .ant-collapse .ant-collapse-header {
  padding: 6px 25px;
  padding-left: 40px;
  color: #5f5a60;
}
.edit-criteria-form .ant-row:nth-child(even) .ant-collapse-header {
  background: #e7ecee;
}
.edit-criteria-form .ant-collapse,
.edit-criteria-form .ant-row .ant-collapse-item,
.edit-criteria-form .ant-row .ant-collapse-content.ant-collapse-content-active {
  border: none;
}
/* .edit-criteria-form .possible-score input {
  width: calc(100% - 100px);
} */
.ant-row.add-criteria-form {
  padding: 10px 19px 0px 10px;
  width: 100%;
}
.edit-criteria-form .possible-score {
  display: flex;
  gap: 5px;
  font-size: 12px;
  font-weight: 300;
  align-items: center;
}
.edit-criteria-form
  .ant-collapse
  .ant-collapse-item-active
  .ant-collapse-header {
  color: var(--probluedarker);
}
.edit-criteria-form .ant-row:nth-child(odd) .ant-collapse {
  background: #f5f7f7;
}
.edit-criteria-form .ant-collapse .ant-collapse-content {
  background: transparent;
  padding-bottom: 7px;
}
.edit-criteria-form .ant-collapse .ant-collapse-content-box {
  padding: 0;
}
.add-more-col button.ant-btn {
  padding: 0 4px;
  height: 22px;
  font-size: 12px;
}
.ant-col.add-more-col {
  width: 75px;
}
.criteria-inner .ant-col.poss-score-col {
  padding-left: 10px;
  width: 144px;
  padding-right: 5px;
}
.ant-col.criteria-first-col {
  width: calc(100% - 220px);
}
.cust-collapse-icon {
  width: 30px;
  display: inline-flex !important;
  align-items: center;
}
.cust-collapse-content {
  width: calc(100% - 30px);
}
.options-to {
  display: flex;
  justify-content: space-between;
  gap: 5px;
}
.ant-col.criteria-first-col .options-outer {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-evenly;
}
.score-inner {
  display: flex;
}
.ant-col.criteria-first-col .ant-radio-group {
  width: 100%;
}
.edit-criteria-form .ant-collapse .ant-collapse-extra {
  display: none;
}
.edit-criteria-form
  .ant-collapse.assigned-questions
  .ant-collapse-item-active
  .ant-collapse-extra {
  display: block;
}
.edit-criteria-form .criteria-inner {
  padding: 0 10px;
}
.criteria-first-col .ops-name-inner .pts-style span {
  padding-right: 5px;
}
.ant-col.criteria-first-col .options-outer .ops-name-inner {
  text-align: right;
  width: 180px;
  color: #9d9e9e;
  font-weight: normal;
}
.ant-col.criteria-first-col .options-outer .ops-name-inner span span {
  padding-right: 5px;
  font-size: 12px;
  font-weight: 300;
}
.add-criteria-form input.ant-input {
  font-size: 12px;
}
.edit-criteria-form {
  padding-top: 5px;
}
.add-vendor-btn {
  box-shadow: none;
  width: unset;
  border: transparent;
  box-shadow: -1px 5px 9px -5px rgba(0, 0, 0, 0.5);
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  height: 32px;
}
button.ant-btn.close-edit-criteria {
  font-size: 18px;
  margin-top: -7px;
  margin-right: -9px;
}
.vendor-score-sectn {
  margin-top: 37px;
}
.edit-delete-inner {
  gap: 5px;
  padding-left: 7px;
}
.vendor-score-sectn .medium-sub-text {
  margin-bottom: 0;
}
button.ant-btn.save-btn2:hover {
  background: #f1f0f0;
  border: none;
  color: #5f5a60;
}
.ant-collapse.unassigned-questions {
  opacity: 0.7;
}
.range-error {
  color: red;
  margin-left: 20px;
  padding: 5px;
  display: block;
}
.scoring-criteria {
  padding-top: 0px !important;
}
.rebate-checkbox {
  float: left;
  flex-direction: unset !important;
  gap: 12px;
}
.rebate-checkbox .ant-form-item-label {
  order: 2;
}
.rebate-checkbox .ant-form-item-control {
  order: 1;
}
.vendor-modal select#createVendor_country,
.vendor-modal select#createVendor_State,
select#ProjectItem_project_location {
  padding: 4px 11px;
  outline: none;
  border: 1px solid #d9d9d9;
}
.vendor-modal select#createVendor_country:focus,
.vendor-modal select#createVendor_State:focus,
select#ProjectItem_project_location:focus {
  border: 1px solid #40a9ff;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.vendor-name{
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 20px;
color: #5F5A60;
}
.card-900{
  width: 900px;
}

/* added for vendorlandingpage */
.welcome-vendor-card {
  text-align: center;
  padding: 10vh 5vh;
  border-radius: 0;
  background: rgba(240, 246, 249, 0.6);
}
.dc-select-proj{
  width: 269px;
}
.dc-select-vendor{width: 272px;}
.dc-select-vendor .ant-select-selection-item:nth-child(-n + 2){width: 84px;}
.custom-extra-content-left{
  position: absolute;
  top: 0;
  left: 15px;
}
.grey-tick svg{stroke: gray; fill: gray;}
.orange-tick svg{stroke: orange; fill: orange;}
.green-tick svg{stroke: green; fill: green;}
.red-tick svg{stroke: red; fill: red;}
.pro-comment-badge{
  color: white;
  position: absolute !important;
  left: 0;
  top: 3px;
  font-size: 11px;
}
.vendor-notice-title{
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #5F5A60;
}
.vendor-notice-outer .ant-modal-body{padding: unset;}
.vendor-notice-outer .ant-modal-header{background: unset;}
.vendor-notice-outer .ant-modal-title{text-align: left;}
.vendor-notice-outer .ant-modal-body{background:unset;padding-left:unset;padding-right:unset;}
.vendor-notice-text{
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: #5F5A60;
}
.warning-msg{
  color: red;
}
.dc-vendor-tabs .ant-tabs-extra-content{padding: 0px 15px;}
.dc-vendor-tabs .ant-tabs-tab:first-child{display: none;}
.display-hidden-child .ant-tabs-tab:first-child{display: inline-block;}
.vendlist-actions{
  width: 50%;
  position: absolute;
  top: -40px;
}
.ant-upload-list-item .ant-progress{display: none;}
.ant-upload-list-item-error, .ant-upload-list-item-error .ant-upload-text-icon > .anticon, .ant-upload-list-item-error .ant-upload-list-item-name{color: #5F5A60;}
.pcs-inp .ant-select-selector{
  max-height: 60px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.pcs-inp .ant-select-selector{
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: thin;
}
.pcs-inp .ant-select-selector::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
  height: 5px;
}

.pcs-inp .ant-select-selector::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: var(--problue);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
.move {cursor: move;}
.fake-inp{
  background: #f5f5f5;
  box-shadow: 4px 4px 10px rgb(0 0 0 / 5%), inset 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 3px !important;
  border-style: solid;
  border-width: 1px;
  border-color: #bfbfbf;
  min-height: 28px;
  padding: 2px 7px;
  cursor: not-allowed;
}
.fake-inp{
  max-height: 36px;
  overflow-y: scroll;
  scrollbar-width: thin;
}
.fake-inp::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
  height: 5px;
}
.fake-inp::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: var(--scrollbarColor);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
.chamfered-box{
  border-radius: 5px;
  overflow: hidden;
  border: 0.1px solid #838383;
  background-color: #f2f2f2;
}
.gray-border-left{border-left: 0.1px solid #838383;}
.issue-rfp-upload .ant-upload-list{
  position: absolute;
  right: 17px;
  bottom: 58px;
}
.issue-rfp-upload .ant-upload-list-item-name{width: 203px;}
.inp-center .ant-input-number-input{text-align: center;}

.input-no-border{
  border: none;
  box-shadow: none;
  background:transparent;
}
.checkbox-mr-0 .ant-checkbox-group-item{margin-right: 0 !important;}