.client-form-multicol {
    margin-bottom: 0px;
  }
  .client-multicol-form-item {
    display: 'inline-block';
    width: 'calc(50% - 8px)';
  }
  .pqq-title{
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 27px;
    color: var(--probluedarker);
    text-transform: uppercase;
}
.client-title{
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  color: var(--probluedarker);
}
.pqq-card-col-padding{
  padding-right: 10px;
}
.pqq-mt{
  margin-top: 20px;
}
.pqq-mb-20{
  margin-bottom: 20px;
}
.pqq-collapse-scroll::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 7px;
}

.pqq-collapse-scroll::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.pqq-collapse-scroll{
  /* height: 500px; */
  overflow-y: scroll;
  overflow-x: hidden;
}
/* .pqq-radio{
  display: table-cell;
  line-height: 30px;
  height: 30px;
} */
.tab-panel{
  padding: 15px;
}
.benchmrk-label{
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  
  color: #5F5A60;
}
.black-label{
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  /* text-align: center; */
}
.benchmrk-input-style{
  background: #FFFFFF;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
}
/* .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab .ant-tabs-close-x{
  color: #FFFFFF !important;
} */
.save-btn{
  background: #F1F0F0;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #5F5A60 !important;
  width: 80px;
  height: 30px;
}
.save-btn2{
  background: #F1F0F0;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #5F5A60;
  width: 67px;
  height: 28px;
  text-align: center;
}
.save-btn3{
  background: #F1F0F0;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  height: 29px;
  width: 30px;
  padding: 5px;
}
.save-btn3 .ant-btn[disabled]{
  display: contents;
  border: none;
  background: #F1F0F0;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
}
.add-btn{
  background: #F1F0F0;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #5F5A60;
  height: 28px;
  text-align: center;
}
.add-btn:hover,.add-btn:focus,.add-btn:active{
  background: #e2e2e2 !important;
  color: #5F5A60 !important;
  border-color: #e2e2e2 !important;
}
.cancel-btn{
  background: #FFFFFF;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #63AA4A;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
}
.cancel-btn-2{
  background: #FFFFFF;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #9D9E9E;
  width: 114px;
}
.checkbox-stacked{
      display: list-item;
      height: 25px;
      line-height: 25px;
      margin-left: 8px;
}
.sort-checkbox .ant-checkbox-wrapper{
  display: list-item;
  height: 25px;
  line-height: 25px;
  margin-left: 8px;
}
.gray-outer-card{
  background: #F6F9F9;
  border-radius: 6px;
}
.center-align .ant-form-item-control{
  align-items: center;
}
.blue-table-btn{
  background: #d7e1e4;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-style: normal;
/*   font-weight: 600;
  font-size: 14px;
  line-height: 17px; */
  color: var(--probluedarker);
}
.block-btn-3{
  background: #E7ECEE;
  color: #3471A9;
  text-align: start;
  height: 46px;
}
.block-btn-4{
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  text-align: start;
  color: #3471A9;
}
.pull-end{
  display: flex;
  justify-content: flex-end;
}

.input-crazy-style .ant-radio-group{
display: grid;
}
.input-crazy-style .ant-checkbox-group{
  display: grid;
}
.input-crazy-style .ant-checkbox-wrapper:first-child{
  margin-left: 8px;
}
.input-crazy-style .ant-radio-wrapper{
  white-space: nowrap;
}
.display-none{display: none;}